import React, { lazy, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDropdownState } from 'context/NavigationDropdownState';
import { mergeClassNames, Suspense } from 'utils';
import { useDebug } from 'Debug';
import { useProductsWithoutDeterminedPaymentTypeQuote } from 'context/Quote/ProductsWithoutDeterminedPaymentType';
import { white, grey10 } from 'styles/baseColors';
import Fixed from 'components/generic/Fixed';
import DropdownContent from './DropdownContent';

export type DropdownType = 'menu' | 'selectpurchasetype' | 'confirmaddedtocart';

const TransitionReveal = lazy(() => import('components/generic/TransitionReveal'));

export default function Dropdown() {
  const debug = useDebug();
  const [currentDropdown, setCurrentDropdown] = useState<JSX.Element | null>(null);
  const { dropdownState, dispatchDropdownState } = useDropdownState();
  const lastDropDownState = useRef(dropdownState.dropdown);
  const { dispatchPaymentTypeDetermineQuote } = useProductsWithoutDeterminedPaymentTypeQuote();

  const closeDropdown = useCallback(() => {
    if (dropdownState.dropdown === 'close') return;
    dispatchDropdownState({ type: 'close' });
    if (dropdownState.menuType === 'selectpurchasetype') {
      dispatchPaymentTypeDetermineQuote({ type: 'reset' });
    }
  }, [dispatchDropdownState, dispatchPaymentTypeDetermineQuote, dropdownState]);

  const isOpen = dropdownState.dropdown === 'open';
  let [wasOpened, setOpened] = useState(false);
  useEffect(() => setOpened(true), [isOpen, setOpened]);

  let conditionallyCloseDropdown = useCallback(() => {
    if (lastDropDownState.current === 'close' && dropdownState.dropdown === 'close') setCurrentDropdown(null);
    lastDropDownState.current = dropdownState.dropdown;
  }, [dropdownState.dropdown]);

  const renderDropdown = useCallback(
    dropdownState => <DropdownContent dropdownState={dropdownState} closeFn={closeDropdown} />,
    [closeDropdown]
  );

  useEffect(() => {
    if (dropdownState.dropdown === 'open') setCurrentDropdown(renderDropdown(dropdownState));
  }, [dropdownState, renderDropdown]);

  return (
    <>
      {isOpen && <TranslucentBackground role="presentation" />}
      <Suspense fallback={null}>
        {(wasOpened || isOpen) && (
          <StyledTransitionReveal
            as={TransitionReveal}
            isVisible={isOpen}
            className={mergeClassNames(isOpen ? 'open' : null, debug ? 'debug' : null)}
            initialTranslate={{ y: `-100px` }}
            onRest={conditionallyCloseDropdown}
          >
            {currentDropdown}
          </StyledTransitionReveal>
        )}
      </Suspense>
    </>
  );
}

const StyledTransitionReveal = styled(Fixed)`
  z-index: 2;
  left: 0;
  width: 100%;
  height: auto;
  max-height: calc(100vh - var(--headerHeight));
  overflow: scroll;
  background-color: ${white};
  border-top: solid 1px ${grey10};
  border-bottom: solid 1px ${grey10};
  justify-content: center;
  display: flex;
  &.debug {
    .inner {
      box-shadow: inset 0 0 0 1px coral;
    }
  }
`;

const TranslucentBackground = styled(Fixed)`
  z-index: 2;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(23, 27, 31, 0.6);
`;
