import React from 'react';
import styled from 'styled-components';
import { red50, xsmallBody } from 'styles';

export default function WarningLabel({ caption }) {
  return (
    <StyledWarningLabel>
      <span>{caption}</span>
    </StyledWarningLabel>
  );
}

const StyledWarningLabel = styled.div`
  ${xsmallBody}
  display: inline-block;
  color: ${red50};
  padding-left: 14px;
`;
