import React, { useState } from 'react';
import { readBrowserCookie, useCookie } from '../../utils/useCookie';
import { UserCountryContext, DetectedCountryContext } from './UserCountryContext';

const storageKeyBase = process.env.GATSBY_LOCAL_STORAGE_KEY || 'cisco-pwp-quote-2020-LOCAL';
const storageKey = storageKeyBase + 'usercountry';

interface UserCountryProviderProps {
  children: React.ReactNode | React.ReactNodeArray;
}

export function UserCountryProvider({ children }: UserCountryProviderProps) {
  const userCountryState = useCookie(storageKey, readBrowserCookie(storageKey));
  const detectedCountryState = useState<string | null>(null);

  return (
    <DetectedCountryContext.Provider value={detectedCountryState}>
      <UserCountryContext.Provider value={userCountryState}>{children}</UserCountryContext.Provider>
    </DetectedCountryContext.Provider>
  );
}
