import React from 'react';
import { supportedCountries } from '../../../../context/WebexShopIntegration/supportedCountries';
import styled from 'styled-components';
import { grey10, mediumBodyEmphasized } from 'styles';

export default function LanguageDropdown() {
  return (
    <Modal>
      <Title>Project Workplace (Global)</Title>
      {supportedCountries.map(country => (
        <li key={country.id}>
          <Link href={country.url} target="_blank" rel="noopener noreferrer">
            {country.urlTitle}
          </Link>
        </li>
      ))}
    </Modal>
  );
}

const Modal = styled.ul`
  padding: 34px;
  padding-top: calc(10px + 44px + 22px);
  background-color: ${grey10};
  border-radius: 20px;
  min-width: calc(200px + 34px + 34px);
  text-align: left;

  li:not(:last-of-type) {
    margin-bottom: 1em;
  }
`;

const Link = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.li`
  ${mediumBodyEmphasized};
`;
