import { getProductPrice } from '.';
import { ItemInCart, ProductInCart, Quote } from '../typedefs';

/** @deprecated should not be needed, bundle (the countrepart) does not exist */
function isProduct(item: ItemInCart): item is ProductInCart {
  return item.product !== undefined;
}

function isDirectPurchase(item: ItemInCart) {
  return item.paymentType === 'Placed Order';
}

// /** @deprecated should not be needed, bundle does not exist */
// function isEasyPay(item: ItemInCart) {
//   return item.paymentType === 'Monthly Payments';
// }

function isRequestQuote(item: ItemInCart) {
  return item.paymentType === 'Quote';
}

/** @depcrecate this needs updating before use!*/
function hasLeasingPrice(item: ItemInCart) {
  return item.product.monthlyPrice > 0;
}

function hasPrice(item: ItemInCart) {
  if (item.product.selectedVariant?.displayPrice === false) return item.product.selectedVariant?.displayPrice;
  return item.product.price > 0;
}

/**
 * Calculates the total price of the placed order (direct purchase)
 */
export function calculatePlacedOrderTotal(quote: Quote): number {
  return calculateTotal(quote.filter(isProduct).filter(isDirectPurchase));
}

/**
 * Calculates the total price of the easy pay items (monthly price)
 */

/** @depcrecate this needs updating before use!*/
export function calculateMontlyPayTotal(quote: Quote): number {
  return (
    quote
      .filter(isProduct)
      // .filter(isEasyPay)
      .filter(hasLeasingPrice) /** Skip negative values */
      .map(p => p.quantity * p.product.monthlyPrice)
      .reduce((total, price) => total + price, 0)
  );
}

/**
 * Calculates the total price of the easy pay items
 */
export function calculateRequestQuoteTotal(quote: Quote): number {
  return calculateTotal(quote.filter(isProduct).filter(isRequestQuote));
  /** Skip negative values */
}

function calculateTotal(quote: Quote): number {
  return quote
    .filter(hasPrice)
    .map(p => p.quantity * (getProductPrice(p.product) >= 0 ? getProductPrice(p.product) : 0))
    .reduce((total, price) => total + price, 0);
}

export function getQuoteTotal(quote: Quote): number {
  return Number(calculateTotal(quote));
}

/** @depcrecated this needs update befoer use  */
export function getQuoteLeaseTotal(quote: Quote): number {
  let total = quote.reduce((total, item) => {
    return total + calculateLeasePrice(item);
  }, 0);
  return Number(total);
}

/** @depcrecate this needs updating before use!*/
// function calculatePrice({ product, quantity }: ItemInCart): number {
//   const price = getProductPrice(product) || -1;
//   if (price < 0) return 0;
//   return price * quantity;
// }

/** @depcrecated this needs update befoer use  */
function calculateLeasePrice({ product, quantity }: ItemInCart): number {
  const { monthlyPrice = -1 } = product || {};
  if (process.env.NODE_ENV === 'development' && !monthlyPrice)
    console.warn(`${product.heading} is missing a monthly price`);

  if (monthlyPrice < 0) return 0;
  return monthlyPrice * quantity;
}

export function getOrderListLength(quote: Quote): number {
  let length = quote.reduce((totalLength, itemInCart) => {
    return totalLength + itemInCart.quantity;
  }, 0);
  return length;
}
