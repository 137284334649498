type UserLevel = 'executive' | 'standard' | 'all';

type UserProductCategories = {
  level: UserLevel;
  productCategoryIds: Array<string>;
};

export type UserConfig = {
  productCategories: {
    [key: string]: UserProductCategories;
  };
};

export const userConfig: UserConfig = {
  productCategories: {
    executive: {
      level: 'executive',
      productCategoryIds: ['gKYh09KdfZylRVIuJH6OD'],
    },
    standard: {
      level: 'standard',
      productCategoryIds: ['gKYh09KdfZylRVIuJH6OD'],
    },
    all: {
      level: 'all',
      productCategoryIds: ['gKYh09KdfZylRVIuJH6OD'],
    },
  },
};
