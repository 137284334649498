import React from 'react';
import { Link } from 'gatsby';
import SearchIcon from './assets/search_icon.svg';
import SubscribeIcon from './assets/subscribe_icon.svg';
import styled from 'styled-components';
import { grey10, grey100, white } from 'styles';

interface IconButtonProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export function SearchIconButton(props: IconButtonProps) {
  return (
    <StyledLink to="/search" activeClassName="isActive" title="Navigate to the search page" onClick={props.onClick}>
      <SearchIcon />
    </StyledLink>
  );
}

export function SubscribeIconButton(props: IconButtonProps) {
  return (
    <StyledLink to="/subscribe" activeClassName="isActive" title="Subscribe to our Newsletter" onClick={props.onClick}>
      <SubscribeIcon />
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-content: center;
  background-color: ${grey10};
  height: 44px;
  width: 44px;
  border-radius: 50%;
  text-decoration: none !important;

  &.${props => props.activeClassName} {
    background-color: ${grey100};
    fill: ${white};
    color: white;
  }
`;
