import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { isDevEnvironment } from '../utils';

const debugInit = {
  debug: false,
  grid: false,
  form: isDevEnvironment,
};

const DebugContext = createContext(debugInit);

interface DebugProviderProps {
  children: ReactNode;
}
export function DebugProvider({ children }: DebugProviderProps) {
  const { debug } = useToggleDebug(debugInit);

  return <DebugContext.Provider value={debug}>{children}</DebugContext.Provider>;
}

export function useDebug() {
  return useContext(DebugContext).debug;
}

export function useDebugGrid() {
  return useContext(DebugContext).grid;
}

export function useDebugForm() {
  return useContext(DebugContext).form;
}

interface debugState {
  debug: boolean;
  grid: boolean;
  form: boolean;
}
function useToggleDebug(initState: debugState) {
  const [debug, setDebug] = useState(initState);

  useEffect(() => {
    function onKeyUp(event: KeyboardEvent) {
      switch (true) {
        case event.code === 'KeyD' && event.ctrlKey:
          return void setDebug(debug => ({ ...debug, debug: !debug.debug }));
        case event.code === 'KeyG' && event.ctrlKey:
          return void setDebug(debug => ({ ...debug, grid: !debug.grid }));
        case event.code === 'KeyE' && event.ctrlKey:
          return void setDebug(debug => ({ ...debug, form: !debug.form }));
        default:
      }
    }

    window.document.body.addEventListener('keyup', onKeyUp);
    return () => window.document.body.removeEventListener('keyup', onKeyUp);
  }, []);

  return { debug };
}
