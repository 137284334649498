// eslint-disable-next-line no-restricted-imports
import { useLayoutEffect as useReactLayoutEffect, useEffect } from 'react';
import { isOnServer } from './isOnServer';

/**
 * This export is to prevent this warning when using useLayoutEffect rendering on the server:
 *
 * `⚠️ Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer’s output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client. See https://fb.me/react-uselayouteffect-ssr for common fixes. `
 *
 * 🤔 please evaluate if you actually need useLayoutEffect anyway
 * or if you do, can the parent component conditionally render the component where it is needed  https://reactjs.org/docs/hooks-reference.html#uselayouteffect
 *
 * 🔮 components can be conditionally rendered only on client side w:
 * ```javascript
 * let [show, setShow] = useState(false)
 * useEffect(() => setShow(true), [])
 * return show && <Component />
 * ```
 */
const useLayoutEffect = !isOnServer ? useReactLayoutEffect : useEffect;

export default useLayoutEffect;
