import { v4 as uuid } from 'uuid';
import { InventoryType, ItemInCart, ProductVariant, ProductWithSelectedVariant } from '../typedefs';

export function generateQuoteItem(item: InventoryType, selectedVariant?: ProductVariant): ItemInCart {
  switch (item.__typename) {
    case 'ContentfulProduct':
      let quotedProduct = transformProductToQuotedProduct(item, selectedVariant);
      return {
        __typename: 'ProductInCart',
        id: uuid(),
        product: quotedProduct,
        quantity: 1,
      };
    default:
      throw new Error('unknown inventory type');
  }
}

function getItemPrice(item: InventoryType, selectedVariant?: ProductVariant) {
  let variantPrices = item?.variants?.find(({ id }) => id === selectedVariant?.id);
  if (!variantPrices) return { price: item.price, monthlyPrice: item.monthlyPrice };
  let price = variantPrices.price >= 0 ? variantPrices.price : item.price;
  let monthlyPrice = variantPrices.monthlyPrice >= 0 ? variantPrices.monthlyPrice : item.monthlyPrice;
  return {
    price,
    monthlyPrice,
  };
}

export function transformProductToQuotedProduct(
  item: InventoryType,
  selectedVariant?: ProductVariant
): ProductWithSelectedVariant {
  let { price, monthlyPrice } = getItemPrice(item, selectedVariant);
  return { ...item, __typename: 'ProductWithSelectedVariant', selectedVariant, price, monthlyPrice };
}
