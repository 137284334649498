import React, { useState } from 'react';
import { Suspense } from 'utils';
import { graphql, useStaticQuery } from 'gatsby';
import { states, useSetCookieAcceptance } from '../../../context/CookiesAcceptance';
import RichText from '../../generic/RichText';
import Modal from '../../generic/Modal';
import { LinkStyleButton, PrimaryButton } from '../../generic/Button';
import styled, { css } from 'styled-components';
import { maxLayoutWidth, mediaQueries, mediumBody, universalMargin, xsmallBody } from 'styles';
import { grey70AlphaDark, white } from 'styles/baseColors';

const CookieSettings = React.lazy(() => import('../CookieSettings'));

export default function CookieDialog() {
  const data = useStaticQuery(query);
  let setCookiesAccepted = useSetCookieAcceptance();
  let [isCookieSettingsVisible, setCookieSettingsVisible] = useState(false);

  return (
    <StyledModal portal full fixed lockBodyScroll preventClickOutside>
      <PopupLayout>
        <Heading>{data.contentfulBlock.heading}</Heading>

        <Left>
          <RichText>{data.contentfulBlock.body}</RichText>
        </Left>

        <Right>
          <ButtonWrapper>
            <StyledButton clickHandler={() => setCookiesAccepted(states.accepted)}>Yes</StyledButton>
          </ButtonWrapper>

          <LinkStyleButton
            clickHandler={e => {
              e.preventDefault();
              setCookieSettingsVisible(true);
            }}
            className="link"
          >
            No, manage cookie settings
          </LinkStyleButton>
        </Right>
      </PopupLayout>

      <Suspense fallback={null}>
        {isCookieSettingsVisible ? (
          <CookieSettings
            data={data.contentfulBlock.sections}
            isSettingsVisible={isCookieSettingsVisible}
            closeFn={() => setCookieSettingsVisible(false)}
          />
        ) : null}
      </Suspense>
    </StyledModal>
  );
}

let query = graphql`
  query {
    contentfulBlock(contentful_id: { eq: "50RudDyDspLccqD46FBpl0" }) {
      heading
      body {
        raw
      }
      sections {
        ... on ContentfulSimpleArticle {
          heading
          body {
            raw
          }
        }
      }
    }
  }
`;

const Heading = styled.h3`
  flex: 0 0 100%;
  padding-bottom: ${universalMargin * 4}px;
`;

const Left = styled.div`
  flex: 0 0 100%;
  > div > p,
  > div > p > a {
    padding-top: 0;
  }
  ${mediaQueries.medium(css`
    padding-right: ${universalMargin * 4}px;
    flex: 0 0 70%;
    > div > p,
    > div > p > a {
      ${xsmallBody}
    }
  `)}
`;

const Right = styled.div`
  padding: ${universalMargin * 4}px 0;
  flex: 0 0 100%;
  .link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    ${mediumBody}
  }
  ${mediaQueries.medium(css`
    padding: unset;
    flex: 0 0 30%;
    .link {
      ${xsmallBody}
    }
  `)}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: ${universalMargin * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(PrimaryButton)`
  width: 170px;
  ${mediaQueries.small(css`
    width: 122px;
  `)}
  ${mediaQueries.large(css`
    width: 152px;
  `)}
`;

const PopupLayout = styled.section`
  position: absolute;
  background-color: ${white};
  padding: ${universalMargin * 4}px;
  max-width: ${maxLayoutWidth}px;
  width: 100vw;
  max-width: 710px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledModal = styled(Modal)`
  background-color: ${grey70AlphaDark};
  z-index: 3;
`;
