import { css } from 'styled-components';
import { mediaQueries } from './breakpoints';
import { branding, universalMargin } from './variables';
import { blue50, grey40, grey70, white } from './baseColors';

const tinyFraction = 0.75;
const smallFraction = 0.8;

// h1
const h1FontSize = 96;
const h1LineHeight = 98;
const h1LetterSpacing = -0.03;
export const h1Heading = css`
  font-weight: 500;
  font-size: ${h1FontSize * tinyFraction}px;
  line-height: ${h1LineHeight * tinyFraction}px;
  letter-spacing: ${h1LetterSpacing * tinyFraction}em;

  ${mediaQueries.small(css`
    font-size: ${h1FontSize * smallFraction}px;
    line-height: ${h1LineHeight * smallFraction}px;
    letter-spacing: ${h1LetterSpacing * smallFraction}px;
  `)}

  ${mediaQueries.large(css`
    font-size: ${h1FontSize}px;
    line-height: ${h1LineHeight}px;
    letter-spacing: ${h1LetterSpacing}em;
  `)}
`;

// h2
const h2FontSize = 84;
const h2LineHeight = 84;
const h2LetterSpacing = -0.04;
export const h2Heading = css`
  font-weight: 500;
  font-size: ${h2FontSize * tinyFraction}px;
  line-height: ${h2LineHeight * tinyFraction}px;
  letter-spacing: ${h2LetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${h2FontSize * smallFraction}px;
      line-height: ${h2LineHeight * smallFraction}px;
      letter-spacing: ${h2LetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${h2FontSize}px;
      line-height: ${h2LineHeight}px;
      letter-spacing: ${h2LetterSpacing}em;
    `
  )}
`;

// h3
const h3FontSize = 64;
const h3LineHeight = 64;
const h3LetterSpacing = -0.04;
// export the h3 font definitions as it is when mediaQueries === medium|large
export const h3FontMedium = css`
  font-size: ${h3FontSize * smallFraction}px;
  line-height: ${h3LineHeight * smallFraction}px;
  letter-spacing: ${h3LetterSpacing * smallFraction}em;
`;
const h3FontLarge = css`
  font-size: ${h3FontSize}px;
  line-height: ${h3LineHeight}px;
  letter-spacing: ${h3LetterSpacing}em;
`;
export const h3Heading = css`
  font-weight: 500;
  font-size: ${h3FontSize * tinyFraction}px;
  line-height: ${h3LineHeight * tinyFraction}px;
  letter-spacing: ${h3LetterSpacing * tinyFraction}em;
  ${mediaQueries.small(h3FontMedium)}
  ${mediaQueries.large(h3FontLarge)}
`;

// h4
const h4FontSize = 40;
const h4LineHeight = 44;
const h4LetterSpacing = -0.04;
// export the h4 font definition as it is when mediaQueries === large
export const h4FontLarge = css`
  font-size: ${h4FontSize}px;
  line-height: ${h4LineHeight}px;
  letter-spacing: ${h4LetterSpacing}em;
`;
export const h4Heading = css`
  font-weight: 400;
  font-size: ${h4FontSize * tinyFraction}px;
  line-height: ${h4LineHeight * tinyFraction}px;
  letter-spacing: ${h4LetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${h4FontSize * smallFraction}px;
      line-height: ${h4LineHeight * smallFraction}px;
      letter-spacing: ${h4LetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(h4FontLarge)}
`;

// h5
const h5FontSize = 32;
const h5LineHeight = 36;
const h5LetterSpacing = -0.04;
export const h5Heading = css`
  font-weight: 400;
  font-size: ${h5FontSize * tinyFraction}px;
  line-height: ${h5LineHeight * tinyFraction}px;
  letter-spacing: ${h5LetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${h5FontSize * smallFraction}px;
      line-height: ${h5LineHeight * smallFraction}px;
      letter-spacing: ${h5LetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${h5FontSize}px;
      line-height: ${h5LineHeight}px;
      letter-spacing: ${h5LetterSpacing}em;
    `
  )}
`;

const navFontSize = 14;
const navLineHeight = 24;
const navLetterSpacing = -0.03;
export const navType = css`
  font-weight: 300;
  font-size: ${navFontSize * tinyFraction}px;
  line-height: ${navLineHeight * tinyFraction}px;
  letter-spacing: ${navLetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${navFontSize * smallFraction}px;
      line-height: ${navLineHeight * smallFraction}px;
      letter-spacing: ${navLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${navFontSize}px;
      line-height: ${navLineHeight}px;
      letter-spacing: ${navLetterSpacing}em;
    `
  )}
`;

const navEmphasizedLetterSpacing = 0;
export const navTypeEmphasized = css`
  ${navType}
  font-weight: 700;
  letter-spacing: ${navEmphasizedLetterSpacing * tinyFraction}em;
  ${mediaQueries.small(
    css`
      letter-spacing: ${navEmphasizedLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      letter-spacing: ${navEmphasizedLetterSpacing}em;
    `
  )}
`;

const headerNavFontSize = 18;
const headerNavLineHeight = 26;
const headerNavLetterSpacing = -0.02;
export const headerNavType = css`
  font-weight: 300;
  font-size: ${headerNavFontSize * tinyFraction}px;
  line-height: ${headerNavLineHeight * tinyFraction}px;
  letter-spacing: ${headerNavLetterSpacing * tinyFraction}em;
  ${mediaQueries.small(
    css`
      font-size: ${headerNavFontSize * smallFraction}px;
      line-height: ${headerNavLineHeight * smallFraction}px;
      letter-spacing: ${headerNavLetterSpacing * smallFraction}em;
    `
  )}
  ${mediaQueries.large(
    css`
      font-size: ${headerNavFontSize}px;
      line-height: ${navLineHeight}px;
      letter-spacing: ${headerNavLetterSpacing}em;
    `
  )}
`;

const headerNavEmphasizedLetterSpacing = 0;
export const headerNavTypeEmphasized = css`
  ${headerNavType}
  font-weight: 700;
  letter-spacing: ${headerNavEmphasizedLetterSpacing * tinyFraction}em;
  ${mediaQueries.small(
    css`
      letter-spacing: ${headerNavEmphasizedLetterSpacing * smallFraction}em;
    `
  )}
  ${mediaQueries.large(
    css`
      letter-spacing: ${headerNavEmphasizedLetterSpacing}em;
    `
  )}
`;

const largeBodyFontSize = 24;
const largeBodyLineHeight = 32;
const largeBodyLetterSpacing = -0.02;
export const largeBody = css`
  font-weight: 400;
  font-size: ${largeBodyFontSize * tinyFraction}px;
  line-height: ${largeBodyLineHeight * tinyFraction}px;
  letter-spacing: ${largeBodyLetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${largeBodyFontSize * smallFraction}px;
      line-height: ${largeBodyLineHeight * smallFraction}px;
      letter-spacing: ${largeBodyLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${largeBodyFontSize}px;
      line-height: ${largeBodyLineHeight}px;
      letter-spacing: ${largeBodyLetterSpacing}em;
    `
  )}
`;

const mediumBodyFontSize = 18;
const mediumBodyLineHeight = 26;
const mediumBodyLetterSpacing = -0.02;
export const mediumBody = css`
  font-weight: 400;
  font-size: ${mediumBodyFontSize * tinyFraction}px;
  line-height: ${mediumBodyLineHeight * tinyFraction}px;
  letter-spacing: ${mediumBodyLetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${mediumBodyFontSize * smallFraction}px;
      line-height: ${mediumBodyLineHeight * smallFraction}px;
      letter-spacing: ${mediumBodyLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${mediumBodyFontSize}px;
      line-height: ${mediumBodyLineHeight}px;
      letter-spacing: ${mediumBodyLetterSpacing}em;
    `
  )}
`;

const mediumBodyEmphasizedLetterSpacing = -0;
export const mediumBodyEmphasized = css`
  ${mediumBody}
  font-weight: 700;
  letter-spacing: ${mediumBodyEmphasizedLetterSpacing * tinyFraction}em;
  ${mediaQueries.small(
    css`
      letter-spacing: ${mediumBodyEmphasizedLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      letter-spacing: ${mediumBodyEmphasizedLetterSpacing}em;
    `
  )}
`;

const smallBodyFontSize = 15;
const smallBodyLineHeight = 20;
const smallBodyLetterSpacing = -0.01;
export const smallBody = css`
  font-weight: 400;
  font-size: ${smallBodyFontSize * tinyFraction}px;
  line-height: ${smallBodyLineHeight * tinyFraction}px;
  letter-spacing: ${smallBodyLetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${smallBodyFontSize * smallFraction}px;
      line-height: ${smallBodyLineHeight * smallFraction}px;
      letter-spacing: ${smallBodyLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${smallBodyFontSize}px;
      line-height: ${smallBodyLineHeight}px;
      letter-spacing: ${smallBodyLetterSpacing}em;
    `
  )}
`;

const xsmallBodyFontSize = 12;
const xsmallBodyLineHeight = 16;
const xsmallBodyLetterSpacing = -0.01;
export const xsmallBody = css`
  font-weight: 400;
  font-size: ${xsmallBodyFontSize * tinyFraction}px;
  line-height: ${xsmallBodyLineHeight * tinyFraction}px;
  letter-spacing: ${xsmallBodyLetterSpacing * tinyFraction}em;

  ${mediaQueries.small(
    css`
      font-size: ${xsmallBodyFontSize * smallFraction}px;
      line-height: ${xsmallBodyLineHeight * smallFraction}px;
      letter-spacing: ${xsmallBodyLetterSpacing * smallFraction}em;
    `
  )}

  ${mediaQueries.large(
    css`
      font-size: ${xsmallBodyFontSize}px;
      line-height: ${xsmallBodyLineHeight}px;
      letter-spacing: ${xsmallBodyLetterSpacing}em;
    `
  )}
`;

// Missing xSmall Body

export const anchor = css`
  color: inherit;
  text-decoration: underline solid currentColor;
  text-underline-offset: 5px;
  text-decoration-thickness: 0.06rem;
  transition: text-decoration 0.3s, color 0.3s;
  cursor: pointer;
  &:hover {
    color: ${branding.accentColor};
  }
`;

//deprecated ?
export const negativeType = css`
  color: ${white};
`;

//deprecated ?
export const mutedType = css`
  color: ${grey40};
`;

export const button = css`
  border: none;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    outline: 0px;
    outline-offset: -4px;
  }
`;

export const input = css`
  ${mediumBody}
  width: 100%;
  appearance: none;
  padding-right: 14px;
  padding-left: 14px;
  height: 36px;
  min-height: 36px;
  background-color: ${white};
  border: 1px solid ${grey40};
  border-radius: 17px;
  box-shadow: none;
  color: ${grey70};
  &:focus,
  &:active {
    border-color: ${blue50};
  }
`;

export const checkbox = css`
  margin-right: 14px;
  margin-left: 14px;
`;

export const textarea = css`
  height: 100px;
  padding-top: ${universalMargin}px;
  padding-bottom: ${universalMargin}px;
  resize: none;
`;

//? dl, ol, ul padding should be like ol padding below: `padding: ${universalMargin * 2}px 0;`
export const contentBlock = css`
  p,
  a {
    ${mediumBody}
  }

  a {
    ${anchor};
  }

  p {
    padding: ${universalMargin * 2}px 0;
    b {
      font-weight: 600;
    }
    i {
      font-style: italic;
    }
    u {
      text-decoration: underline solid currentColor;
      text-decoration-thickness: 0.06rem;
    }
  }

  dl,
  // ol,
  ul {
    padding: ${universalMargin * 2}px 0 0;
    margin: 0;

    li {
      // ol,
      ul {
        padding: 0 0 0 ${universalMargin * 2}px;
      }

      list-style-type: disc;
      list-style-position: inside;
      padding: 0;
      p {
        padding: 0;
        display: inline;
      }
    }
  }

  ol {
    list-style: decimal;
    padding: 0 ${universalMargin}px;

    li {
      padding: ${universalMargin}px 0 0 0;
    }
    p {
      padding: 0;
      display: inline;
    }

    ol {
      list-style: lower-latin;
      //padding: ${universalMargin}px 0 0 0;

      ol {
        list-style: lower-roman;
        //padding: ${universalMargin}px 0 0 0;
      }
    }
  }

  // ol {
  //   counter-reset: base-list;
  //   padding: ${universalMargin * 2}px 0;
  //   li {
  //     counter-increment: base-list;
  //     list-style: none;
  //     list-style-position: outside;
  //     padding: 0;
  //     &:before {
  //       content: counter(base-list) '.';
  //       display: inline-block;
  //       width: ${universalMargin * 4}px;
  //       text-align: left;
  //       ${mediumBody}
  //     }
  //     &:nth-child(n + 10):before {
  //       content: counter(base-list);
  //     }
  //   }
  // }

  h1 {
    ${h1Heading};
    padding: ${universalMargin * 6}px 0 0;
    margin: 0;
  }

  h2 {
    ${h2Heading};
    padding: ${universalMargin * 5}px 0 0;
    margin: 0;
  }

  h3 {
    ${h3Heading};
    padding: ${universalMargin * 4}px 0 0;
    margin: 0;
  }

  h4 {
    ${h4Heading};
    padding: ${universalMargin * 3}px 0 0;
    margin: 0;
  }

  h5 {
    ${h5Heading};
    padding: ${universalMargin * 2}px 0 0;
    margin: 0;
  }

  h6 {
    ${largeBody};
    padding: ${universalMargin * 2}px 0 0;
    margin: 0;
  }

  hr {
    border: dashed #eeeeee 1px;
    margin: ${universalMargin * 6}px 0;
  }

  blockquote {
    position: relative;
    display: block;
    padding-left: ${universalMargin * 6}px;
    margin: 0 ${universalMargin * -2}px;
    ${mediaQueries.large(
      css`
        margin: 0 ${universalMargin * -6}px;
        padding-left: ${universalMargin * 6}px;
      `
    )}
    p:first-of-type {
      ${h3Heading};
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: ${universalMargin * 2}px;
      width: 24px;
      height: 24px;
      background-image: url(/images/blockquote-icon.svg);
      background-size: 24px;
      background-repeat: no-repeat;
      ${mediaQueries.large(
        css`
          background-size: 32px;
          width: 32px;
          height: 32px;
        `
      )}
    }
  }

  code {
    display: table;
    width: calc(100% + ${universalMargin * 4}px);
    margin: 0 ${universalMargin * -2}px;
    white-space: pre-wrap;
    font-family: monospace;
    color: ${grey70};
    padding: ${universalMargin * 2}px;
    background-color: #f2f4f5;
    ${mediaQueries.medium(css`
      width: calc(100% + ${universalMargin * 8});
      padding: ${universalMargin * 4}px;
      margin: 0 ${universalMargin * -4}px;
    `)}
    ${mediaQueries.large(
      css`
        width: calc(100% + ${universalMargin * 18}px);
        padding: ${universalMargin * 4}px;
        margin: 0 ${universalMargin * -9}px;
      `
    )}
  }
`;
