import React, { ReactNode } from 'react';

interface NotRichTextProps {
  children?: ReactNode;
  className?: string;
}

export function NotRichText({ children, className }: NotRichTextProps) {
  return <p className={className}>{children}</p>;
}
