// only for gatsby-browser config, should not be used for gatsby-server config
export const onClientEntry = async () => {
  polyfills.forEach(loadPolyfill => loadPolyfill());
};

const ResizeObserver = async () => {
  if ('ResizeObserver' in window === false) {
    await import('@juggle/resize-observer').then(module => {
      window.ResizeObserver = module.ResizeObserver;
    });
  }
};

const IntersectionObserver = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
};

const SmoothScroll = async () => {
  if (!('scrollBehavior' in document.documentElement.style)) {
    const [smoothscrollPolyfill] = await Promise.all([
      import('smoothscroll-polyfill'),
      import('smoothscroll-anchor-polyfill'),
    ]);
    smoothscrollPolyfill.polyfill(); // must run after both smoothscroll polyfills' import
  }
};

const polyfills = [ResizeObserver, IntersectionObserver, SmoothScroll];
