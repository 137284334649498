import styled from 'styled-components';
import { mediaQueries } from 'styles/breakpoints';

export const HideOnMobile = styled.div`
  display: none;
  ${mediaQueries.medium(`
    display: unset;  
`)}
`;

export const HideOnTablet = styled.div<{ alsoHideMobile: boolean }>`
  ${props => props.alsoHideMobile && `display: none;`}
  ${mediaQueries.medium(`display: none;`)}
  ${mediaQueries.large(`display: unset;`)}
`;

export const HideOnDesktop = styled.div`
  ${mediaQueries.large(`display: none;`)}
`;
