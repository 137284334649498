import { PaymentType } from 'context/Quote/typedefs';
import { PageProps } from 'gatsby';
import { ContentfulNavigation } from 'types';
import { DropdownType } from 'components/Dropdown';
import { buildTargets } from '../../build-target';
import { UserConfig } from 'white-label/ford/userConfig';

export type { ContentfulNavigation };

export type LinkType = 'internal' | 'external' | 'dropdown';

type NavLinkBase = {
  title: string;
  type: LinkType;
  standaloneInMenu?: boolean /** Wether or not this NavLink will be displayed as a seperate icon in the menu on desktop, and in the hamburger menu on mobile devices. Like Search and Language Selector. */;
};

export type NavDirectLink = NavLinkBase & {
  type: 'external' | 'internal';
  url: string;
};

export function isDirectLink(link: NavLink): link is NavDirectLink {
  return link.type === 'external' || link.type === 'internal';
}

export type NavDropdownLink = NavLinkBase & {
  type: 'dropdown';
  dropdownType: Extract<DropdownType, 'menu'>;
  subNavigation?: Array<ContentfulNavigation>;
};

export function isNavDropdownLink(link: NavLink): link is NavDropdownLink {
  return link.type === 'dropdown';
}

export type NavLink = NavDirectLink | NavDropdownLink;

export type SiteMetadata = {
  brand: string;
  title: string;
  description: string;
  image: string;
  author: string;
  site: string;
  siteUrl: string;
  homeLink: NavDirectLink;
  themeColor: string;
  orderPage: NavDirectLink;
  globalMenuBottomLinks: Array<NavDirectLink>;
  contactPage: NavDirectLink;
  canonicalBaseUrl: string;
  phrases: {
    disclaimerNonBinding: string;
    disclaimerAdditionalCost: string;
    disclaimerPricingRequest: string;
    buyButtonLabel: string;
  };
};

type CardGridItem = {
  headerColor: string;
  color: string;
  background: string;
};

type Button = {
  borderRadius: { small: string; medium: string; large: string };
  colors: {
    color: string;
    background: string;
    hoverColor: string;
    hoverBackground: string;
    disabledColor: string;
    disabledBackground: string;
  };
};

type AvailablePaymentTypes = Array<PaymentType>;

type Phrases = {
  invalidPrice: string;
  disclaimerNonBinding: string;
  disclaimerAdditionalCost: string;
  disclaimerPricingRequest: string;
  buyButtonLabel: string;
};

type DeviceSize = 'mobile' | 'tablet' | 'desktop';

export type WhitelabelBranding = {
  headerHeight: Record<DeviceSize, string>;
  accentColor: string;
  hrGradient: string;
  boxBorderRadius: string;
  innerBorderRadius: string;
  headerLinkBar: { visible: boolean; background: string };
  primaryButton: Button;
  secondaryButton: Button;
  contentBlock: {
    background: {
      light: string;
      dark: string;
    };
  };
  cardGrid: {
    background: string;
    item: {
      prominent: CardGridItem; // dark card in pwp, blue gradient card in wwt, light in webex
      default: CardGridItem; // light card in pwp, light lavender in wwt, dark in webex
    };
  };
  homeDevicesGrid: {
    background: string;
    item: CardGridItem;
  };
  availablePaymentTypes: AvailablePaymentTypes;
  phrases: Phrases;
  globalMenuBottomLinks: Array<NavDirectLink>;
  icon: string;
  formApiEndpoint: string;
  formSiteId: string;
  formCampaignId: string;
  elqFormNameQuote: string; // <input name="elqFormName" ... />
  elqFormNameContact: string; // <input name="elqFormName" ... />
  elqFormNameSubscription: string; // <input name="elqFormName" ... />
  commonColors: {
    white: string;
    black: string;
  };
  siteMetadata: {
    title: string;
    brand: string;
    description: string;
    defaultSeoImageUrl: string;
    canonicalBaseUrl: string;
    siteAndAuthor: string;
    homeLink?: {
      title: 'Home' | string;
      url: '/home' | string;
      type: 'internal';
    };
  };
  pathPrefix: string;
  assetPrefix: string;
  order: {
    usePaymentTypeDialog: boolean;
    summary: {
      heading: string;
      button: string;
      usePDFButton: boolean;
    };
    submit: {
      heading: string;
      button: string;
    };
    timeline: Array<'Create order' | 'Submit order' | 'Receive quote & payment' | 'Track shipment'>;
  };
  userConfig?: UserConfig;
};

export type BrandedLayoutProps = Omit<PageProps<Record<string, unknown>>, 'children'> & { children: JSX.Element };

export type BuildTargetType = keyof typeof buildTargets;
