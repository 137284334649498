import { useEffect } from 'react';
import { useCookiesPerformanceAccepted } from '../../context/CookiesAcceptance';
import { useDebug } from 'Debug';
import { isOnServer } from '../../utils';

const gaProperty = process.env.GA_TRACKING_ID;

export default function GoogleTracking() {
  let debug = useDebug();
  let accepted = useCookiesPerformanceAccepted();

  useEffect(() => {
    if (isOnServer) return void null;
    gaEnable(accepted, debug);
    sendPageView(accepted);
  }, [accepted, debug]);

  return null;
}

export function gaEnable(enable, debug = false) {
  const disableString = `ga-disable-${gaProperty}`;
  if (debug) console.log(`enabling google analytics: ${enable}`);
  if (!isOnServer) {
    if (debug) console.log(`current window[disablestring(${disableString})]: ${JSON.stringify(window[disableString])}`);
    document.cookie = `${disableString}=${JSON.stringify(
      !enable
    )} ; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/;SameSite=Strict`;
    window[disableString] = !enable;
    if (debug) console.log(`after gaEnable() - window[${disableString}]: ${JSON.stringify(window[disableString])}`);
  }
}

function sendPageView(enable = false) {
  if (!isOnServer && 'ga' in window && enable) {
    const pagePath = window.location
      ? window.location.pathname + window.location.search + window.location.hash
      : undefined;

    // @ts-ignore
    window.ga(`set`, `page`, pagePath);
    // @ts-ignore
    window.ga(`send`, `pageview`);
  }
}
