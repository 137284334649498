import { useCookiesPerformanceAccepted } from '../context/CookiesAcceptance';
import { CustomEventArgs, trackCustomEvent as gaTrackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useDebug } from 'Debug';

export function useTrackCustomEvent() {
  let debug = useDebug();
  let accepted = useCookiesPerformanceAccepted();
  let trackFn = logEventTracking(accepted ? gaTrackCustomEvent : () => void null, accepted, debug);
  return trackFn;
}

function logEventTracking(
  trackEventCallback: (args: CustomEventArgs, ...rest: Array<unknown>) => void,
  accepted = false,
  debug = false
) {
  return (trackingEvent: CustomEventArgs, ...rest: Array<unknown>) => {
    if (debug) log(trackingEvent);
    if (accepted) trackEventCallback(trackingEvent, ...rest);
  };

  function log(trackingEvent: CustomEventArgs) {
    let debugStyles = ['color: plum', 'color: unset', 'color: purple', 'color: greenyellow', 'color: lime'];
    console.groupCollapsed(
      `Track %c${trackingEvent.action}%c in %c${trackingEvent.category}`,
      ...debugStyles.slice(0, 3)
    );
    console.log(`%cCategory:`, debugStyles[2], trackingEvent.category);
    console.log(`%cAction:`, debugStyles[0], trackingEvent.action);
    if (trackingEvent.label) console.log(`%cLabel:`, debugStyles[3], trackingEvent.label);
    if (trackingEvent.value) console.log(`%cValue:`, debugStyles[4], trackingEvent.value);
    console.groupEnd();
  }
}
