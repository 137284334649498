import React, { createContext, useContext, useMemo } from 'react';
import { useCookie } from '../../utils/useCookie';

const storageKeyBase = process.env.GATSBY_LOCAL_STORAGE_KEY || 'cisco-pwp-quote-2020-LOCAL';
const storageKey = storageKeyBase + '_cookieacceptance';

export const states = {
  nodecision: 'nodecision',
  declined: 'declined',
  accepted: 'accepted',
  conditional: 'conditional',
};

export const initAcceptance = {
  necessary: false,
  performance: false,
  targeting: false,
};

const CookieAcceptanceContext = createContext([initAcceptance]);
const SetCookieAcceptanceContext = createContext(null);

export function CookieAcceptanceProvider({ children }) {
  let [storedCookie, setStoredCookie] = useCookie(storageKey, null);

  let cookieAcceptance = useMemo(() => [parseAcceptanceCookie(storedCookie) || initAcceptance], [storedCookie]);
  let setCookieAcceptance = (acceptanceState, acceptanceConditions) => {
    setStoredCookie(parseCookieSetting(acceptanceState, acceptanceConditions));
  };

  return (
    <CookieAcceptanceContext.Provider value={cookieAcceptance}>
      <SetCookieAcceptanceContext.Provider value={setCookieAcceptance}>{children}</SetCookieAcceptanceContext.Provider>
    </CookieAcceptanceContext.Provider>
  );
}

export function parseCookieSetting(acceptanceState, acceptanceConditions = {}) {
  let acceptedSettings = { ...initAcceptance };
  switch (acceptanceState) {
    case states.conditional: {
      acceptedSettings = { ...acceptedSettings, ...acceptanceConditions };
      break;
    }
    case states.accepted: {
      Object.entries(initAcceptance).forEach(([key]) => (acceptedSettings[key] = true));
      break;
    }
    case states.declined:
    case states.nodecision:
    default:
      break;
  }
  return convertToAcceptanceCookie(acceptedSettings);
}

export function useSetCookieAcceptance() {
  return useContext(SetCookieAcceptanceContext);
}

export function useCookieAcceptance() {
  return useContext(CookieAcceptanceContext);
}

export function mapCMSCookieSettingToCondition(setting) {
  const [settingName] = Object.entries(initAcceptance).find(([condition]) => {
    return setting?.heading.toLowerCase().includes(condition);
  });
  return settingName;
}

function convertToAcceptanceCookie(object) {
  const storableCookieState = Object.entries(object || {})
    .map(([key, value]) => `${key}:${value}`)
    .join(',');
  return storableCookieState;
}

/** parse comma separated list from stored cookie and populate current state */
/**
 *
 * @param {string} acceptanceCookie
 * @returns {object|null}
 */
export function parseAcceptanceCookie(acceptanceCookie) {
  if (typeof acceptanceCookie !== 'string' || acceptanceCookie === undefined || acceptanceCookie === null) return null;
  if (!acceptanceCookie) return {};
  return acceptanceCookie
    .split(',')
    .map(setting => setting.split(':'))
    .reduce((obj, [key, value]) => Object.assign(obj, { [key]: JSON.parse(value) }), {});
}

export function useCookiesPerformanceAccepted() {
  let [acceptedCookies] = useContext(CookieAcceptanceContext);
  let performanceAccepted = checkAcceptance(acceptedCookies, 'performance');
  return performanceAccepted;
}

function checkAcceptance(cookie, type) {
  let accepted = cookie && type in cookie && cookie[type];
  return accepted || false;
}
