import { variables as pwpVariables } from '../pwp/variables';
import { WhitelabelBranding } from 'white-label/shared/types';
import { white, black, grey10, grey50, grey85, grey90 } from '../../styles/baseColors';

export const globalMenuBottomLinks = [{ title: 'hardware.webex.com', url: 'https://hardware.webex.com' }];

export const variables: WhitelabelBranding = {
  ...pwpVariables,
  headerHeight: {
    //! please see ./WebexHeaderBuffer.tsx to see how these values is overridden
    mobile: `72px`, // header height (variable, inaccurate)
    tablet: `193px`, // header height (variable, inaccurate)
    desktop: `${98 + 34}px`, // header height + header banner height
  },
  icon: 'static/webex/images/favicon.png',
  contentBlock: {
    background: {
      light: white,
      dark: grey85,
    },
  },
  cardGrid: {
    background: '#000000',
    item: {
      prominent: {
        headerColor: black,
        color: grey50,
        background: `linear-gradient(270deg, ${grey10}, ${grey10});`,
      },
      default: {
        headerColor: white,
        color: grey50,
        background: `linear-gradient(270deg, ${grey90}, ${grey90});`,
      },
    },
  },
  formCampaignId: `hardware.webex`,
  siteMetadata: {
    ...pwpVariables.siteMetadata,
    title: 'Webex',
    brand: 'Webex',
    description:
      'Webex by Cisco is the leading enterprise solution for video conferencing, online meetings, screen share, and webinars. Web conferencing, cloud calling, and equipment.',
    defaultSeoImageUrl: 'https://hardware.webex.com/images/default_seo.jpg',
    canonicalBaseUrl: 'https://hardware.webex.com',
  },
};
