import React from 'react';
import GlobeIcon from '../assets/globe.svg';
import styled from 'styled-components';
import { grey10, white, black, green50 } from 'styles';

interface LanguageButtonProps {
  onClick?: () => void;
}

export default function LanguageButton(props: LanguageButtonProps) {
  return (
    <Icon onClick={props.onClick}>
      <GlobeIcon />
    </Icon>
  );
}

const Icon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${grey10};
  height: 44px;
  width: 44px;
  border-radius: 22px;
  text-decoration: none !important;
  cursor: pointer;

  svg {
    display: block;
    path {
      transition: fill 250ms ease-in;
    }
  }

  &.active {
    color: ${white};
    background-color: ${black};
  }

  &:hover {
    svg {
      path {
        fill: ${green50};
      }
    }
  }
`;
