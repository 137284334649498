import React, { lazy, useEffect } from 'react';
import { Suspense, useLockBodyScroll } from 'utils';
import { DropdownState } from 'context/NavigationDropdownState';
import Section from 'components/generic/Section';
import { useClickOutside } from 'utils';

interface DropdownContentProps {
  dropdownState: DropdownState;
  closeFn: () => void;
}

export default function DropdownContent({ dropdownState, closeFn }: DropdownContentProps) {
  const elementRef = useClickOutside(closeFn, { capture: false, debug: { name: DropdownContent.name, enable: false } });

  const [lockScroll, unlockScroll] = useLockBodyScroll();
  useEffect(() => {
    lockScroll();
    return () => unlockScroll();
  }, [lockScroll, unlockScroll]);

  return (
    <Section size="max" ref={elementRef} innerPadding>
      <DropdownTypeSwitch dropdownState={dropdownState} closeFn={closeFn} />
    </Section>
  );
}

const SelectPaymentType = lazy(() => import('./SelectPaymentType'));
const NavigationMenu = lazy(() => import('./NavigationMenu'));
const ConfirmAddedToCartDialog = lazy(() => import('./ConfirmAddedToCart'));

type DropdownTypeProps = {
  dropdownState: DropdownState;
  closeFn: () => void;
};

function DropdownTypeSwitch(props: DropdownTypeProps) {
  if (props.dropdownState.dropdown === 'close') return null;
  switch (props.dropdownState?.menuType) {
    case 'menu':
      return (
        <Suspense fallback={null}>
          <NavigationMenu nodes={props.dropdownState.subNavigation} closeFn={props.closeFn} />
        </Suspense>
      );
    case 'selectpurchasetype':
      return (
        <Suspense fallback={null}>
          <SelectPaymentType closeFn={props.closeFn} />
        </Suspense>
      );
    case 'confirmaddedtocart':
      return (
        <Suspense fallback={null}>
          <ConfirmAddedToCartDialog confirmedCarted={props.dropdownState.quote} closeFn={props.closeFn} />
        </Suspense>
      );
    default:
      return null;
  }
}
