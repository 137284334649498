import { EntryLinkTargetGeneric } from 'types';

export function getProductCategoryPath<Target extends EntryLinkTargetGeneric>(category: Target) {
  const categorySlug = category && category.slug;

  const path = `/products/${categorySlug}`;
  if (!categorySlug) {
    throw new Error('No category or missing slug');
  }
  return path;
}

export function getProductPath<Target extends EntryLinkTargetGeneric>(product: Target) {
  const productSlug = product && product.slug;
  if (!productSlug) {
    throw new Error('No product or missing slug');
  }

  return `/products/${productSlug}`;
}
