import { variables as pwpVariables } from '../pwp/variables';
import { WhitelabelBranding, NavDirectLink } from 'white-label/shared/types';
import { white, grey50, grey40, grey100, black } from '../../styles/baseColors';

const wwtgrey = `#F0F0FA`;
const wwtblack = `#0A0B19`;
const wwtblue = `#0086EA`;
const wwtlightblue = `#339ded`; //used on hover. Replica of WWT site. NOT approved
const wwtdarkblue = `#131431`;

export const globalMenuBottomLinks: Array<NavDirectLink> = [
  { title: 'wwt.com', url: 'https://www.wwt.com', type: 'external' },
  { title: 'Contact Us', url: 'https://projectworkplace.cisco.com/get-in-touch', type: 'external' },
];

export const variables: WhitelabelBranding = {
  headerHeight: {
    mobile: `${92 + 8}px`, // header height + wwt's gradiented top bar height
    tablet: `${92 + 8}px`, // header height + wwt's gradiented top bar height
    desktop: `${92 + 8}px`, // header height + wwt's gradiented top bar height
  },
  icon: 'src/assets/icon.svg',
  accentColor: wwtblue,
  hrGradient: 'linear-gradient(to right, red, #330072, #0860ce)',
  boxBorderRadius: '4px',
  innerBorderRadius: '4px',
  headerLinkBar: { visible: false, background: 'transparent' },
  primaryButton: {
    colors: {
      color: white,
      hoverColor: white,
      background: wwtblue,
      hoverBackground: wwtlightblue,
      disabledColor: grey50,
      disabledBackground: grey40,
    },
    borderRadius: {
      small: '4px',
      medium: '4px',
      large: '4px',
    },
  },
  secondaryButton: {
    colors: {
      color: grey100,
      background: 'transparent',
      hoverColor: white,
      hoverBackground: wwtdarkblue,
      disabledColor: grey40,
      disabledBackground: 'transparent',
    },
    borderRadius: {
      small: '4px',
      medium: '4px',
      large: '4px',
    },
  },
  contentBlock: {
    background: {
      light: wwtgrey, //?
      dark: wwtblack, //?
    },
  },
  cardGrid: {
    background: 'transparent',
    item: {
      prominent: {
        headerColor: white,
        color: white,
        background: `linear-gradient(270deg, #0860CE 0%, #1136B0 54.69%, #1A098E 100%);`,
      },
      default: {
        headerColor: black,
        color: grey50,
        background: `linear-gradient(270deg, ${wwtgrey}, ${wwtgrey});`,
      },
    },
  },
  homeDevicesGrid: {
    background: `linear-gradient(0deg, #0860CE 0%, #1136B0 54.69%, #1A098E 100%);`,
    item: {
      headerColor: white,
      color: white,
      background: wwtdarkblue,
    },
  },
  availablePaymentTypes: ['Quote'],
  phrases: {
    buyButtonLabel: 'Add to Cart',
    invalidPrice: 'Pricing upon request.',
    disclaimerNonBinding:
      'Pricing is for guidance and planning purposes. This is not a binding offer from Cisco. Prices may vary on country and region. Price does not include Cisco Supported Services.',
    disclaimerAdditionalCost: 'Additional costs for support and installation services may apply.',
    disclaimerPricingRequest: 'Pricing upon request.',
  },
  globalMenuBottomLinks,
  formApiEndpoint: pwpVariables.formApiEndpoint,
  formSiteId: pwpVariables.formSiteId,
  formCampaignId: `hardware.wwt`,
  elqFormNameContact: pwpVariables.elqFormNameContact,
  elqFormNameQuote: pwpVariables.elqFormNameQuote,
  elqFormNameSubscription: pwpVariables.elqFormNameContact,
  commonColors: {
    white,
    black,
  },
  siteMetadata: pwpVariables.siteMetadata,
  pathPrefix: '',
  assetPrefix: '',
  order: {
    usePaymentTypeDialog: true,
    usePDFButton: true,
    orderSummaryButtonLabel: 'Continue',
  },
};
