/*eslint-disable no-unused-vars*/
import React, { useEffect, useRef, useState } from 'react';

import { isOnServer } from './isOnServer';

const DAY_MILLISECONDS = 864e5; // = 86400000 -> 1000ms * 60s * 60m * 24h === 1 day in milliseconds
const DEFAULT_TTL_DAYS = 31;
const DEFAULT_PATH = '/';

/**
 * @typedef {object} CookieStringOptions
 * @property {number} [useCookieStringOptions.ttlDays=31] time to live for cookie
 * @property {string} [useCookieStringOptions.path='/'] path added to cookie - '/'
 */

/**
 * Store string as cookie
 * @param {string} key cookie store identifier
 * @param {string|null} initState initial state to store
 * @param {boolean} storeCookie
 * @param {CookieStringOptions} [options]
 * @returns {[string, React.Dispatch<React.SetStateAction<string|null>>]}
 */
export function useCookie(key, initState, storeCookie = true, options = {}) {
  let [state, setState] = useState(initState);
  let mutableOptions = useRef(options);
  let [initRead, setInitRead] = useState(false);

  useEffect(() => void (mutableOptions.current = options), [options]);

  useEffect(() => {
    setState(prevState => readBrowserCookie(key) || prevState);
    setInitRead(true);
  }, [key]); // only on first render

  useEffect(() => {
    if (!initRead) return void null; //don't write cookies before we have checked if there is prior data
    storeCookie && writeBrowserCookie(key, state, mutableOptions.current);
  }, [initRead, key, state, storeCookie]);

  return [state, setState];
}

/**
 * Store serializable data as cookie
 * @template T
 * @param {string} key cookie store identifier
 * @param {T} initState initial state to store
 * @param {boolean} storeCookie
 * @param {CookieStringOptions} [options]
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]}
 */
export function useCookieJSON(key, initState = {}, storeCookie = true, options = {}) {
  let [cookie, setCookie] = useCookie(key, JSON.stringify(initState), storeCookie, options);
  let setStringifiedCookie = value => setCookie(JSON.stringify(value));

  return [JSON.parse(cookie), setStringifiedCookie];
}

/**
 * @param {string} key cookie store identifier
 */
export function readBrowserCookie(key) {
  if (isOnServer) return null;
  return getCookie(document.cookie, key);
}

export function getCookie(cookie, key) {
  if (!cookie) return '';
  let cookies = cookie.split('; ');
  let cookieByProvidedKey = cookies.map(cookie => cookie.split('=')).find(([cookieKey]) => cookieKey === key);
  let [, value] = cookieByProvidedKey || [];
  return decodeURIComponent(value || '');
}

/**
 * @param {string} key cookie store identifier
 * @param {string|((_: string) => string)} value
 * @param {CookieStringOptions} [options]
 * @returns void
 */
function writeBrowserCookie(key, value, options = {}) {
  let { ttlDays = DEFAULT_TTL_DAYS } = options;
  let { path = DEFAULT_PATH } = options;

  if (isOnServer) return;
  if (typeof value === 'string' && value.length) {
    const expires = new Date(Date.now() + ttlDays * DAY_MILLISECONDS).toUTCString();
    const formattedCookie = `${key}=${encodeURIComponent(value)}; expires=${expires}; path=${path}; SameSite=Strict`;
    document.cookie = formattedCookie;
  } else if (!value) {
    deleteCookie(key, options);
  }
}

/**
 * @param {string} key cookie store identifier
 * @param {CookieStringOptions} options
 * @returns void
 */
function deleteCookie(key, options) {
  let { path = DEFAULT_PATH } = options;

  if (isOnServer) return null;
  if (readBrowserCookie(key)) {
    document.cookie = `${key} = ;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${path}`;
  }
}
