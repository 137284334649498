import { Product, ProductCategory, ProductWithSelectedVariant } from 'context/Quote/typedefs';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ImageShape } from 'components/generic/Image';

export type InputType = 'email' | 'name' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';

export type FieldOutput = {
  value: string;
  name: string;
  id: string;
  required: boolean;
};

/**
 * Interface for a Slide in a Gallery (see components/Gallery/)
 */
export interface ISlide {
  id: string;
  heading: string;
  image: Image;
  description?: RichText;
}

export function isPwpImage(image: unknown): image is PwpImage {
  return typeof image === 'object' && image !== null && isImage(image);
}

export type PwpImage = {
  __typename: string;
  id: string;
  image: Image;
  heading: string;
  credits: string;
  focalPoint: FocalPoint;
  cutOut?: CutOut;
  description: RichText;
};

export type PwpGridImage = {
  id: string;
  image: GridImage;
  heading: string;
  credits: string;
  focalPoint: FocalPoint;
  cutOut?: CutOut;
  description: RichText;
};

function isImage(image: unknown): image is Image {
  return (
    typeof image === 'object' &&
    image !== null &&
    '__typename' in image &&
    (image as ContentfulBase).__typename === 'ContentfulAsset' &&
    'gatsbyImageData' in image &&
    typeof (image as Image).gatsbyImageData === 'object'
  );
}

export type Image = ImageBase & {
  gatsbyImageData: IGatsbyImageData;
};

//? something like this to separate grid images and others?
type GridImage = ImageBase & {
  gatsbyImageData: IGatsbyImageData;
};

export type ImageBase = {
  __typename: 'ContentfulAsset';
  id: string;
  title: string;
  description: string;
  file: FileType;
  svg?: SVG;
};

type SVG = {
  content: string;
};

export type CutOut = { x1: number; y1: number; x2: number; y2: number };

export type FocalPoint = {
  x: number;
  y: number;
};

export type Fluid = {
  aspectRatio: number;
  sizes: string;
  src: string;
  srcSet: string;
  srcSetWebp: string;
  srcWebp: string;
};

export type FileType = {
  contentType?: string;
  url: string;
  details: {
    image: { width: number; height: number };
  };
};

/** @deprecated */
export interface TemplateLinkData {
  __typename: string;
  id: string;
  slug: string;
}

export type RichText = { raw: string; references: Array<ReferencesType> } | string; // references is **intentially not** optional here (`references?:`) even if it is optional. the references array is required in the contentful / gatsby rich text types but not required functionally (THIS CAN CHANGE!)

export type LinkItem = { id: string; title: string; slug: string };

export type File = { url: string; details: { image: { width: number; height: number } } };

export type DeviceImage = PwpImage;

export type ReferencesType = RichTextAssetBase & Button;

export type RichTextAssetBase = {
  contentful_id: string;
  __typename: string;
  description: string;
  file: FileType;
};

export type Button = {
  contentful_id: string;
  __typename: string;
  label: string;
  icon: Icon;
  type: Type;
  color: Color;
  action: Action;
  url: string;
  reference: { __typename: string; slug: string };
  file: Asset;
};

export type Icon = 'None' | 'Pen' | 'Camera' | 'Cart';

export type Type = 'Primary' | 'Secondary';

export type Color = 'Green' | 'Purple' | 'Blue' | 'Black' | 'White' | 'Orange';

export type Action = 'Download' | 'Link' | 'Play' | 'Modal';

export type Asset = {
  __typename: 'ContentfulAsset';
  id: string;
  title: string;
  description: string;
  file: { url: string };
};

export type SelectOption = { value: string; displayValue: string; disabled?: boolean };

export type GridLayout = 'Default' | 'Right' | 'Left' | 'Top' | 'Bottom';

export type GridSize = 'Default' | 'Wide' | 'Tall' | 'Big' | 'DefaultWithImage' | 'AutoLayout';

export type ArticleBody = {
  __typename: 'ContentfulArticleBody';
  id: string;
  heading: string;
  body: RichText;
};

export type ArticleGallery = {
  __typename: 'ContentfulArticleGallery';
  id: string;
  heading: string;
  images: Array<PwpImage>;
  body: RichText;
};

export type ArticleParallax = {
  __typename: 'ContentfulArticleParallax';
  id: string;
  heading: string;
  stickyImage: Image;
  textPosition: 'Left' | 'Center' | 'Right';
  scrollyText: ArticleBody;
};

export type ArticleTable = {
  __typename: 'ContentfulArticleTable';
  id: string;
  heading: string;
  body: RichText;
  table: { table: object | Array<object> };
};

export type ArticleHero = {
  __typename: 'ContentfulArticleHero';
  id: string;
  heading: string;
  image: Image;
  textColor: 'white' | 'black'; //? disabled?
  body: RichText;
};

export function isContentfulSimpleArticle(article: unknown): article is ContentfulSimpleArticle {
  return (
    typeof article === 'object' &&
    article !== null &&
    '__typename' in article &&
    (article as ContentfulBase).__typename === 'ContentfulSimpleArticle'
  );
}

export type ContentfulSimpleArticle = {
  __typename: 'ContentfulSimpleArticle';
  id: string;
  heading: string;
  description: { description: string };
  body: RichText;
  images: Array<PwpImage>;
  fullWidthImages: Array<PwpImage>;
};

export type ContentfulUrl = {
  __typename: 'ContentfulUrl';
  id: string;
  title: string;
  url: string;
};

export type ContentfulBase = {
  __typename: string;
  id: string;
};

export type ContentfulArticle = ContentfulBase & {
  __typename: 'ContentfulArticle';
  slug: string;
  shortHeading: string;
  gridSize: GridSize;
  thumbnail: Image;
  heading?: string;
  description?: { description: string };
  articleSections: Array<ArticleHero | ArticleBody | ArticleGallery | ArticleParallax | ArticleTable | PwpImage>;
};

export type ContentfulPage = Omit<ContentfulBase, 'description'> & {
  __typename: 'ContentfulPage';
  contentful_id: string;
  slug: string;
  metaKeywords: { metaKeywords: string };
  plainDescription: { plainDescription: string };
  heading?: string;
  description?: RichText;
  image: PwpImage;
  inPageMenu: ContentfulNavigation;
};

export type ContentfulWorkspaceSubCategory = {
  __typename: 'ContentfulWorkspaceSubCategory';
  heading: string;
  shortHeading: string;
  id: string;
  slug: string;
  images: Array<PwpGridImage>;
  numberOfSeats: string;
  physicalSizeM2: string;
  description: { description: string };
  workspaces: ContentfulWorkspace[];
  gridSize: GridSize;
};

export type ContentfulWorkspaceCategory = {
  __typename: 'ContentfulWorkspaceCategory';
  id: string;
  heading: string;
  slug: string;
  numberOfSeats: string;
  body: { body: string };
  description: { description: string };
  images: Array<PwpGridImage>;
  workspaceSubCategories: ContentfulWorkspaceSubCategory[];
  gridSize: GridSize;
};

type ContentfulSvgIcon = Asset & {
  svg: { content: string };
  gatsbyImageData: null;
};

export type ContentfulRoomExperience = {
  __typename: 'ContentfulRoomExperience';
  heading: string;
  subHeading: string;
  subsections: Array<ContentfulRoomExperienceSubCategory>;
};

export type ContentfulRoomExperienceSubCategory = {
  __typename: 'ContentfulRoomExperience';
  id: string;
  heading: string;
  subHeading: string;
  body: RichText;
  icon: ContentfulSvgIcon;
  image: PwpImage;
};

export type ContentfulWorkspace = {
  __typename: 'ContentfulWorkspace';
  id: string;
  heading: string;
  shortHeading: string;
  slug: string;
  images: Array<PwpGridImage>;
  hotspotHero: {
    __typename: 'ContentfulHotspotHero';
    id: string;
    heading: string;
    image: Image;
    focalPoint: FocalPoint;
    hotspots: Array<{
      heading?: string;
      description: string;
      product: Product;
      Position: { x: number; y: number };
      image: Image;
    }>;
  };
  products: Product[];
  featuresHeading: string;
  features: { __typename: 'ContentfulFeature'; id: string; heading: string }[];
  featuresInfoboxAlternatePosition: boolean;
  roomExperience: ContentfulRoomExperience;
};

export type ContentfulWorkspaceCategoryWithLinkRequirements = Omit<
  ContentfulWorkspaceCategory,
  'workspaceSubCategories'
> & {
  workspaceSubCategories: [WorkspaceSubCatWithLinkRequirements & { workspaces: Array<WorkspaceWithLinkRequirements> }];
};

export type WorkspaceSubCatWithLinkRequirements = ContentfulWorkspaceSubCategory & {
  workspaceCategory: [ContentfulWorkspaceCategoryWithLinkRequirements];
};

export type WorkspaceWithLinkRequirements = ContentfulWorkspace & {
  workspaceSubCategory: [ContentfulWorkspaceSubCategory & { workspaceCategory: [ContentfulWorkspaceCategory] }];
};

export type MuxVideo = {
  __typename: 'contentfulMuxVideoVideoJsonNode';
  assetId: string;
  id: string;
  playbackId: string;
  ratio: string;
  ready: boolean;
  loop: boolean;
  uploadId: string;
};

export type VideoPlayMode = 'Loop' | 'Play once';
type VideoControls = 'Controls' | 'Play and Pause' | 'None';
type VideoAudio = 'Muted' | 'Unmuted';

type ContentfulVideoBase = ContentfulBase & {
  __typename: 'ContentfulMuxVideo';
  heading?: string;
  body?: RichText;
  mode?: VideoPlayMode;
  controls?: VideoControls;
  audio: VideoAudio;
  posterFrame: number;
  video: unknown;
  externalVideoUrl: unknown;
  autoplay: boolean;
};

export type ContentfulVideoMux = ContentfulVideoBase & {
  video: MuxVideo;
};

export function isContentfulVideoMux(video: unknown): video is ContentfulVideoMux {
  return (
    typeof video === 'object' &&
    video !== null &&
    '__typename' in video &&
    (video as ContentfulVideoBase).__typename === 'ContentfulMuxVideo' &&
    'video' in video &&
    Boolean((video as ContentfulVideoBase).video)
  );
}

export type ContentfulVideoYoutube = ContentfulVideoBase & {
  externalVideoUrl: string;
};

export function isContentfulVideoYoutube(video: unknown): video is ContentfulVideoYoutube {
  return (
    typeof video === 'object' &&
    video !== null &&
    '__typename' in video &&
    (video as ContentfulVideoBase).__typename === 'ContentfulMuxVideo' &&
    'externalVideoUrl' in video &&
    Boolean((video as ContentfulVideoBase).externalVideoUrl)
  );
}

export type ContentfulVideo = ContentfulVideoYoutube | ContentfulVideoMux;

export type ColorScheme = 'Light' | 'Dark' | 'None';
export type CardDisplayType = ImageShape | 'icon';

type ContentfulBlockBase = ContentfulBase & {
  __typename: 'ContentfulBlock';
  shouldMuteBottomPadding: boolean;
  backgroundColor?: ColorScheme;
  heading?: string;
  body?: RichText;
};

export type ContentfulBlockDefault = ContentfulBlockBase & {
  layout: 'Default';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockDefaultReversedAlternatingPosition = ContentfulBlockBase & {
  layout: 'Default reversed alternating position';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockDefaultWithNormalSizedImage = ContentfulBlockBase & {
  layout: 'Default with Normal Sized Image';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockVideo = ContentfulBlockBase & {
  layout: 'Video Player' | 'Video Player Hero';
  sections: Array<ContentfulVideo>;
};

export type ContentfulBlockWebexAudioHero = ContentfulBlockBase & {
  layout: 'Webex Audio Hero';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockCallToAction = ContentfulBlockBase & {
  layout: 'Call to Action';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockCarousel = ContentfulBlockBase & {
  layout: 'Carousel';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockCardCarousel = ContentfulBlockBase & {
  layout: 'Card Carousel';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockChapters = ContentfulBlockBase & {
  layout: 'Chapters';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockBodyWide = ContentfulBlockBase & {
  layout: 'Body Wide';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockCards = ContentfulBlockBase & {
  layout: 'Cards';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockCardsWithIcons = ContentfulBlockBase & {
  layout: 'Cards with icons';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockAccordion = ContentfulBlockBase & {
  layout: 'Accordion';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockAccordionWithIcons = ContentfulBlockBase & {
  layout: 'Accordion with icons';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockAccordionWithSquareImages = ContentfulBlockBase & {
  layout: 'Cards with square images';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockHero = ContentfulBlockBase & {
  layout: 'Hero';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockHeroBleedingHero = ContentfulBlockBase & {
  layout: 'Hero: Bleeding page image background';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockQuote = ContentfulBlockBase & {
  layout: 'Quote';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockSubscribeToNewsletter = ContentfulBlockBase & {
  layout: 'Subscribe to newsletter';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockLongArticleWithAside = ContentfulBlockBase & {
  layout: 'Long Article with Aside';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockLongArticleInColumns = ContentfulBlockBase & {
  layout: 'Long Article in Columns';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockGrid = ContentfulBlockBase & {
  layout: 'Grid';
  sections: Array<ContentfulSimpleArticle>;
};

export type ContentfulBlockiFrame = ContentfulBlockBase & {
  layout: 'iFrame';
  sections: Array<ContentfulUrl>;
};

export type ContentfulBlockFordHero = ContentfulBlockBase & {
  layout: 'Ford Hero';
  sections: Array<ContentfulVideo>;
};

export type ContentfulBlockFordWorkspace = ContentfulBlockBase & {
  layout: 'Ford Workspace';
  sections: Array<ContentfulVideo>;
};

export function isContentfulBlockType(block: unknown) {
  return (
    typeof block === 'object' &&
    block !== null &&
    '__typename' in block &&
    (block as ContentfulBase).__typename === 'ContentfulBlock'
  );
}

export type ContentfulBlock =
  | ContentfulBlockVideo
  | ContentfulBlockWebexAudioHero
  | ContentfulBlockCallToAction
  | ContentfulBlockCarousel
  | ContentfulBlockCardCarousel
  | ContentfulBlockChapters
  | ContentfulBlockBodyWide
  | ContentfulBlockCards
  | ContentfulBlockCardsWithIcons
  | ContentfulBlockAccordionWithSquareImages
  | ContentfulBlockAccordion
  | ContentfulBlockAccordionWithIcons
  | ContentfulBlockHero
  | ContentfulBlockHeroBleedingHero
  | ContentfulBlockQuote
  | ContentfulBlockDefault
  | ContentfulBlockDefaultReversedAlternatingPosition
  | ContentfulBlockDefaultWithNormalSizedImage
  | ContentfulBlockSubscribeToNewsletter
  | ContentfulBlockLongArticleWithAside
  | ContentfulBlockLongArticleInColumns
  | ContentfulBlockGrid
  | ContentfulBlockiFrame
  | ContentfulBlockFordHero
  | ContentfulBlockFordWorkspace;

export type ContentfulPageSection =
  | ContentfulSimpleArticle
  | ContentfulBlock
  | ProductCategory
  | ContentfulWorkspaceCategory;

export type PageSections = {
  sections: Array<ContentfulPageSection>;
};

//todo add back a type for requirements EntryLink used in cards (also needs heading, description, image ....) (was NavigationReferenceBase)
export interface EntryLinkCardTargetCommonRequirements extends EntryLinkTargetCommonRequirements {
  heading: string;
  description: string | { description: string } | RichText;
  image: PwpImage;
}

interface EntryLinkTargetCommonRequirements {
  id: string;
  slug: string;
}

export interface EntryLinkTargetGeneric extends EntryLinkTargetCommonRequirements {
  __typename: (
    | ContentfulArticle
    | ContentfulPage
    | Product
    | ProductCategory
    | ProductWithSelectedVariant
  )['__typename'];
}

export interface WorkspaceCategoryLinkTarget extends EntryLinkTargetCommonRequirements {
  __typename: ContentfulWorkspaceCategory['__typename'];
}

export interface WorkspaceSubCategoryLinkTarget extends EntryLinkTargetCommonRequirements {
  __typename: ContentfulWorkspaceSubCategory['__typename'];
  workspaceCategory: Array<WorkspaceCategoryLinkTarget>;
  workspaces: ContentfulWorkspaceSubCategory['workspaces'];
}

export interface WorkspaceLinkTarget extends EntryLinkTargetCommonRequirements {
  __typename: ContentfulWorkspace['__typename'];
  workspaceSubCategory: Array<WorkspaceSubCategoryLinkTarget>;
}

export type EntryLinkTarget =
  | EntryLinkTargetGeneric
  | WorkspaceLinkTarget
  | WorkspaceCategoryLinkTarget
  | WorkspaceSubCategoryLinkTarget;

export interface ContentfulNavigationBase {
  __typename: 'ContentfulNavigation';
  id: string;
  label: string;
  block?: ContentfulBlock;
  thumbnail?: PwpImage;
}

export interface ContentfulNavigationSubNav extends ContentfulNavigationBase {
  subNavigation: Array<ContentfulNavigation>;
}

export function isContentfulNavigationSubNav(target: unknown): target is ContentfulNavigationSubNav {
  return (
    typeof target === 'object' &&
    target !== null &&
    'subNavigation' in target &&
    Boolean((target as ContentfulNavigationSubNav).subNavigation)
  );
}

interface ContentfulNavigationReference extends ContentfulNavigationBase {
  reference: EntryLinkTarget;
}

type ContentfulNavigationValidLink = ContentfulNavigationReference | ContentfulNavigationLink;

export function isContentfulNavigationValidLink(target: ContentfulNavigation): target is ContentfulNavigationValidLink {
  return isContentfulNavigationReference(target) || isContentfulNavigationLink(target);
}

export function isContentfulNavigationReference(target: unknown): target is ContentfulNavigationReference {
  return (
    typeof target === 'object' &&
    target !== null &&
    'reference' in target &&
    Boolean((target as ContentfulNavigationReference).reference)
  );
}

export interface ContentfulNavigationLink extends ContentfulNavigationBase {
  url: string;
}

export function isContentfulNavigationLink(target: unknown): target is ContentfulNavigationLink {
  return (
    typeof target === 'object' &&
    target !== null &&
    'url' in target &&
    Boolean((target as ContentfulNavigationLink).url)
  );
}

export type ContentfulNavigation =
  | ContentfulNavigationReference
  | ContentfulNavigationLink
  | ContentfulNavigationSubNav;

export type Location = {
  hash?: string;
  state?: { workspaceToggle?: boolean; modal?: boolean };
};

export type ContentfulProductFeature = {
  __typename: 'ContentfulProductFeature';
  id: string;
  heading: string;
  shortHeading: string;
  description: RichText;
  images: Array<PwpGridImage>;
  display: 'Header' | 'Hero' | 'Fullscreen';
  textPosition: 'Right' | 'Left' | null;
  product: Pick<Product, 'availability' | 'id' | 'slug'>;
};
