import { useState, useCallback } from 'react';
import useLayoutEffect from './useIsomorphicLayoutEffect';

/**
 * Lock scroll on body by
 * ```javascript
 * const [lockBodyScroll, unlockBodyScroll] = useLockBodyScroll();
 * lockBodyScroll();
 * ```
 * Unlock:
 * ```javascript
 * unlockBodyScroll();
 * ```
 *
 * Will set `overflow` style on `body` back to existing on component unmount
 *
 */
export function useLockBodyScroll(isLockedAtInit = false) {
  const [isLocked, setIsLocked] = useState(isLockedAtInit);

  const lockBodyScroll = useCallback(() => setIsLocked(true), []);

  const unlockBodyScroll = useCallback(() => setIsLocked(false), []);

  useLayoutEffect(() => {
    document.body.style.overflow = isLocked ? 'hidden' : 'visible';
    return () => void (document.body.style.overflow = 'visible');
  }, [isLocked]);

  return [lockBodyScroll, unlockBodyScroll];
}
