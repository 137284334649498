import { InventoryType, QuotedItem } from '../typedefs';

export function getProductPrice(product: InventoryType | QuotedItem): number {
  if (
    product.__typename === 'ProductWithSelectedVariant' &&
    product.selectedVariant?.displayPrice &&
    product.selectedVariant.price >= 0
  ) {
    return product.selectedVariant.price;
  }
  if (product.__typename === 'ProductWithSelectedVariant' && product.selectedVariant?.displayPrice === false) {
    return -1;
  }
  return product.price;
}

/**
 * @deprecated
 */
export function getProductMonthlyPrice(product: InventoryType): number {
  return product.monthlyPrice;
}

/** @typedef {'price'|'monthlyPrice'} PriceType */

/**
 * @deprecated
 * @param {import('../typedefs').InventoryType} product
 * @param {PriceType} [priceType='price']
 * @returns {number}
 */
// function getIncludedPrice(product, priceType = 'price') {
//   if (!Array.isArray(product.includedProducts) || !product.includedProducts.length) return 0;
//   const cmsProductTypeKey = priceTypeToCmsProductTypeKeys[priceType];
//   let includedTotalPrice = product.includedProducts.reduce(
//     (totalPrice, includedProduct) => totalPrice + includedProduct[cmsProductTypeKey],
//     0
//   );
//   return includedTotalPrice;
// }

// const priceTypeToCmsProductTypeKeys = {
//   price: 'price',
//   monthlyPrice: 'monthlyPrice',
// };
