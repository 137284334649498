import { QuoteStateAction } from './typedefs';
import { v4 as uuid } from 'uuid';
import React, { useCallback, useMemo, useReducer, useState, ReactNode } from 'react';
import {
  calculatePlacedOrderTotal,
  calculateMontlyPayTotal,
  calculateRequestQuoteTotal,
  getQuoteLeaseTotal as getQuoteLeaseTotalImpl,
  getQuoteTotal as getQuoteTotalImpl,
} from './utils/quoteCalculations';
import { toCurrencyString, useLocalStorageReducer, useLocalStorageState } from '../../utils';
import { ProductsWithoutDeterminedPaymentType } from './ProductsWithoutDeterminedPaymentType';
import { QuoteContext } from './QuoteContext';
import { extractAndSetLastQuoteItemState } from './utils/extractAndSetLastQuoteItemState';
import { reducer } from './reducer';
import { useActionSideeffects } from './hooks/useActionSideeffects';
import { useDropdownState } from '../NavigationDropdownState';
import { useQuoteStateDebugLogger } from './hooks/useQuoteStateDebugLogger';
import { interuptAction, useReducerMiddleware } from './hooks/useReducerMiddleware';
import { useTrackCartEvents } from './hooks/useTrackCartEvents';

const storageKey = process.env.GATSBY_LOCAL_STORAGE_KEY || 'cisco-pwp-quote-2020-LOCAL';

export function QuoteProvider({ children }: { children: ReactNode }) {
  const { dispatchDropdownState } = useDropdownState();
  const trackEvent = useTrackCartEvents();

  const triggerAddCartUserConfirmation = (action: QuoteStateAction) => {
    if (action.type === 'add' || action.type === 'interrupted') {
      dispatchDropdownState({ type: 'open', menuType: 'selectpurchasetype' });
    }
  };

  const [lastInQuote, setLastInQuote] = useState([]);
  const extractLastInQuote = extractAndSetLastQuoteItemState(setLastInQuote);

  function interceptAddProductComplete(action: QuoteStateAction) {
    if (action.type === 'addWithPaymentType') {
      dispatchPaymentTypeDetermineQuote({ type: 'reset' });
    }
    return action;
  }

  function interceptAddProduct(action: QuoteStateAction) {
    if (action.type === 'add') {
      dispatchPaymentTypeDetermineQuote(action);
      return interuptAction(action);
    }
    return action;
  }

  const [paymentTypeDetermineQuote, dispatchPaymentTypeDetermineQuote] = useQuoteStateDebugLogger(
    'ProductsWithoutDeterminedPaymentTypeStore',
    useReducer(reducer, [])
  );

  const [quote, dispatchQuote] = useReducerMiddleware(
    useActionSideeffects(useQuoteStateDebugLogger('Quote', useLocalStorageReducer(storageKey, reducer, [])), [
      extractLastInQuote,
      trackEvent,
      triggerAddCartUserConfirmation,
    ]),
    [interceptAddProduct, interceptAddProductComplete]
  );

  /** TODO: See if we can remove some stuff here, to make it simpler. Rather do formatting in the Views. Then we could also simplify the QuoteContext type. */
  const [quoteId] = useLocalStorageState(`${storageKey}-id`, uuid());
  const getQuoteTotal = useCallback(() => getQuoteTotalImpl(quote), [quote]);
  const getQuoteLeaseTotal = useCallback(() => getQuoteLeaseTotalImpl(quote), [quote]);
  const getTotalCurrencyString = useCallback(() => toCurrencyString(getQuoteTotalImpl(quote)), [quote]);
  const getLeaseTotalCurrencyString = useCallback(() => toCurrencyString(getQuoteLeaseTotalImpl(quote)), [quote]);
  const totalCurrencyStringFormatted = useMemo(() => getTotalCurrencyString(), [getTotalCurrencyString]);
  const leaseTotalCurrencyStringFormatted = useMemo(() => getLeaseTotalCurrencyString(), [getLeaseTotalCurrencyString]);
  const requestQuoteTotal = useMemo(() => calculateRequestQuoteTotal(quote), [quote]);
  const placedOrderTotal = useMemo(() => calculatePlacedOrderTotal(quote), [quote]);
  const monthlyPayTotal = useMemo(() => calculateMontlyPayTotal(quote), [quote]);

  const contextValue = useMemo(
    () => ({
      quote,
      quoteId,
      dispatchQuote,
      getQuoteTotal,
      getQuoteLeaseTotal,
      getTotalCurrencyString,
      totalCurrencyStringFormatted,
      leaseTotalCurrencyStringFormatted,
      lastInQuote,
      placedOrderTotal,
      monthlyPayTotal,
      requestQuoteTotal,
    }),
    [
      dispatchQuote,
      getQuoteLeaseTotal,
      getQuoteTotal,
      getTotalCurrencyString,
      quote,
      quoteId,
      totalCurrencyStringFormatted,
      leaseTotalCurrencyStringFormatted,
      lastInQuote,
      placedOrderTotal,
      monthlyPayTotal,
      requestQuoteTotal,
    ]
  );

  return (
    <ProductsWithoutDeterminedPaymentType.Provider
      value={{ dispatchPaymentTypeDetermineQuote, paymentTypeDetermineQuote }}
    >
      <QuoteContext.Provider value={contextValue}>{children}</QuoteContext.Provider>
    </ProductsWithoutDeterminedPaymentType.Provider>
  );
}
