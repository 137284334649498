import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { isOnServer, useResizeObserverElemRect } from 'utils';

/** WebexHeaderBuffer component measures the height of the provided webex header to push down the page content accordingly */
export default function WebexHeaderBuffer() {
  let webexHeaderSelector = `.wx-wrapper.navigation-wrapper header`;
  let webexHeaderRef = useRef<Element>(!isOnServer ? document.querySelector(webexHeaderSelector) : null);
  let [resizeDimensions] = useResizeObserverElemRect(webexHeaderRef);

  //hack this hack is for updating --headerHeight css custom property according to the current webex header height
  //! overrides --headerHeight defined in ./variables.ts and set in <GlobalStyle>
  useEffect(() => {
    let root = !isOnServer ? document.documentElement : undefined;
    root?.style.setProperty('--headerHeight', `${resizeDimensions?.height}px`);
  }, [resizeDimensions]);

  return <WebexHeaderBufferContainer style={{ height: `${resizeDimensions?.height || 130}px` }} />;
}

const WebexHeaderBufferContainer = styled.div`
  position: relative;
  height: 130px; // fallback header height if not detected programatically
`;
