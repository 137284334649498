import { addToQuote } from '../reducer';
import { isDevEnvironment } from '../../../utils';

/** TODO: Type this file */
export function extractAndSetLastQuoteItemState(setState) {
  return action => {
    let addedItem = action.payload?.item;
    switch (action.type) {
      case 'add':
        setState(addToQuote(addedItem, [], action.payload));
        break;
      case 'remove': {
        setState(currentItems => {
          try {
            let removingObjectPosition = currentItems.findIndex(item => {
              if ('product' in item) return item.product.id === addedItem.product.id;
              if ('bundle' in item) return item.bundle.id === addedItem.bundle.id;
              return false;
            });
            if (removingObjectPosition > -1) {
              return [
                ...currentItems.slice(0, removingObjectPosition),
                ...currentItems.slice(removingObjectPosition, -1),
              ];
            }
          } catch (error) {
            if (isDevEnvironment) console.error(error);
            return []; // this is (ATM) ok since the last items added to quote is only used at the time after 'add' to quote and before other alternations of the quote or user alternations
          }
          return currentItems;
        });
        break;
      }
      default:
        return null;
    }
  };
}
