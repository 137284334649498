/**
 * Takes a hex color like #FFFFFF and returns
 * it like '255, 255, 255'
 * @argument {string} hex color in hex format
 */
export function hexToRgbString(hex: string) {
  const result = hexToRgb(hex);
  if (!result) return null;
  return `${Object.values(result).join(', ')}`;
}

/**
 * @param hex takes a hex color format like #FFFFFF
 */
function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}
