// import { useDebug } from 'Debug';
import { RefObject, useEffect, useRef } from 'react';

type Options = {
  elementRef?: RefObject<HTMLElement>;
  capture?: boolean;
  debug?: { name?: string; enable: boolean };
};

export function useClickOutside<ElementType extends HTMLElement>(
  handler: (event: MouseEvent) => void,
  options?: Options
) {
  const generatedRef = useRef<ElementType>(null);
  const ref: RefObject<ElementType> = options?.elementRef
    ? (options.elementRef as RefObject<ElementType>)
    : generatedRef;

  const printDebug = options?.debug?.enable || false; // = useDebug()
  if (options?.debug !== undefined && 'name' in options?.debug) options.debug.name = `<${options.debug.name}>` || ``;

  let capture = options?.capture !== undefined && options?.capture;

  // a issue with 'toggle on/off' button mixed with 'toggle off' in the useClickOutside handler:

  // // in the cases where we wish to press a toggle button outside the elem handled by useClickOutside the useClickOutside
  // // handler, called in the same tick as the toggle button's onClick, is ran and potentially changing the visibility
  // // state for a component and then make the toggle button in the instance one presses it change the state from being a
  // // _toggle off_ to being a _toggle on_ button.
  // // more info on this:
  // * use event.stopPropagation() and native events on buttons competing with useClickOutside for toggle state (for example open / close toggling modals)
  // https://gideonpyzer.dev/blog/2018/12/29/event-propagation-react-synthetic-events-vs-native-events/
  // more at the source: https://github.com/facebook/react/issues/7094

  useEffect(() => {
    function listener(event: MouseEvent) {
      const { target: clickedElem } = event as { target: Node | null };
      const { current: parentElem } = ref;

      if (printDebug) {
        console.groupCollapsed(
          `a %cclick%c registered in %c${options?.debug?.name}%c's ${useClickOutside.name} handler`,
          'color: greenyellow',
          'color: unset',
          'color: white'
        );
        console.log(`clicked element's event.target:`, event.target);
      }

      if (!clickedElem || !parentElem) {
        if (printDebug) {
          console.warn(`bailing: !clickedElem: ${!clickedElem} || !parentElem: ${!parentElem}`);
          console.groupEnd();
        }
        return;
      }

      if (parentElem.contains(clickedElem)) {
        if (printDebug) {
          console.info(`parentElem contains clickedElem:`, parentElem?.contains(clickedElem));
          console.groupEnd();
        }
        return;
      }

      if (printDebug) console.warn(`calling event handler with event:`, event);

      handler(event);

      if (printDebug) console.groupEnd();
    }

    if (printDebug) console.log(`%c${options?.debug?.name}%c: 🎍 adding listener...`, 'color: white', 'color: unset');

    document.body.addEventListener('click', listener, { capture });

    return () => {
      if (printDebug)
        console.log(`%c${options?.debug?.name}%c: 🧼 removing old listener...`, 'color: white', 'color: unset');
      document.body.removeEventListener('click', listener, { capture });
    };
  }, [options?.debug?.name, handler, printDebug, ref, capture]);

  useEffect(() => {
    if (printDebug) console.log(`${options?.debug?.name}`, { capture, handler });
  }, [handler, capture, options?.debug?.name, printDebug]);

  return ref;
}
