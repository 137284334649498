import { branding, button, mediaQueries, navType } from 'styles';
import { isDevEnvironment, mergeClassNames } from 'utils';
import styled, { css } from 'styled-components';
import { IconType, IconTypes } from './Icon';
import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import getRoute from 'routes/getRoute';
import { getEntryAnchor } from 'routes/getEntryIdentifier';
import { Asset, ContentfulBlock, EntryLinkTarget } from 'types';

type LinkButtonTypes = 'Primary' | 'Secondary'; // Allowed button types
type LinkButtonActions = 'Link' | 'Download' | 'Modal' | 'Play' | 'Target _blank'; // Allowed button action types

/**
 * LinkButton - link that visually appears as <PrimaryButton> or <SecondaryButton> without click handling
 * compatible with the Pwp Contentful button data
 */
interface LinkButtonProps {
  label: string;
  action: LinkButtonActions; //'Link'|'Download'|'Modal'|'Play'
  icon?: string;
  type?: LinkButtonTypes;
  replace?: boolean; // will replace the current URL in the browser history
  reference?: EntryLinkTarget; // internal entry reference
  block?: ContentfulBlock; // internal entry reference
  to?: string; // internal entry link
  file?: Asset; // file link
  url?: string; // external link
  onClick?: React.DOMAttributes<HTMLAnchorElement>['onClick'];
  className?: string; // className to be passed to the wrapping <Link>|<a> element
  internalClassName?: string; // className passed to the internal (visual) part of the link
}

export default function LinkButton({
  label,
  icon,
  type = 'Primary',
  action,
  replace = false,
  url,
  reference,
  block,
  to,
  file,
  onClick,
  className,
  internalClassName,
}: LinkButtonProps) {
  let link = reference ? getRoute(reference) : to;
  let blockAnchor = block ? getEntryAnchor(block) : '';
  let state = action === 'Modal' ? { modal: true } : undefined;
  let matchInternalLink = /^\//;

  let button = (
    <SwitchButtonAction action={action} type={type} iconName={icon} className={internalClassName}>
      {label}
    </SwitchButtonAction>
  );

  switch (true) {
    case Boolean(url?.match(matchInternalLink)):
    case Boolean(link):
      const to = link ? link : url;
      return (
        <StyledLink
          as={Link}
          to={to + blockAnchor}
          state={state}
          className={className}
          replace={replace}
          onClick={onClick}
        >
          {button}
        </StyledLink>
      );
    case Boolean(url):
      return (
        <StyledLink
          href={url}
          className={className}
          onClick={onClick}
          target={action === 'Target _blank' ? '_blank' : undefined}
        >
          {button}
        </StyledLink>
      );
    case Boolean(file):
      return (
        <StyledLink href={file?.file.url} className={className} onClick={onClick} target="_blank" rel="noreferrer">
          {button}
        </StyledLink>
      );
    default:
      console.log('Button missing:', { reference, block, url, label });
      return isDevEnvironment ? (
        <p>{`ButtonLink not fully defined, missing props: link:${link} link:${link}`}</p>
      ) : null;
  }
}

const StyledLink = styled.a`
  display: inline-block;
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
`;

interface SwitchButtonActionProps {
  children: ReactNode;
  action: LinkButtonActions;
  type: LinkButtonTypes;
  iconName?: string;
  className?: string;
}

/**
 * SwitchButtonAction
 * render out Primary|Secondary LinkButtonInternal based on type
 *
 */
function SwitchButtonAction({ children, action, type, iconName, className }: SwitchButtonActionProps) {
  switch (action) {
    case 'Modal':
    case 'Link':
    case 'Download':
    case 'Target _blank':
      return (
        <SwitchLinkButton type={type} iconName={iconName} className={className}>
          {children}
        </SwitchLinkButton>
      );
    case 'Play':
      return <>{children}</>;
    default:
      return isDevEnvironment ? <p>Unknown action ${action}</p> : null;
  }
}

interface SwitchLinkButtonProps {
  children: ReactNode;
  type: LinkButtonTypes;
  iconName?: string;
  className?: string;
}

/**
 * SwitchLinkButton
 * render out Primary|Secondary LinkButtonInternal based on type
 */
function SwitchLinkButton({ children, type = 'Primary', iconName, className }: SwitchLinkButtonProps) {
  switch (type) {
    case 'Secondary':
      return <SecondaryLinkButtonInternal className={className}>{children}</SecondaryLinkButtonInternal>;
    case 'Primary':
    default:
      return (
        <PrimaryLinkButtonInternal
          iconType={
            iconName && iconName in Object.keys(IconTypes)
              ? IconTypes[iconName.toLowerCase() as keyof typeof IconTypes]
              : undefined
          }
          className={className}
        >
          {children}
        </PrimaryLinkButtonInternal>
      );
  }
}

interface LinkButtonInternalProps {
  children: ReactNode;
  className?: string;
}

interface PrimaryLinkButtonInternalProps extends LinkButtonInternalProps {
  iconType?: IconType;
}

/**
 * PrimaryLinkButtonInternal
 * Render out a span that visually looks like a <PrimaryButton>
 */

function PrimaryLinkButtonInternal({ children, iconType: IconType, className = '' }: PrimaryLinkButtonInternalProps) {
  return (
    <StyledPrimaryLinkButton className={mergeClassNames(IconType ? 'icon' : '', className)}>
      {IconType && <span className="icon-container">{<IconType />}</span>}
      {children && <span className="text-container">{children}</span>}
    </StyledPrimaryLinkButton>
  );
}

/**
 * SecondaryLinkButtonInternal
 * Render out a span that visually looks like a <SecondaryButton>
 */
function SecondaryLinkButtonInternal({ children, className = '' }: LinkButtonInternalProps) {
  return (
    <StyledSecondaryLinkButton className={mergeClassNames('default', className)}>{children}</StyledSecondaryLinkButton>
  );
}

const StyledLinkButton = styled.span`
  display: inline-flex;
  align-items: center;
`;

const StyledPrimaryLinkButton = styled(StyledLinkButton)`
  ${navType}
  white-space: nowrap;
  height: 36px;
  transition: background-color 0.3s linear, fill 0.3s linear, color 0.3s linear, stroke 0.3s linear;
  border-radius: ${branding.primaryButton.borderRadius.small};
  padding: 0 18px;
  color: ${branding.primaryButton.colors.color};
  background-color: ${branding.primaryButton.colors.background};
  &:hover {
    color: ${branding.primaryButton.colors.hoverColor};
    background-color: ${branding.primaryButton.colors.hoverBackground};
  }
  ${mediaQueries.medium(css`
    height: 48px;
    padding: 0 27px;
    border-radius: ${branding.primaryButton.borderRadius.medium};
  `)}
  ${mediaQueries.large(css`
    height: 54px;
    border-radius: ${branding.primaryButton.borderRadius.large};
  `)}

  .icon {
    svg {
      transition: transform 250ms ease-in-out;
      width: auto;
      max-width: 24px;
      max-height: 24px;
    }
  }

  .icon-container {
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .icon .text-container {
    margin-left: 6px;
  }
`;

const StyledSecondaryLinkButton = styled(StyledLinkButton)`
  ${button}
  ${navType}
  height: 44px;
  padding: 0 22px;
  border-radius: ${branding.secondaryButton.borderRadius.small};
  border: solid currentColor 1px;
  background-color: transparent;
  ${mediaQueries.medium(css`
    height: 48px;
    padding: 0 27px;
    border-radius: ${branding.secondaryButton.borderRadius.medium};
  `)}
  ${mediaQueries.large(css`
    height: 54px;
    border-radius: ${branding.secondaryButton.borderRadius.large};
  `)}

  &.dark,
  &.default {
    color: ${branding.secondaryButton.colors.color};
    &[disabled] {
      color: ${branding.secondaryButton.colors.disabledColor};
    }
    &:hover {
      color: ${branding.secondaryButton.colors.hoverColor};
      background-color: ${branding.secondaryButton.colors.hoverBackground};
    }
  }

  &.light {
    color: ${branding.secondaryButton.colors.color};
    &[disabled] {
      color: ${branding.secondaryButton.colors.disabledColor};
    }
    &:hover {
      background-color: ${branding.secondaryButton.colors.hoverBackground};
    }
  }
`;
