import { NavDirectLink, WhitelabelBranding } from 'white-label/shared/types';
import {
  black,
  grey10,
  grey100,
  grey40,
  grey50,
  grey70AlphaDark,
  grey80,
  grey90,
  white,
} from '../../styles/baseColors';

import { userConfig } from './userConfig';

export const globalMenuBottomLinks: Array<NavDirectLink> = [
  { title: 'Terms', url: 'https://www.cisco.com/c/en/us/about/legal/terms-conditions.html', type: 'external' },
  { title: 'Privacy', url: 'https://www.cisco.com/c/en/us/about/legal/privacy.html', type: 'external' },
  { title: 'Cookies', url: 'https://www.cisco.com/c/en/us/about/legal/privacy.html#cookies', type: 'external' },
  { title: 'Trademarks', url: 'https://www.cisco.com/c/en/us/about/legal/trademarks.html', type: 'external' },
  { title: 'Contact Us', url: 'https://projectworkplace.cisco.com/get-in-touch', type: 'external' },
];

const fordBlue = `#00095B`;
const fordTwilight = '#00142E';

export const variables: WhitelabelBranding = {
  headerHeight: {
    mobile: `${92 + 8}px`, // header height + ford's top bar height
    tablet: `${92 + 8}px`, // header height + ford's top bar height
    desktop: `${92 + 8}px`, // header height + ford's top bar height
  },
  icon: 'static/images/favicon.svg',
  accentColor: fordBlue,
  hrGradient: `linear-gradient(to right, ${fordBlue}, ${fordBlue}, ${fordBlue})`,
  boxBorderRadius: '20px',
  innerBorderRadius: '10px',
  headerLinkBar: {
    visible: true,
    background: `radial-gradient(62.07% 62.07% at 24.9% 19.38%, #171B1F 0%, rgba(23, 27, 31, 0.9) 100%)`,
  },
  primaryButton: {
    colors: {
      color: white,
      background: fordBlue,
      hoverColor: white,
      hoverBackground: black,
      disabledColor: grey50,
      disabledBackground: grey40,
    },
    borderRadius: {
      small: '18px',
      medium: '24px',
      large: '26px',
    },
  },
  secondaryButton: {
    colors: {
      color: grey100,
      background: 'transparent',
      hoverColor: white,
      hoverBackground: grey70AlphaDark,
      disabledColor: grey40,
      disabledBackground: 'transparent',
    },
    borderRadius: {
      small: '26px',
      medium: '26px',
      large: '26px',
    },
  },
  contentBlock: {
    background: {
      light: grey10,
      dark: fordTwilight,
    },
  },
  cardGrid: {
    background: 'transparent',
    item: {
      prominent: {
        headerColor: white,
        color: grey50,
        background: `linear-gradient(270deg, ${grey90}, ${grey90});`,
      },
      default: {
        headerColor: black,
        color: grey50,
        background: `linear-gradient(270deg, ${grey10}, ${grey10});`,
      },
    },
  },
  homeDevicesGrid: {
    background: `linear-gradient(180deg,#171b1f,#737678)`,
    item: {
      headerColor: white,
      color: white,
      background: grey80,
    },
  },
  availablePaymentTypes: ['Placed Order', 'Quote'], // 'Monthly Payments'
  phrases: {
    buyButtonLabel: 'Add to Cart',
    invalidPrice: 'Pricing upon request.',
    disclaimerNonBinding:
      'Cisco Suggested Resale Price (CSRP) shown are a Target End Customer Price and are dependent on the specific offer model/configuration. CSRPs are provided for guidance and planning purposes only and may vary by country/region. This is not a binding offer from Cisco. Price does not include optional hardware accessories or Cisco Supported Services.',
    disclaimerAdditionalCost: 'Additional costs for support and installation services may apply.',
    disclaimerPricingRequest: 'Pricing upon request.',
  },
  globalMenuBottomLinks,
  formApiEndpoint: `https://s1485857328.t.eloqua.com/e/f2`,
  formSiteId: `1485857328`,
  formCampaignId: `projectworkplace`,
  elqFormNameContact: `contactsalesutmrouting`,
  elqFormNameQuote: `contactsalesutmrouting`,
  elqFormNameSubscription: 'Wbxdeviceforms',
  commonColors: {
    white,
    black,
  },
  siteMetadata: {
    title: 'Cisco Project Workplace',
    brand: 'Cisco',
    description:
      'Project Workplace is a conversation enabler around Cisco collaboration technologies. It is an information hub for reference on team collaboration, how to set up rooms and best practices for Cisco collaboration equipment.',
    defaultSeoImageUrl: '/images/default_seo.jpg',
    siteAndAuthor: '@cisco',
    canonicalBaseUrl: 'https://projectworkplace.cisco.com',
    homeLink: {
      title: 'Home',
      url: '/',
      type: 'internal',
    },
  },
  pathPrefix: '',
  assetPrefix: '',
  order: {
    usePaymentTypeDialog: false,
    summary: {
      usePDFButton: false,
      heading: 'Order Summary',
      button: 'Checkout',
    },
    submit: {
      heading: 'Submit Order',
      button: 'To payment',
    },
    timeline: ['Create order', 'Submit order', 'Receive quote & payment', 'Track shipment'],
  },
  userConfig,
};
