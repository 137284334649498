import { Context, useContext } from 'react'; // eslint-disable-line no-unused-vars

/**
 * React Context's createContext() is usyally inited with undefined
 * used with typescript it is also provided with a type where we need to have union with "undefined" to make the init with undefined type correct:
 * 
 * createContext<ContextType>(undefined);
 ```typescript
  type ContextType =
    | {
        setValue: React.Dispatch<React.SetStateAction<string>>;
        value: string;
      }
    | undefined;
  ```
 * When consuming this created context with useContext() we have to handle the context possibly being 'undefined' everywhere.
 * With this utility the consumption of the context can be consumed like `let {value, setValue} = useContext(ourcontext) without having to handle possible undefined:
 */
function useContextOnlyIfValidContext<Type>(context: Context<Type>) {
  const contextAccessor = useContext(context);
  if (!contextAccessor) {
    throw new Error(`useContext(useContextOnlyIfValidContext) must be used inside it's Provider`);
  }
  return contextAccessor as NonNullable<Type>;
}

export { useContextOnlyIfValidContext as useContext };
