import { css } from 'styled-components';
import { BuildTargetType, getBuildTarget } from '../white-label';
import { variables as pwp } from '../white-label/pwp/variables';
import { variables as webex } from '../white-label/webex/variables';
import { variables as wwt } from '../white-label/wwt/variables';
import { variables as ford } from '../white-label/ford/variables';
import { variables as onboarding } from '../white-label/onboarding/variables';
import { WhitelabelBranding } from 'white-label/shared/types';
import { blue10, grey10, grey100, grey70, grey80, grey90, white } from './baseColors';

export { grey100 };

const variables: Required<Record<BuildTargetType, WhitelabelBranding>> = { pwp, webex, wwt, ford, onboarding };
export default variables;

export const branding = variables[getBuildTarget()];

// misc
export const headerHeight = 92; //px
export const breadcrumbHeight = 74; //px
export const universalMargin = 8;

export const webexHeaderHeight = 97; //px
export const webexContactbarHeight = 33; //px
export const webexMobileHeaderHeight = 'calc(8.5333333333vw + (2 * 5.3333333333vw))'; //vw

export const backgroundColors = css`
  &.Transparent {
    background-color: transparent;
  }
  &.White {
    background-color: ${white};
    color: ${grey100};
  }
  &.Grey10 {
    background-color: ${grey10};
    color: ${grey100};
  }
  &.Grey70 {
    background-color: ${grey70};
    color: ${white};
  }
  &.Grey80 {
    background-color: ${grey80};
    color: ${white};
  }
  &.Grey90 {
    background-color: ${grey90};
    color: ${white};
  }
  &.Grey100 {
    background-color: ${grey100};
    color: ${white};
  }
  &.Blue10 {
    background-color: ${blue10};
    color: black;
  }
  &.Black {
    background-color: black;
    color: ${white};
  }
`;
