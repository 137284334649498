import React from 'react';
import { QuoteProvider } from './Quote/QuoteProvider';
import { OnRequestAnimFrameOnScroll } from './onRequestAnimFrameOnScroll';
import { CookieAcceptanceProvider } from './CookiesAcceptance';
import { NavigationDropdownStateProvider } from './NavigationDropdownState';
import { DebugProvider } from 'Debug';
import { BaseLayoutProvider } from './BaseLayoutState';
import { UserCountryProvider } from './WebexShopIntegration/UserCountryProvider';
import { UserProvider } from './User/UserProvider';

/**
 * @param {object} props
 * @param {React.ReactNode|React.ReactNodeArray} props.children
 */
export default function Store({ children }) {
  return (
    <DebugProvider>
      <NavigationDropdownStateProvider>
        <CookieAcceptanceProvider>
          <QuoteProvider>
            <UserProvider>
              <UserCountryProvider>
                <BaseLayoutProvider>
                  <OnRequestAnimFrameOnScroll>{children}</OnRequestAnimFrameOnScroll>
                </BaseLayoutProvider>
              </UserCountryProvider>
            </UserProvider>
          </QuoteProvider>
        </CookieAcceptanceProvider>
      </NavigationDropdownStateProvider>
    </DebugProvider>
  );
}
