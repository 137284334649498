import React from 'react';
import styled from 'styled-components';
import { black, grey10, white } from 'styles';
import MenuIcon from '../../assets/burger_44.svg';
import CloseIcon from '../../assets/close_44.svg';

type NavigationStatusIconProps = {
  isOpen: boolean;
  toggleNav: (action?: 'open' | 'close') => void;
  className?: string;
};

export default function NavigationStatusIcon({ isOpen, toggleNav }: NavigationStatusIconProps) {
  return (
    <NavigationToggle
      className={isOpen ? 'active' : ''}
      onClick={action => toggleNav()}
      aria-label="Toggle navigation menu"
    >
      <BaseMenuIcon as={MenuIcon} className={'menuIcon'} />
      <BaseMenuIcon as={CloseIcon} className={'closeIcon'} />
    </NavigationToggle>
  );
}

const transitionLength = 0.3;

const NavigationToggle = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-content: center;
  transition: background-color ${transitionLength}s ease-in-out, color ${transitionLength}s ease-in-out;
  background-color: ${grey10};
  height: 44px;
  width: 44px;
  border-radius: 22px;
  overflow: hidden;
  text-decoration: none !important;
  &.active {
    color: ${white};
    background-color: ${black};
    transition: background-color ${transitionLength}s ease-in-out ${transitionLength},
      color ${transitionLength}s ease-in-out ${transitionLength};
  }
`;

const BaseMenuIcon = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &.menuIcon {
    rect {
      opacity: 1;
      transition: opacity ${transitionLength}s ease-in-out ${transitionLength}s;
    }
  }
  &.closeIcon {
    rect {
      opacity: 0;
      transition: opacity ${transitionLength}s ease-in-out;
    }
  }

  ${NavigationToggle}.active & {
    &.closeIcon {
      rect {
        opacity: 1;
        transition: opacity ${transitionLength}s ease-in-out ${transitionLength}s;
      }
    }
    &.menuIcon {
      rect {
        opacity: 0;
        transition: opacity ${transitionLength}s ease-in-out;
      }
    }
  }
`;
