import React from 'react';
import { mergeClassNames } from '../../../../utils';
import Button from '..';
import styled from 'styled-components';
import { anchor, navType } from 'styles';

export function LinkStyleButton({
  children,
  clickHandler,
  title = '',
  className = '',
  tabIndex = undefined,
  style = undefined,
  disabled = false,
  type = undefined,
  startIcon = undefined,
  endIcon = undefined,
  appearance = appearances.default,
}) {
  return (
    <StyledButton
      disabled={disabled}
      title={title}
      tabIndex={tabIndex}
      clickHandler={clickHandler}
      className={mergeClassNames([className, appearance])}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      linkStyle={true}
    >
      {children}
    </StyledButton>
  );
}

const appearances = {
  default: 'default',
  light: 'light',
};

const StyledButton = styled(Button)`
  text-align: center;
  line-height: 24px;
  background-color: transparent;
  ${navType}
  span {
    ${anchor}
  }

  &.default {
    color: $grey100;
    &[disabled] {
      color: $grey40;
      &:hover {
        color: inherit;
      }
    }
  }

  &.light {
    color: $white;
    &[disabled] {
      color: $grey40;
      &:hover {
        color: inherit;
      }
    }
  }
`;
