/** Helper to iterate over a Record, and still keep the correct types of the Record (else it will be type ereased to more generic types like string etc.) */
export function iterator<Item>(item: Item) {
  const result: Array<{ key: keyof typeof item; value: Item[keyof typeof item] }> = [];

  let k: keyof Item;
  for (k in item) {
    const value = item[k];
    result.push({ value, key: k });
  }
  return result;
}
