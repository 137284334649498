import { Quote, QuoteStateAction } from '../typedefs'; // eslint-disable-line no-unused-vars
import React, { useEffect, useRef } from 'react'; // eslint-disable-line no-unused-vars
import { useDebug } from 'Debug';

/**
 * @param {string} debugLabel
 * @param {[Quote, React.Dispatch<QuoteStateAction>]} state
 * @returns {[Quote, React.Dispatch<QuoteStateAction>]} React useReducer equivalent w/ built in storing to local storage
 */
export function useQuoteStateDebugLogger(debugLabel, [state, dispatch]) {
  let debug = useDebug();
  let actionRef = useRef([]);
  let logAction = createQuoteActionDebugLogger(debugLabel);
  let logState = createQuoteStateDebugLogger(debugLabel);

  let newDispatch = action => {
    actionRef.current.push(action);
    if (debug) logAction(action, state);
    dispatch(action);
  };

  useEffect(() => {
    const { current: actions } = actionRef;
    if (debug) logState(actions, state);
    actionRef.current = [];
  }, [debug, logState, state]);

  return [state, newDispatch];
}

export function createQuoteActionDebugLogger(debugLabel) {
  return function quoteActionDebugLogger(action, state) {
    if (action) {
      let debugStyles = ['color: greenyellow', 'color: unset', 'color: white'];
      let item = action.payload?.item;
      let itemTitles = Array.isArray(item)
        ? item?.reduce((titles, innerItem, index) => titles.concat(`${index < 1 ? '' : ', '}${innerItem.title}`), '')
        : item?.title || item?.product?.title;
      console.group(
        `${debugLabel ? `((${debugLabel})) ` : ``}Dispatch %c${action.type}%c w/ %c${itemTitles}`,
        ...debugStyles
      );
      console.log(`Action type: "%c${action.type}%c"`, ...['color: plum', debugStyles[1]]);
      console.log('Action payload:', action.payload);
      console.log('Prev state:', state);
      console.groupEnd();
    }
  };
}

export function createQuoteStateDebugLogger(debugLabel) {
  return function quoteStateDebugLogger(actions, state) {
    if (actions.length) {
      let debugStyles = ['color: pink', 'color: unset'];
      let stateTitle = `New state (w/ actions%c${concatActionNames(actions)}%c)`;
      console.groupCollapsed(`${debugLabel ? `((${debugLabel})) ` : ``}${stateTitle}`, ...debugStyles);
      console.log('Current state:');
      console.table(
        state.map(inQuoteItem => {
          if ('bundle' in inQuoteItem) {
            let {
              bundle: item,
              quantityTree: {
                main: { quantity },
              },
              ...rest
            } = inQuoteItem;
            return { ...rest, item, quantity };
          }
          if ('product' in inQuoteItem) {
            let { product: item, ...rest } = inQuoteItem;
            return { ...rest, item };
          }
          return inQuoteItem;
        })
      );
      console.groupEnd();
    }
  };
}

function concatActionNames(actions) {
  actions.reduce((prev, action) => prev.concat(` ${action.type}`), '');
}
