import { hexToRgbString } from './utils';

export const black = `#181818`;
export const grey100 = `#171b1f`;
export const grey90 = `#191919`;
export const grey85 = '#212121';
export const grey80 = `#4b4a4b`; // used on Experience Modal with MUX video bg + ???
export const grey70 = `#535759`;
export const grey60 = `#737678`;
export const grey50 = `#818181`; //! '50' not necessarily correct
export const grey40 = `#b4b6b8`;
export const grey30 = `#929596`;
export const grey20 = `#d8d8d8`;
export const grey10 = `#f2f4f5`;
export const white = `#ffffff`;
export const blue10 = `#ecfafd`;
export const blue40 = `#1dc4f2`;
export const blue50 = `#00a0d1`;
export const blue70 = `#0a5e7d`;
export const green50 = `#078855`; //! '50' not necessarily correct
export const green60 = `#05935b`; // From figma, Complient Type on Black
export const green70 = `#074825`; //! '70' not necessarily correct
export const red50 = '#E51414';

export const whiteAlpha = `rgba(${hexToRgbString(white)}, 0.9)`;
export const grey100AlphaDark = `rgba(${hexToRgbString(grey100)}, 0.9)`;
export const grey70AlphaDark = `rgba(${hexToRgbString(grey70)}, 0.9)`;
export const grey100AlphaMedium = `rgba(${hexToRgbString(grey100)}, 0.3)`;
export const grey100AlphaLight = `rgba(${hexToRgbString(grey100)}, 0.1)`;
export const grey70AlphaLight = `rgba(${hexToRgbString(grey70)}, 0.2)`;
export const grey10Alpha = `rgba(${hexToRgbString(grey10)}, 0.9)`;
