import MuxPlayer, { MuxPlayerProps } from 'components/generic/MuxPlayer';
import { YoutubePlayer } from 'components/YoutubePlayer';
import { Column, Row } from 'components/generic/Grid';
import styled, { css } from 'styled-components';
import { ContentfulVideo, isContentfulVideoMux, isContentfulVideoYoutube, RichText as RichTextType } from 'types';
import React from 'react';
import RichText from 'components/generic/RichText/RichText';
import { branding, h4Heading } from 'styles';
import { grey40, grey70, grey60, white, black, universalMargin, mediaQueries } from 'styles';
import { YoutubePlayerProps } from 'components/YoutubePlayer/YoutubePlayer';
import { ContentBlock } from 'components/generic/ContentBlock';

type VideoProps = {
  video: ContentfulVideo;
  fullscreen?: boolean;
  darkMode?: boolean;
  increasedContrast?: boolean;
};

export function Video(props: VideoProps) {
  if (props.fullscreen) {
    return (
      <RelativeVideo>
        <SelectVideoPlayer video={props.video} posterImageMaxWidth={2880} />
        {props.video.heading || props.video.body ? (
          <AbsoluteText>
            <OverlayText>
              <ContentBlock>
                <TextContainer>
                  <TextSection
                    heading={props.video.heading}
                    description={props.video.body}
                    darkMode={props.darkMode}
                    increasedContrast={props.increasedContrast}
                  />
                </TextContainer>
              </ContentBlock>
            </OverlayText>
          </AbsoluteText>
        ) : null}
      </RelativeVideo>
    );
  }

  return (
    <Row>
      <Column desktop={'10/12'} desktopOffset={'2/12'}>
        <BorderRadius>
          <SelectVideoPlayer video={props.video} />
        </BorderRadius>
      </Column>
      <Column>
        {props.video.heading || props.video.body ? (
          <TextContainer>
            <TextSection
              heading={props.video.heading}
              description={props.video.body}
              darkMode={props.darkMode}
              increasedContrast={props.increasedContrast}
            />
          </TextContainer>
        ) : null}
      </Column>
    </Row>
  );
}

type SelectVideoPlayerProps = {
  video: ContentfulVideo;
} & (Omit<MuxPlayerProps, 'video'> | Omit<YoutubePlayerProps, 'video'>);

export function SelectVideoPlayer(props: SelectVideoPlayerProps) {
  if (isContentfulVideoYoutube(props.video)) return <YoutubePlayer {...props} video={props.video} />;
  if (isContentfulVideoMux(props.video)) return <MuxPlayer {...props} video={props.video} roundedCorners />;
  return <></>;
}

type TextSectionProps = {
  heading?: string;
  description?: RichTextType;
  darkMode?: boolean;
  increasedContrast?: boolean;
};

function TextSection(props: TextSectionProps) {
  return (
    <Row>
      <Column desktop={'4/12'} desktopOffset={'2/12'}>
        <Heading darkMode={props.darkMode}>{props.heading}</Heading>
      </Column>
      <Column desktop={'5/12'}>
        <StyledRichText darkMode={props.darkMode} increasedContrast={props.increasedContrast}>
          {props.description}
        </StyledRichText>
      </Column>
    </Row>
  );
}

const StyledRichText = styled(RichText)<{ darkMode?: boolean; increasedContrast?: boolean }>`
  color: ${props => (props.darkMode ? grey40 : props.increasedContrast ? grey70 : grey60)};
  p:first-child {
    padding-top: 0;
  }
`;

const BorderRadius = styled.div`
  border-radius: ${branding.boxBorderRadius};
  overflow: hidden;
`;

const TextContainer = styled.div`
  padding: ${universalMargin * 3}px 0 0;
  ${mediaQueries.large(css`
    padding: ${universalMargin * 7}px 0 0;
  `)}
`;

const Heading = styled.h3<{ darkMode?: boolean }>`
  ${h4Heading}
  color: ${props => (props.darkMode ? white : black)};
  line-height: 1em;
  padding-bottom: ${universalMargin * 2}px;
  ${mediaQueries.large(css`
    padding: 0;
  `)}
`;

const RelativeVideo = styled.div`
  ${mediaQueries.large(css`
    position: relative;
  `)}
`;

const AbsoluteText = styled.div`
  ${mediaQueries.large(css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  `)}
`;

const OverlayText = styled.div`
  ${mediaQueries.large(css`
    background-color: rgb(24, 24, 24, 0.8);
    padding: 0 0 ${universalMargin * 4}px 0;
  `)}
`;
