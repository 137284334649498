import { maxContentWidth } from 'styles/breakpoints';
import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { gridPaddings } from 'styles/grid';
import styled, { css } from 'styled-components';
import { universalMargin, mediaQueries } from 'styles';
import { PwpImage } from 'types';
import Image from 'components/generic/Image';

/** TODO: Share sizes with Box component in a nice way? Maybe with more components */
type SupportedSizes = 'small' | 'medium' | 'large';
const mapSupportedSizes: Record<SupportedSizes, number> = { small: 4, medium: 8, large: 16 };

export type ContentBlockProps = {
  id?: string;
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode | Array<React.ReactNode>;
  backgroundColor?: string;
  backgroundImage?: PwpImage;
  mobilePaddingY?: SupportedSizes;
  tabletPaddingY?: SupportedSizes;
  desktopPaddingY?: SupportedSizes;
  shouldMuteBottomPadding?: boolean;
  increasedContrast?: boolean;
  darkMode?: boolean;
};

export function ContentBlock(props: ContentBlockProps) {
  if (props.backgroundColor || props.backgroundImage) {
    return (
      <BackgroundContainer as={props.as}>
        {props.backgroundImage ? (
          <Background
            as={Image}
            image={props.backgroundImage?.image.gatsbyImageData}
            alt={props.backgroundImage?.image.description}
            backgroundColor={props.backgroundColor}
            objectFit="cover"
          />
        ) : (
          <Background backgroundColor={props.backgroundColor} />
        )}
        <Inner
          mobilePaddingY={props.mobilePaddingY}
          tabletPaddingY={props.tabletPaddingY}
          desktopPaddingY={props.desktopPaddingY}
          muteBottomPadding={props.shouldMuteBottomPadding}
          id={props.id || ''}
        >
          {props.children}
        </Inner>
      </BackgroundContainer>
    );
  }

  return (
    <Inner
      as={props.as}
      mobilePaddingY={props.mobilePaddingY}
      tabletPaddingY={props.tabletPaddingY}
      desktopPaddingY={props.desktopPaddingY}
      muteBottomPadding={props.shouldMuteBottomPadding}
      id={props.id || ''}
    >
      {props.children}
    </Inner>
  );
}

const BackgroundContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const Background = styled.div<{ backgroundColor?: string } | { backgroundColor?: string; image: IGatsbyImageData }>`
  z-index: -1;
  ::after {
    content: ' ';
  }
  &,
  &::after {
    position: absolute;
    inset: 0;
  }
  ${props =>
    props.backgroundColor &&
    css`
      ::after {
        background: ${props.backgroundColor};
        opacity: ${'image' in props && props.image ? 0.65 : 1};
      }
    `}
`;

export const Inner = styled.div<{
  id: string; //required!
  mobilePaddingY?: SupportedSizes;
  tabletPaddingY?: SupportedSizes;
  desktopPaddingY?: SupportedSizes;
  muteBottomPadding?: boolean;
}>`
  ${gridPaddings};

  ${props =>
    props.mobilePaddingY &&
    css`
      padding-top: ${universalMargin * mapSupportedSizes[props.mobilePaddingY]}px;
      ${!props.muteBottomPadding && `padding-bottom: ${universalMargin * mapSupportedSizes[props.mobilePaddingY]}px`};
    `};

  ${props =>
    props.tabletPaddingY &&
    mediaQueries.small(css`
      padding-top: ${universalMargin * mapSupportedSizes[props.tabletPaddingY]}px;
      ${!props.muteBottomPadding && `padding-bottom: ${universalMargin * mapSupportedSizes[props.tabletPaddingY]}px`};
    `)}

  ${props =>
    props.desktopPaddingY &&
    mediaQueries.medium(css`
      padding-top: ${universalMargin * mapSupportedSizes[props.desktopPaddingY]}px;
      ${!props.muteBottomPadding && `padding-bottom: ${universalMargin * mapSupportedSizes[props.desktopPaddingY]}px`};
    `)}
   
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${maxContentWidth}px;
`;
