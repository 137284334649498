import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Navbar from './Navbar';
import UserArea, { UserAreaProps } from './UserArea';
import Section from '../../../components/generic/Section';
import { ContentfulNavigation } from 'types';
import { mediaQueries, universalMargin, branding } from 'styles';
import styled, { css } from 'styled-components';

interface HeaderProps {
  path: string;
  globalNavVisibility: UserAreaProps['globalNavVisibility'];
  leftLogo: React.ReactNode;
  rightLogo: React.ReactNode;
  mainNavigation?: ContentfulNavigation;
  showWebexLinkBar: boolean;
  gradient?: boolean;
  showLanguageSelector?: boolean;
  showSubscribeButton: boolean;
  showSearchButton: boolean;
  showCartButton: boolean;
  showLoginButton: boolean;
  showBurgerMenuButton: boolean;
}

export default function Header({ path, globalNavVisibility, ...props }: HeaderProps) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);

  return (
    <>
      {props.gradient && <Hr />}
      <Section size="max" innerPadding>
        <NavWrapper>
          <Nav>
            <LogoLink to={siteMetadata.homeLink.url}>
              {props.leftLogo}
              {props.rightLogo}
            </LogoLink>
            {props.mainNavigation && <Navbar path={path} mainNavigation={props.mainNavigation} />}
          </Nav>
          <UserArea
            globalNavVisibility={globalNavVisibility}
            showLanguageSelector={props.showLanguageSelector || false}
            showSubscribeButton={props.showSubscribeButton}
            showSearchButton={props.showSearchButton}
            showCartButton={props.showCartButton}
            showLoginButton={props.showLoginButton}
            showBurgerMenuButton={props.showBurgerMenuButton}
          />
        </NavWrapper>
      </Section>
    </>
  );
}

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${universalMargin * 3}px 0;
  justify-content: space-between;
  ${mediaQueries.large(css`
    padding: ${universalMargin * 3}px 0;
  `)}
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Hr = styled.hr`
  background: ${branding.hrGradient};
  height: ${universalMargin}px;
`;

const LogoLink = styled(Link)`
  display: contents;
  text-decoration: none;
  :hover {
    color: inherit;
  }
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        homeLink {
          url
        }
      }
    }
  }
`;
