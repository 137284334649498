import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { NavDropdownLink, ContentfulNavigation, NavLink } from 'white-label/shared/types';
import { useDropdownState } from '../../../../context/NavigationDropdownState';
import Icon, { IconTypes } from '../../../../components/generic/Icon';
import styled, { css } from 'styled-components';
import { branding, universalMargin } from '../../../../styles/variables';
import { mediaQueries } from '../../../../styles/breakpoints';
import { anchor, headerNavType, headerNavTypeEmphasized } from 'styles/typography';
import { isContentfulNavigationSubNav } from 'types';
import getRoute from 'routes/getRoute';

interface NavbarProps {
  path: string;
  mainNavigation: ContentfulNavigation;
}

export default function Navbar({ path, ...props }: NavbarProps) {
  const location = useLocation();
  const pathArr = location.pathname.split('/').filter(x => x !== '');

  return (
    <NavbarWrapper>
      <List>
        {isContentfulNavigationSubNav(props.mainNavigation)
          ? props.mainNavigation?.subNavigation?.map((node: ContentfulNavigation) => {
              const urlFromString = 'url' in node && node.url !== null ? node.url : null;
              const urlFromReference = 'reference' in node && node.reference !== null ? getRoute(node.reference) : null;
              const targetUrl = urlFromString || urlFromReference;
              const targetType = targetUrl?.match(/^\//) ? 'external' : 'internal';
              return (
                <ListItem key={node.label}>
                  {isContentfulNavigationSubNav(node) ? (
                    <NavItem
                      navItem={{
                        title: node.label,
                        subNavigation: node.subNavigation,
                        type: 'dropdown',
                        dropdownType: 'menu',
                        standaloneInMenu: false,
                      }}
                      pathArr={pathArr}
                    />
                  ) : (
                    <NavItem
                      navItem={{
                        title: node.label,
                        url: targetUrl,
                        type: targetType,
                        standaloneInMenu: false,
                      }}
                      pathArr={pathArr}
                    />
                  )}
                </ListItem>
              );
            })
          : null}
      </List>
    </NavbarWrapper>
  );
}

interface NavItemProps {
  navItem: NavLink;
  pathArr: string[];
}

function NavItem({ navItem, pathArr }: NavItemProps) {
  switch (navItem.type) {
    case 'internal':
      return (
        <Link to={navItem.url} partiallyActive activeClassName="activeLink">
          {navItem.title}
        </Link>
      );

    case 'dropdown':
      return <DropdownNavItem navItem={navItem} />;

    default:
      return <a href={navItem.url}>{navItem.title}</a>;
  }
}

type DropdownNavItemProps = {
  navItem: NavDropdownLink;
};

function DropdownNavItem(props: DropdownNavItemProps) {
  const { dropdownState, dispatchDropdownState } = useDropdownState();
  const isClosed = dropdownState.dropdown === 'close';
  const isMenuType = !isClosed && props.navItem.dropdownType === dropdownState.menuType;

  let buttonRef = useRef<HTMLButtonElement>(null);

  // to prevent both the useClickOutside and button toggle here compete on toggling the dropdown we need to use stopPropagation.
  // that only works with native DOM events, not react synthetic events - don't use button's onClick here!
  useEffect(() => {
    function onDropdown(event: MouseEvent) {
      event.preventDefault();
      event.stopPropagation();
      if (isClosed) {
        dispatchDropdownState({
          type: 'open',
          menuType: props.navItem.dropdownType,
          subNavigation: props.navItem.subNavigation,
        });
      } else {
        dispatchDropdownState({ type: 'close' });
      }
    }

    let { current: buttonElem } = buttonRef;
    buttonElem?.addEventListener('click', onDropdown);
    return () => buttonElem?.removeEventListener('click', onDropdown);
  });

  return (
    <button ref={buttonRef} className={isMenuType && !isClosed ? 'activeLink' : ''}>
      {props.navItem.title} <ExpandedIndicator isOpen={isMenuType && !isClosed} IconType={IconTypes.down} />
    </button>
  );
}

const ExpandedIndicator = styled(Icon)<{ isOpen: boolean }>`
  padding-left: ${universalMargin / 2}px;
  & svg {
    transition: transform 300ms ease-in-out;
    ${props =>
      props.isOpen &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const NavbarWrapper = styled.nav`
  display: none;
  margin-top: auto;
  padding-left: ${universalMargin * 4}px;

  ${mediaQueries.large(css`
    display: initial;
  `)}

  a,
  button {
    ${anchor}
    ${headerNavType}
    text-decoration: none;
    transition: color 200ms ease-in-out;
    white-space: nowrap;
  }

  button {
    background-color: transparent;
  }

  a:hover,
  button:hover {
    color: ${branding.accentColor};
    text-decoration: inherit;
  }

  a:focus,
  button:focus {
    color: ${branding.accentColor};
  }

  .activeLink {
    ${headerNavTypeEmphasized}
  }
`;

const List = styled.ul`
  margin: 0;
`;

const ListItem = styled.li`
  margin: 0 ${universalMargin * 2}px;
  display: inline;
  white-space: nowrap;
`;
