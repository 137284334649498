/*eslint-disable no-unused-vars*/
import React from 'react';

/**
 * @template StateType
 * @template ActionType
 * @param {[StateType, React.Dispatch<ActionType>]} state
 * @param {((arg: ActionType, state: StateType) => ActionType)[]} middlewares
 * @returns {[StateType, React.Dispatch<ActionType>]} React useReducer equivalent w/ built in storing to local storage
 */
export function useReducerMiddleware([state, dispatch], middlewares = []) {
  /** @param {ActionType} action */
  function dispatchWrapper(action) {
    let reducedAction = middlewares.reduce((modifiedAction, middleware) => {
      return middleware(modifiedAction, state);
    }, action);
    dispatch(reducedAction ? reducedAction : interuptAction(action));
  }

  /** @constant @type {[StateType, React.Dispatch<ActionType>]} */
  return Object.seal([state, dispatchWrapper]);
}

/**
 * a way to alter an action and tag it so that we can mark an action as interrupted so we can interupt the execution of the dispatche
 * see ./reducer.js's 'interuppted' on how we handle an interrupted action
 *
 * @template ActionType
 * @param {ActionType & {type: string}} action
 * @returns {ActionType & {type: string, wasType: string}}
 */
export function interuptAction(action) {
  return { ...action, wasType: action.type, type: 'interrupted' };
}
