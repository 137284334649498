import React, { useState, useEffect, Fragment } from 'react';
import ArrowDownIcon from './assets/arrow-down_16.svg';
import { SelectOption } from '../../../../types';
import { InputLabels, InputLabelsProps } from '../InputLabels';
import { FieldOutput } from 'types';
import styled from 'styled-components';
import { grey40, grey50, grey20, red50 } from 'styles';
import { CountrySelectorWrapper } from 'context/WebexShopIntegration/CountrySelector';

type Height = 'default' | 'tall';
type SelectFieldProps = {
  withLabel?: InputLabelsProps;
} & SelectFieldPropsWithoutLabel;

export function SelectField(props: SelectFieldProps) {
  if (props.withLabel) {
    return (
      <InputLabels
        {...props.withLabel}
        required={props.withLabel?.required || props.required}
        invalid={props.withLabel?.invalid || props.invalid}
        htmlFor={props.withLabel?.htmlFor || props.id}
        id={props.withLabel?.id || `_${props.id}`}
      >
        <SelectFieldWithoutLabel {...props} />
      </InputLabels>
    );
  }

  return <SelectFieldWithoutLabel {...props} />;
}

interface SelectFieldPropsWithoutLabel {
  id: string;
  name: string;
  value?: string;
  options: ReadonlyArray<SelectOption>;
  onChange: (output: FieldOutput) => void;
  required?: boolean;
  invalid?: boolean;
  height?: Height;
}

function SelectFieldWithoutLabel(props: SelectFieldPropsWithoutLabel) {
  const defaultValue = props.value || props.options[0]?.value;

  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(
    props.options.find(o => o.value === defaultValue)
  );

  function handleChange(element: React.ChangeEvent<HTMLSelectElement>) {
    const option = props.options.find(o => o.value === element.target.value);
    setSelectedOption(option);

    if (option)
      props.onChange({
        value: option.value,
        name: props.name,
        id: props.id,
        required: Boolean(props.required),
      });
  }

  useEffect(() => {
    if (!props.value) return;
    const option = props.options.find(o => o.value === props.value);
    setSelectedOption(option);
  }, [props.options, props.value]);

  /**
   * TODO: A bit fragile, since the displayValue can change, should be passed in as a separate type
   */
  const isDivider = (option: SelectOption) => option.displayValue === '--------------';

  return (
    <SelectFieldWrapper>
      <Select
        // className={props.invalid ? shared.invalid : ''}
        highlighted={!props.invalid && props.required}
        id={props.id}
        name={props.name}
        onChange={handleChange}
        required={props.required}
        value={selectedOption?.value}
        height={props.height || 'default'}
      >
        {props.options.map(option => (
          <Fragment key={`${option.displayValue}-${option.value}`}>
            {isDivider(option) ? (
              <option disabled>───────────</option>
            ) : (
              <option value={option.value} disabled={option.disabled}>
                {option.displayValue}
              </option>
            )}
          </Fragment>
        ))}
      </Select>

      <InlineIcon />
    </SelectFieldWrapper>
  );
}

const SelectFieldWrapper = styled.div`
  position: relative;
`;

const Select = styled.select<{ height: Height; disabled?: boolean; highlighted?: boolean, invalid?: boolean; }>`
  padding-right: 34px;
  ${props =>
    props.height === 'tall'
      ? `
          height: 44px;
          border-radius: 22px;
        `
      : null}
  ${props => props.disabled && `color: ${grey40};`}
  ${props => props.highlighted && `border: solid 1px ${grey50} !important;`}
  ${props => !props.highlighted && `border: solid 1px ${grey20} !important;`}
  ${props => props.invalid && `border: solid 1px ${red50} !important;`}

  // component specific style for country selector used in footer and GlobalNavigation
  ${CountrySelectorWrapper} & {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
`;

const InlineIcon = styled(ArrowDownIcon)`
  position: absolute;
  color: ${grey40};
  height: 100%;
  display: flex;
  top: 0;
  right: 14px;
  pointer-events: none;
`;
