// eslint-disable-next-line no-restricted-imports
import React, { Suspense as ReactSuspense, SuspenseProps } from 'react';
import { isOnServer } from './isOnServer';
import { useIsFirstRender } from './useIsFirstRender';

function IsomorphicSuspense(props: SuspenseProps): JSX.Element {
  const firstRender = useIsFirstRender();
  const isReadyToLoad = !firstRender && !isOnServer && Boolean(props.children);
  if (!isReadyToLoad) return props.fallback as JSX.Element;
  return <ReactSuspense fallback={props.fallback}>{props.children}</ReactSuspense>;
}

export { IsomorphicSuspense as Suspense };
