import Layout, { useGlobalNavToggle } from '../shared/Layout';
import styled from 'styled-components';
import CiscoLogo from '../../assets/cisco_logo.svg';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BrandedLayoutProps } from 'white-label/shared/types';
import { ContentfulNavigation } from 'types';
import { branding, mediaQueries, universalMargin } from 'styles';
import Header from 'white-label/shared/Header';
import Footer, { FooterData } from 'white-label/shared/Footer';
import getRoute from 'routes/getRoute';

export default function PwpLayout(props: BrandedLayoutProps) {
  const globalNavVisibility = useGlobalNavToggle();
  const data: FooterData & { mainNavigationData: ContentfulNavigation } = useStaticQuery(query);
  const {
    mainNavigationData,
    productCategories,
    workspaceCategories,
    site: { siteMetadata },
  } = data;
  const logo = <StyledCiscoLogo />;

  const text = (
    <Title>
      Project <br />
      Workplace
    </Title>
  );

  return (
    <Layout
      children={props.children}
      path={props.path}
      location={props.location}
      showChatBubble={true}
      showWebexLayout={false}
      mainNavigation={mainNavigationData}
      globalNavVisibility={globalNavVisibility}
      header={
        <Header
          path={props.path || ''}
          globalNavVisibility={globalNavVisibility}
          leftLogo={logo}
          rightLogo={text}
          mainNavigation={mainNavigationData}
          gradient={false}
          showLanguageSelector={true}
          showWebexLinkBar={branding.headerLinkBar.visible}
          showCartButton={true}
          showSearchButton={true}
          showSubscribeButton={true}
          showLoginButton={true}
        />
      }
      footer={
        <Footer
          showLanguageSelector={false}
          showFooterWorkspaces={false}
          siteMetadata={siteMetadata}
          menu={[
            {
              heading: { label: 'Workspaces', url: '/workspaces' },
              items: workspaceCategories?.sections.map(workspaceCategory => {
                return { label: workspaceCategory.heading, url: getRoute(workspaceCategory) };
              }),
            },

            {
              heading: { label: 'Devices', url: '/devices' },
              items: productCategories?.sections.map(productCategory => {
                return {
                  label: productCategory.shortHeading || productCategory.heading,
                  url: getRoute(productCategory),
                };
              }),
            },
            undefined,
            {
              heading: { label: 'Company', url: '/' },
              items: siteMetadata.globalMenuBottomLinks.map(({ title, url }) => {
                return {
                  label: title,
                  url: url,
                };
              }),
            },
          ]}
        />
      }
    />
  );
}

const StyledCiscoLogo = styled(CiscoLogo)`
  color: #808080;
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  flex-grow: 0;
  padding-left: ${universalMargin * 2}px;
  ${mediaQueries.large(`padding-left: ${universalMargin * 3}px`)}
`;

const query = graphql`
  query {
    mainNavigationData: contentfulNavigation(contentful_id: { eq: "3wsSwaPhh4KI4n8krkFwTJ" }) {
      ...Navigation
    }
    # productCategories: contentfulPage(contentful_id: { eq: "6HupNQqKbleHvhZPJDHKnW" }) {
    productCategories: contentfulPage(contentful_id: { eq: "3kYmlYlWQmw5UrGiXU5YI3" }) {
      heading
      sections {
        __typename
        ... on ContentfulProductCategory {
          shortHeading
          heading
          slug
        }
      }
    }
    workspaceCategories: contentfulPage(contentful_id: { eq: "1Vk29Jl6rTsfvGlHLh9mHW" }) {
      heading
      sections {
        __typename
        ... on ContentfulWorkspaceCategory {
          heading
          slug
        }
      }
    }
    site {
      siteMetadata {
        homeLink {
          url
        }
        contactPage {
          url
        }
        globalMenuBottomLinks {
          title
          url
        }
      }
    }
  }
`;
