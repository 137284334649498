import React from 'react';
import { isCookieWarningEnabled, useIsFirstRender } from 'utils';
import { useCookieAcceptance } from '../../context/CookiesAcceptance';
import CookieDialog from './CookieDialog';

export default function CookieAcceptanceView() {
  let [cookiesAccepted] = useCookieAcceptance();
  let firstRender = useIsFirstRender();
  let displayCookieDialog = !firstRender && !cookiesAccepted.necessary;

  return (displayCookieDialog && isCookieWarningEnabled && <CookieDialog />) || null;
}
