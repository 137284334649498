import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { smallBody } from 'styles';

interface LabelProps {
  children?: ReactNode;
  htmlFor?: string;
  required?: boolean;
}

export default function Label({ children, htmlFor, required }: LabelProps) {
  return (
    <StyledLabel htmlFor={htmlFor} required={required}>
      {children}
    </StyledLabel>
  );
}

const StyledLabel = styled.label<{ required?: boolean }>`
  ${smallBody}
  display: block;
  padding-left: 14px;
  margin-bottom: 5px;
  ${props => props.required && `font-weight: 500;`}
`;
