import React, { useEffect, useCallback, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useClickOutside, mergeClassNames, useLockBodyScroll, isOnServer } from 'utils';

/**
 *
 * ! The Modal component's useClickOutside will be triggered on wrapped elems
 * if they exist in a <Modal> with portal wrapped within another <Modal> since
 * the check if the useClickOutside checks on any click if the element being
 * clicked is an ancestor of the element we are checking for clicks outside.
 * This check follows the DOM tree and not the React virtual DOM and with a
 * modal placed in a portal elements that is ancestors of an element in the
 * virtual DOM doesn't need to ancestors of the corresponding element in the
 * browser's DOM.
 */

interface ModalProps {
  children: ReactNode;
  closeCallback?: (event: unknown) => void;
  preventClickOutside?: boolean;
  fixed?: boolean;
  full?: boolean;
  portal?: boolean;
  lockBodyScroll?: boolean;
  className?: string;
}

export default function Modal({
  children,
  closeCallback = event => void event,
  preventClickOutside = false,
  fixed = false,
  full = false,
  portal = false,
  lockBodyScroll = false,
  className = '',
}: ModalProps) {
  const stopPropagationCallback = useCallback(
    event => {
      event.stopPropagation();
      closeCallback(event);
    },
    [closeCallback]
  );
  const clickOutsideRef = useClickOutside<HTMLDivElement>(stopPropagationCallback);
  const [lockScroll, unlockScroll] = useLockBodyScroll();

  useEffect(() => {
    if (lockBodyScroll) {
      lockScroll();
      return () => unlockScroll();
    }
  }, [lockBodyScroll, lockScroll, unlockScroll]);

  const content = (
    <ModalRoot
      ref={clickOutsideRef}
      className={mergeClassNames([
        fixed ? 'fixed' : '',
        full ? 'full' : '',
        preventClickOutside ? 'preventClickOutside' : '',
        className,
      ])}
    >
      {children}
    </ModalRoot>
  );

  if (portal && !isOnServer) {
    const portalElem = !isOnServer && document.getElementById('modal-root');
    if (!portalElem) {
      if (process.env.NODE_ENV === 'development') {
        console.warn(
          `<Modal portal={true}> wasn't able to find this App's "modal-root" portal container.\n This is as expected on first render, but if this message is repeated and you expect to render the Modal check that you are within the <Layout>-component or that a portal container is defined somewhere (like <div id="modal-root"/>)`
        );
      }
      return null;
    }
    return createPortal(content, portalElem);
  }

  return content;
}

const ModalRoot = styled.div`
  pointer-events: none;
  > * {
    pointer-events: auto;
  }

  &.preventClickOutside {
    pointer-events: revert;
  }

  &.fixed {
    position: fixed;
    top: 0;
    height: 100%;
  }

  &.full {
    top: 0;
    height: 100vh;
    width: 100vw;
  }
`;
