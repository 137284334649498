import React from 'react'; // eslint-disable-line no-unused-vars

/**
 * @template StateType
 * @template ActionType
 * @param {[StateType, React.Dispatch<ActionType>]} state
 * @param {((arg: ActionType) => void)[]} sideeffects
 * @returns {[StateType, React.Dispatch<ActionType>]} React useReducer equivalent w/ built in storing to local storage
 */
export function useActionSideeffects(state, sideeffects = []) {
  let [wrappedState, setState] = state;

  /** @type React.Dispatch<ActionType> */
  const setStateWrapper = arg => {
    sideeffects.forEach(callback => {
      callback(arg);
    });
    setState(arg);
  };
  /** @constant @type {[StateType, React.Dispatch<ActionType>]} */
  return Object.seal([wrappedState, setStateWrapper]);
}
