import React from 'react';
import styled from 'styled-components';
import { navType } from 'styles';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';
import ActivityIndicator from '../../generic/ActivityIndicator';

interface ButtonProps {
  children: React.ReactNode | React.ReactNodeArray;
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  keyHandler?: React.KeyboardEventHandler<HTMLButtonElement>;
  tabIndex?: number;
  disabled?: boolean;
  className?: string;
  style?: object;
  type?: 'button' | 'submit' | 'reset';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  buttonStyle?: boolean;
  linkStyle?: boolean;
  showActivityIndicator?: boolean;
}

export default function Button({
  clickHandler,
  title,
  keyHandler,
  tabIndex,
  children,
  disabled,
  className,
  style,
  type,
  startIcon,
  endIcon,
  buttonStyle = false,
  linkStyle = false,
  ...props
}: ButtonProps) {
  if (startIcon && endIcon) throw new Error('A button cannot have two icons');

  return (
    <StyledButton
      disabled={disabled}
      tabIndex={tabIndex}
      onKeyDown={keyHandler}
      onClick={clickHandler}
      title={title}
      className={mergeClassNames([
        styles.button,
        buttonStyle ? styles.buttonStyle : null,
        linkStyle ? styles.linkStyle : null,
        startIcon || endIcon ? styles.icon : null,
        startIcon || endIcon ? styles[endIcon ? 'right' : 'left'] : null,
        className,
      ])}
      style={style}
      type={type}
    >
      {props.showActivityIndicator && (
        <span className={styles.iconContainer}>
          <ActivityIndicator />
        </span>
      )}
      {!props.showActivityIndicator && startIcon && <span className={styles.iconContainer}>{startIcon}</span>}
      {children ? <span className={styles.textContainer}>{children}</span> : null}
      {endIcon && <span className={styles.iconContainer}>{endIcon}</span>}
    </StyledButton>
  );
}

export const StyledButton = styled.button`
  ${navType}
  white-space: nowrap;
  fill: currentColor;
  transition: background-color 0.3s linear, fill 0.3s linear, color 0.3s linear, stroke 0.3s linear;
`;

export const types = {
  button: 'button',
  submit: 'submit',
  reset: 'reset',
};

export * from './PrimaryButton';
export * from './SecondaryButton';
export * from './LinkStyleButton';
export * from './prefilled/BackButton';
export * from './prefilled/CloseButton';
export * from './prefilled/DecrementButton';
export * from './prefilled/IncrementButton';
export * from './prefilled/RemoveButton';
