import React, { createContext, useMemo, useContext, useState, useEffect, Dispatch, SetStateAction } from 'react';

export const baseAppearance = {
  dark: 'dark',
  light: 'light',
};

const BaseLayoutContext = createContext({ appearance: 'light' } as { appearance: 'light' | 'dark' });
const SetAppearanceContext = createContext((() => null) as Dispatch<SetStateAction<'dark' | 'light'>>);

interface BaseLayoutProviderProps {
  children: React.ReactNode | React.ReactNodeArray;
}
export function BaseLayoutProvider({ children }: BaseLayoutProviderProps) {
  const [appearance, setAppearance] = useState(baseAppearance.light as 'light' | 'dark');
  const contextValue = useMemo(() => ({ appearance }), [appearance]);

  return (
    <BaseLayoutContext.Provider value={contextValue}>
      <SetAppearanceContext.Provider value={setAppearance}>{children}</SetAppearanceContext.Provider>
    </BaseLayoutContext.Provider>
  );
}

export function useBaseLayout() {
  return useContext(BaseLayoutContext);
}

export function useDarkBaseAppearance() {
  const setAppearance = useContext(SetAppearanceContext);
  useEffect(() => {
    setAppearance('dark');
    return () => setAppearance('light');
  });
}
