import React from 'react';
import { FieldOutput } from 'types';
import { SelectField } from 'components/generic/InputField';
import { supportedCountries } from '../supportedCountries';
import { useDetectedCountry, useUserCountry } from '../UserCountryContext';
import { CountrySelectorWrapper } from './CountrySelectorWrapper';

interface CountrySelectorProps {
  onChangeCallback?: () => void;
}

export default function CountrySelector(props: CountrySelectorProps) {
  const [, setUserCountry] = useUserCountry();
  const [userCountry] = useUserCountry();
  const [detectedCountry] = useDetectedCountry();
  const presedentedCountry = userCountry || detectedCountry || undefined;
  const countries = [
    { id: 'global', value: '/', displayValue: 'Global' },
    ...supportedCountries.map(country => ({
      id: country.id,
      value: country.id,
      displayValue: country.urlTitle,
    })),
  ];

  function changeHandler(selected: FieldOutput) {
    const country = supportedCountries.find(country => country.id === selected.value);
    if (!country) return;

    setUserCountry(country.name);
    if (props.onChangeCallback) props.onChangeCallback();

    window.open(country.url);
  }

  return (
    <CountrySelectorWrapper>
      <SelectField
        id="redirect_site"
        name="redirectSite"
        options={countries}
        onChange={changeHandler}
        value={presedentedCountry?.toLowerCase()}
        height="tall"
      />
    </CountrySelectorWrapper>
  );
}
