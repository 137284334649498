import styled, { css } from 'styled-components';
import React, { lazy, useState } from 'react';
import { Suspense } from 'utils';
import CookieAcceptanceView from 'components/CookieAcceptanceView';
import GlobalStyle from 'styles/GlobalStyle';
import { maxLayoutWidth, mediaQueries, universalMargin } from 'styles';
import { white } from 'styles/baseColors';
import { ContentfulNavigation } from './types';
import ChatBubbleButton from 'components/generic/ChatBubbleButton';
import { useDebugGrid } from 'Debug';
import { PageProps } from 'gatsby';
import WebexHeaderBuffer from 'white-label/webex/WebexHeaderBuffer';
import Dropdown from 'components/Dropdown/Dropdown';
import { UserAreaProps } from './Header/UserArea';

const SeoInspector = lazy(() => import('components/generic/SeoInspector'));
const GlobalNavigation = lazy(() => import('./Header/GlobalNavigation'));
const DebugGrid = lazy(() => import('Debug/DebugGrid'));
const DebugModal = lazy(() => import('Debug/DebugModal'));

export type LayoutProps = {
  children?: React.ReactNode;
  path?: string;
  location: PageProps['location'];
  footer: JSX.Element;
  header: JSX.Element;
  mainNavigation?: ContentfulNavigation;
  globalNavVisibility: UserAreaProps['globalNavVisibility'];
  showWebexLayout: boolean;
  showChatBubble: boolean;
};

export default function Layout({ ...props }: LayoutProps) {
  const debugGrid = useDebugGrid();
  const [isGlobalNavVisible, toggleGlobalNavigation] = props.globalNavVisibility || [];
  const { header, footer, mainNavigation, showWebexLayout, showChatBubble } = props;

  return (
    <React.Fragment>
      <GlobalStyle />
      <LayoutWrapper>
        {/* the header is INTENTIONALLY added twice to have a unified way to push down the content below the header between different pages that is layed out different on different build targets */}
        {!showWebexLayout ? <FlowingHeader role="presentation">{header}</FlowingHeader> : <WebexHeaderBuffer />}
        <FixedHeader>{header}</FixedHeader>

        <ContentContainer>
          <article className="pageContent">{props.children}</article>
          {footer}
        </ContentContainer>

        <Dropdown />

        <Suspense fallback={null}>
          {mainNavigation && isGlobalNavVisible && (
            <GlobalNavigation mainNavigation={mainNavigation} toggleGlobalNavigation={toggleGlobalNavigation} />
          )}
          {Boolean(process.env.GATSBY_SEO_INSPECTOR === 'true') && <SeoInspector />}
          {debugGrid ? (
            <DebugModal full>
              <DebugGrid />
            </DebugModal>
          ) : null}
        </Suspense>

        {showChatBubble && (
          <FloatBottomRight>
            <ChatBubbleButton ariaLabel="Contact us" />
          </FloatBottomRight>
        )}

        <CookieAcceptanceView />

        {/* // root for modals using portal to render, dont remove! */}
        <div id="modal-root" />
      </LayoutWrapper>
    </React.Fragment>
  );
}

const LayoutWrapper = styled.div`
  max-width: ${maxLayoutWidth}px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  font-family: 'CiscoSansTT', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1; // z-indexes: main layout 1, header 3, modals 2
  max-width: ${maxLayoutWidth}px;
  @media print {
    margin: 0 auto 0 auto;
  }
`;

const HeaderWrapper = styled.div`
  background-color: ${white};
  width: 100%;
  max-width: ${maxLayoutWidth}px;
  margin-left: auto;
  margin-right: auto;
`;

const FlowingHeader = styled(HeaderWrapper)`
  @media screen {
    visibility: hidden;
  }
`;

const FixedHeader = styled(HeaderWrapper)`
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
  @media print {
    visibility: hidden;
  }
`;

export function useGlobalNavToggle() {
  const [isGlobalNavVisible, setIsGlobalNavVisible] = useState(false);
  function toggleGlobalNavigation(action?: 'open' | 'close') {
    switch (action) {
      case 'open':
        setIsGlobalNavVisible(true);
        break;
      case 'close':
        setIsGlobalNavVisible(false);
        break;
      default:
        setIsGlobalNavVisible(state => !state);
        break;
    }
  }
  return [isGlobalNavVisible, toggleGlobalNavigation] as const;
}

const FloatBottomRight = styled.div`
  z-index: 120;
  position: fixed;
  bottom: ${universalMargin * 2}px;
  right: ${universalMargin * 2}px;
  ${mediaQueries.small(css`
    bottom: ${universalMargin * 4}px;
    right: ${universalMargin * 4}px;
  `)}

  @media print {
    display: none;
  }
`;
