import { Quote, QuoteStateAction } from './typedefs'; // eslint-disable-line no-unused-vars
import React, { createContext } from 'react'; // eslint-disable-line no-unused-vars
import { useContext } from 'utils';

type ProductsWithoutDeterminedPaymentTypeType =
  | {
      dispatchPaymentTypeDetermineQuote: React.Dispatch<QuoteStateAction>;
      paymentTypeDetermineQuote: Quote;
    }
  | undefined;

const ProductsWithoutDeterminedPaymentType = createContext<ProductsWithoutDeterminedPaymentTypeType>(undefined);

function useProductsWithoutDeterminedPaymentTypeQuote() {
  return useContext(ProductsWithoutDeterminedPaymentType);
}

export { ProductsWithoutDeterminedPaymentType, useProductsWithoutDeterminedPaymentTypeQuote };
