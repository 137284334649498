import React, { useState } from 'react';
import { UserDataWithLevel } from 'utils';
import { UserContext, UserContextType } from './UserContext';

interface UserProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<UserContextType | null>(null);

  const updateUserIfChanged = (newUser: UserDataWithLevel) =>
    setUser(user => {
      if (newUser && newUser.email !== user?.email) {
        return newUser;
      } else return user;
    });

  return <UserContext.Provider value={[user, updateUserIfChanged]}>{children}</UserContext.Provider>;
}
