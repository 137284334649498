import React from 'react';
import { branding } from 'styles';
import Button from '..';
import Icon, { IconType } from '../../Icon';
import styled, { css } from 'styled-components';
import { mediaQueries } from '../../../../styles/breakpoints';

interface PrimaryButtonProps {
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  tabIndex?: number;
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  style?: object;
  disabled?: boolean;
  type?: 'reset' | 'submit' | 'button';
  iconType?: IconType;
  iconAtEnd?: boolean;
  showActivityIndicator?: boolean;
}

export function PrimaryButton({
  clickHandler,
  title,
  tabIndex,
  children,
  className,
  style,
  disabled = false,
  type,
  iconType,
  iconAtEnd = false,
  ...props
}: PrimaryButtonProps) {
  const icon = iconType ? <Icon IconType={iconType} /> : null;
  return (
    <StyledPrimaryButton
      disabled={disabled}
      title={title}
      tabIndex={tabIndex}
      clickHandler={clickHandler}
      className={className}
      style={style}
      startIcon={!iconAtEnd && icon}
      endIcon={iconAtEnd && icon}
      type={type}
      buttonStyle={true}
      showActivityIndicator={props.showActivityIndicator}
    >
      {children}
    </StyledPrimaryButton>
  );
}

export const StyledPrimaryButton = styled(Button)`
  height: 36px;
  padding: 0 18px;
  color: ${branding.primaryButton.colors.color};
  background-color: ${branding.primaryButton.colors.background};
  border-radius: ${branding.primaryButton.borderRadius.small};
  &:hover {
    color: ${branding.primaryButton.colors.hoverColor};
    background-color: ${branding.primaryButton.colors.hoverBackground};
  }
  &[disabled] {
    color: ${branding.primaryButton.colors.disabledColor};
    background-color: ${branding.primaryButton.colors.disabledBackground};
  }
  ${mediaQueries.medium(css`
    height: 48px;
    padding: 0 27px;
    border-radius: ${branding.primaryButton.borderRadius.medium};
  `)}
  ${mediaQueries.large(css`
    height: 54px;
    border-radius: ${branding.primaryButton.borderRadius.large};
  `)}
`;
