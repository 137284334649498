import { useCookieAcceptance } from 'context/CookiesAcceptance';
import React, { lazy } from 'react';
import { Suspense } from 'utils';
import { getBuildTarget } from 'white-label';
import { YoutubePlayerProps } from './YoutubePlayer';

const YoutubePlayer = lazy(() => import('./YoutubePlayer'));

function LazyYoutubePlayer(props: YoutubePlayerProps) {
  const [{ necessary: necessaryCookieAccepted }] = useCookieAcceptance();
  const videoInitAllowed = !['pwp', 'wwt'].includes(getBuildTarget()) || necessaryCookieAccepted;
  return <Suspense fallback={null}>{videoInitAllowed && <YoutubePlayer {...props} />}</Suspense>;
}

export { LazyYoutubePlayer as YoutubePlayer };
