export function mergeClassNames(
  classNames: Array<string | null | undefined> | string | null | undefined,
  ...args: Array<string | null | undefined>
) {
  if (Array.isArray(classNames)) {
    return classNames.filter(Boolean).join(' ');
  }

  return [classNames, ...args].filter(Boolean).join(' ');
}

export { mergeClassNames as mcn };
