import { getItemInQuote as getItemInQuoteImpl } from './utils/getItemInQuote';
import { generateQuoteItem, transformProductToQuotedProduct } from './utils/generateQuoteItem';
import {
  InventoryType,
  ItemInCart,
  Quote,
  QuoteAddActionPayloads,
  QuoteSetQuantityActionPayload,
  QuoteStateAction,
  QuoteAddWithPaymentTypeActionPayload,
} from './typedefs';
import { assertUnreachable } from 'utils';

/**
 * @throws {Error} Unknown quote action: type must match
 */
function reducer(quote: Quote, action: QuoteStateAction | Quote): Quote {
  if (Array.isArray(action)) {
    if (action.every(elem => 'bundle' in elem || 'product' in elem)) return [...action];
    throw new Error('invalid quote list passed in');
  }
  if (!action.type) throw new Error(`.type of action is needed!`);
  if (action.type !== 'reset' && action.type !== 'interrupted' && !action.payload?.item)
    throw new Error(`.item is needed in this action!`);
  switch (action.type) {
    case 'add':
      return addToQuote(action.payload.item, quote, action.payload);
    case 'addWithPaymentType':
      return addWithPaymentType(action.payload.item, quote, action.payload);
    case 'addWithSelectedOptionals':
      return quote; // let existing = quote.find(existingItem => action.payload.item.product.id === existingItem.product.id);
    case 'duplicate':
      throw new Error('duplicateInQuote(item, quote) not implemented');
    case 'remove':
      return removeFromQuote(action.payload.item, quote);
    case 'increment':
      return incrementInQuote(action.payload.item, quote);
    case 'decrement':
      return decrementInQuote(action.payload.item, quote);
    case 'setQuantity':
      if (!action.payload?.quantity) {
        throw new Error(`a provided .quantity property is needed in this action to set quantity`);
      }
      return setQuantityInQuote(action.payload.item, quote, action.payload);
    case 'reset':
      return resetQuote();
    case 'interrupted':
      return quote;
    default: {
      let { type } = action;
      assertUnreachable(type);
    }
  }
}

function addToQuote(item: InventoryType | InventoryType[], quote: Quote, payload: QuoteAddActionPayloads): Quote {
  if (Array.isArray(item)) return addMultipleToQuote(item, quote, payload); //? how to handle payload for multiple items? remove array support and do individual addToQuote again?
  return addSingleToQuote(item, quote, payload);
}

function addMultipleToQuote(items: InventoryType[], quote: Quote, payload: QuoteAddActionPayloads): Quote {
  let useSelectedVariantOnlyForFirstProduct = [
    ...items.reduce(
      (newQuote, item, index) =>
        addSingleToQuote(item, newQuote, index === 0 ? payload : { ...payload, variant: undefined }),
      quote
    ),
  ];
  return useSelectedVariantOnlyForFirstProduct;
}

function addSingleToQuote(item: InventoryType, quote: Quote, payload: QuoteAddActionPayloads): Quote {
  let selectedVariant = 'variant' in payload ? payload?.variant : undefined;
  switch (item.__typename) {
    case 'ContentfulProduct': {
      if (!selectedVariant && item.variants?.length) {
        selectedVariant = item.variants[0];
      }
      const existing = getItemInQuoteImpl(quote, transformProductToQuotedProduct(item, selectedVariant));
      if (existing) {
        if (existing.quantity < 0) existing.quantity = 0;
        existing.quantity += 1;
        return [...quote];
      }

      const quoteItem = generateQuoteItem(item, selectedVariant);
      return [...quote, quoteItem];
    }
    default:
      throw new Error(`Cannot add an elem of type ${item}`);
  }
}

function removeFromQuote(quoteItem: ItemInCart, quote: Quote): Quote {
  return quote.filter(elem => {
    if ('product' in elem) return quoteItem.product !== elem.product;
    return elem;
  });
}

function incrementInQuote(quoteItem: ItemInCart, quote: Quote): Quote {
  quoteItem.quantity += 1;
  return [...quote];
}

function decrementInQuote(quoteItem: ItemInCart, quote: Quote): Quote {
  quoteItem.quantity -= 1;
  return [...quote];
}

function setQuantityInQuote(quoteItem: ItemInCart, quote: Quote, payload: QuoteSetQuantityActionPayload): Quote {
  if (isNaN(Number(payload.quantity))) {
    return [...quote];
  }

  let quantity = isNaN(Number(payload.quantity)) ? 0 : Number(payload.quantity);
  let itemIndex = quote.findIndex(_qi => quoteItem === _qi);
  let beforeProvidedItem = quote.slice(0, itemIndex);
  let afterProvidedItem = quote.slice(itemIndex + 1);
  return [...beforeProvidedItem, { ...quoteItem, quantity }, ...afterProvidedItem];
}

function resetQuote() {
  return [];
}

function addWithPaymentType(item: ItemInCart, quote: Quote, payload: QuoteAddWithPaymentTypeActionPayload): Quote {
  let itemIndex = quote.findIndex(_qi => item.product.id === _qi.product.id);
  if (itemIndex >= 0) {
    let newQuote = quote;
    newQuote[itemIndex].quantity += 1;
    return [...newQuote];
  }

  return [...quote, { ...item, paymentType: payload.paymentType }];
}

export { reducer, addToQuote, setQuantityInQuote };
