import React, { lazy } from 'react';
import { Suspense } from 'utils';
import { useCookiesPerformanceAccepted } from '../../context/CookiesAcceptance';

let LoadSentry = lazy(() => import('./LoadSentry'));

export default function SentryTracking() {
  let cookiesAccepted = useCookiesPerformanceAccepted();

  return <Suspense fallback={null}>{cookiesAccepted && <LoadSentry cookiesAccepted={cookiesAccepted} />}</Suspense>;
}
