import { css, FlattenInterpolation, ThemeProps } from 'styled-components';

/*
 * BREAKPOINTS
 */
export const breakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200,
};

const largeHeight = 920;

/*
 * LAYOUT & COMPONENTS MAX WIDTH
 */
export const maxLayoutWidth = 2880; // NOTE: 2880px for production
export const maxContentWidth = 1440; // NOTE: 1440px for production

export const tinySectionWidth = 300;
export const smallSectionWidth = 648;
export const mediumSectionWidth = 875;
export const largeSectionWidth = 1100;

type MediaQueryArgs = string | FlattenInterpolation<unknown> | FlattenInterpolation<ThemeProps<unknown>>;

export const mediaQueries = {
  small: minWidthMediaQuery(breakpoints.small),
  medium: minWidthMediaQuery(breakpoints.medium),
  large: minWidthMediaQuery(breakpoints.large),
  extraLarge: minWidthMediaQuery(breakpoints.extraLarge),
  fullscreen: minWidthMediaQuery(maxContentWidth),
  portraitTouchables: (content: MediaQueryArgs) => css`
    @media screen and (max-width: ${breakpoints.large}px) and (orientation: portrait) {
      ${content};
    }
  `,
  largeHeight: (content: MediaQueryArgs) => css`
    @media screen and (min-height: ${largeHeight}px) {
      ${content};
    }
  `,
};

function minWidthMediaQuery(maxWidth: number) {
  return (content: MediaQueryArgs) => css`
    @media screen and (min-width: ${maxWidth}px) {
      ${content};
    }
  `;
}

/*
 * GRID COLUMNS AND GRID GUTTERS
 */

export type ScreenSize = 'mobile' | 'tablet' | 'desktop';

export function column(count = 1, screen: ScreenSize = 'mobile', normal = false) {
  const columnDesktop = 81 / 1324; // 81 (column width) (1444 (desktop width) - 58 * 2 (grid offset)) (12 columns)
  const columnTablet = 71 / 708; // 71 (column width) (768 (mobile width) - 30 * 2 (grid offset) = 708) (8 columns)
  const columnMobile = 38 / 278; // 38 (column width) (320 (mobile width) - 21 * 2 (grid offset) = 278) (6 columns)

  const columnWidth = {
    desktop: columnDesktop * Math.abs(count),
    tablet: columnTablet * Math.abs(count),
    mobile: columnMobile * Math.abs(count),
  };

  if (normal) return columnWidth[screen] * (count < 0 ? -1 : 1);
  return columnWidth[screen] * 100 * (count < 0 ? -1 : 1);
}

export function gutter(count = 1, screen: ScreenSize = 'mobile', normal = false) {
  const gutterDesktop = 32 / 1324; // 32 (gutter width) 1444 (screen width) - 58 * 2 (grid offset) = 1324
  const gutterTablet = 20 / 708; // 20 (gutter width) 768 (screen width) - 30 * 2 (grid offset) = 708
  const gutterMobile = 10 / 278; // 38 (gutter width) 320 (screen width) - 21 * 2 (grid offset) = 278

  const gutterWidth = {
    desktop: gutterDesktop * Math.abs(count),
    tablet: gutterTablet * Math.abs(count),
    mobile: gutterMobile * Math.abs(count),
  };

  if (normal) return gutterWidth[screen] * (count < 0 ? -1 : 1);
  return gutterWidth[screen] * 100 * (count < 0 ? -1 : 1);
}
