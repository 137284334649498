import { css } from 'styled-components';
import CiscoSansTTThinWoff2 from 'assets/fonts/CiscoSansTTThin.woff2';
import CiscoSansTTThinWoff from 'assets/fonts/CiscoSansTTThin.woff';
import CiscoSansTTExtraLightWoff2 from 'assets/fonts/CiscoSansTTExtraLight.woff2';
import CiscoSansTTExtraLightWoff from 'assets/fonts/CiscoSansTTExtraLight.woff';
import CiscoSansTTLightWoff2 from 'assets/fonts/CiscoSansTTLight.woff2';
import CiscoSansTTLightWoff from 'assets/fonts/CiscoSansTTLight.woff';
import CiscoSansTTRegularWoff2 from 'assets/fonts/CiscoSansTTRegular.woff2';
import CiscoSansTTRegularWoff from 'assets/fonts/CiscoSansTTRegular.woff';
import CiscoSansTTMediumWoff2 from 'assets/fonts/CiscoSansTTMedium.woff2';
import CiscoSansTTMediumWoff from 'assets/fonts/CiscoSansTTMedium.woff';
import CiscoSansTTBoldWoff2 from 'assets/fonts/CiscoSansTTBold.woff2';
import CiscoSansTTBoldWoff from 'assets/fonts/CiscoSansTTBold.woff';

export const fonts = css`
  @font-face {
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 100;
    src: url(${CiscoSansTTThinWoff2}) format('woff2'), url(${CiscoSansTTThinWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 200;
    src: url(${CiscoSansTTExtraLightWoff2}) format('woff2'), url(${CiscoSansTTExtraLightWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 300;
    src: url(${CiscoSansTTLightWoff2}) format('woff2'), url(${CiscoSansTTLightWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 400;
    src: url(${CiscoSansTTRegularWoff2}) format('woff2'), url(${CiscoSansTTRegularWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 500;
    src: url(${CiscoSansTTMediumWoff2}) format('woff2'), url(${CiscoSansTTMediumWoff}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 600;
    src: url(${CiscoSansTTBoldWoff2}) format('woff2'), url(${CiscoSansTTBoldWoff}) format('woff');
    font-display: fallback;
  }
`;
