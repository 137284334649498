const redirectCountries = {
  de: 'Germany',
  fr: 'France',
  gb: 'United Kingdom',
};

export const selectionOptions = [
  { default: 'Project Workplace (global)' },
  ...Object.entries(redirectCountries).map(([key, value]) => ({ [key]: `Webex.shop (${value})` })),
];

type Country = {
  id: string;
  name: string;
  url: string;
  urlTitle: string;
};

export const supportedCountries: Array<Country> = [
  {
    id: 'de',
    name: 'Germany',
    url: 'https://webex.shop/de',
    urlTitle: 'Webex.shop (DE)',
  },
  {
    id: 'fr',
    name: 'France',
    url: 'https://webex.shop/fr',
    urlTitle: 'Webex.shop (FR)',
  },
  {
    id: 'gb',
    name: 'United Kingdom',
    url: 'https://webex.shop/gb',
    urlTitle: 'Webex.shop (UK)',
  },
  {
    id: 'nl',
    name: 'Netherlands',
    url: 'https://webex.shop/nl',
    urlTitle: 'Webex.shop (NL)',
  },
  {
    id: 'at',
    name: 'Austria',
    url: 'https://webex.shop/at',
    urlTitle: 'Webex.shop (AT)',
  },
  {
    id: 'be',
    name: 'Belgium',
    url: 'https://webex.shop/be',
    urlTitle: 'Webex.shop (BE)',
  },
  {
    id: 'it',
    name: 'Italy',
    url: 'https://webex.shop/it',
    urlTitle: 'Webex.shop (IT)',
  },
  {
    id: 'es',
    name: 'Spain',
    url: 'https://webex.shop/es',
    urlTitle: 'Webex.shop (ES)',
  },
];
