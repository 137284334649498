import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

/** defaults for type inference, demoing the expected footprint of the OnChangeContext value */
const defaultContextValue = {
  onChangeCallbacks: new Set(),
  registerCallback:
    (cb = () => void cb) =>
    () =>
      void null,
};

let OnRequestAnimFrameOnScrollContext = createContext(defaultContextValue);

export function OnRequestAnimFrameOnScroll({ children }) {
  let rafInterupt = useRef(null);
  let interuptAnimationByRefreshRate = useRef(null);

  let contextValue = useMemo(() => {
    let onChangeCallbacks = new Set();
    let registerCallback = callback => {
      callback();
      onChangeCallbacks.add(callback);
      return () => onChangeCallbacks.delete(callback);
    };
    return {
      onChangeCallbacks,
      registerCallback,
    };
  }, []);

  const animate = useCallback(() => {
    rafInterupt.current = requestAnimationFrame(() => {
      contextValue.onChangeCallbacks.forEach(value => value());
      interuptAnimationByRefreshRate.current = false;
    });
  }, [contextValue.onChangeCallbacks]);

  const onBrowserChange = useCallback(() => {
    if (interuptAnimationByRefreshRate.current) return;
    interuptAnimationByRefreshRate.current = true;
    animate();
  }, [animate]);

  // useEffect(() => {
  //   window.addEventListener('hashchange', onBrowserChange);
  //   return () => {
  //     window.removeEventListener('hashchange', onBrowserChange);
  //   };
  // }, [onBrowserChange]);

  useEffect(() => {
    window.addEventListener('scroll', onBrowserChange);
    return () => {
      window.removeEventListener('scroll', onBrowserChange);
    };
  }, [onBrowserChange]);

  useEffect(() => {
    window.addEventListener('resize', onBrowserChange);
    return () => {
      window.removeEventListener('resize', onBrowserChange);
    };
  }, [onBrowserChange]);

  return (
    <OnRequestAnimFrameOnScrollContext.Provider value={contextValue}>
      {children}
    </OnRequestAnimFrameOnScrollContext.Provider>
  );
}

export function useRequestAnimFrameOnScroll() {
  return useContext(OnRequestAnimFrameOnScrollContext);
}
