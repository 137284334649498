import {
  EntryLinkTarget,
  EntryLinkTargetGeneric,
  WorkspaceCategoryLinkTarget,
  WorkspaceLinkTarget,
  WorkspaceSubCategoryLinkTarget,
} from 'types';
import { getProductCategoryPath, getProductPath } from './products';
import { isDevEnvironment } from '../utils';

export function getRoute<Target extends EntryLinkTargetGeneric>(target: Target): string;
export function getRoute<Target extends WorkspaceLinkTarget>(target: Target): string;
export function getRoute<Target extends WorkspaceCategoryLinkTarget>(target: Target): string;
export function getRoute<Target extends WorkspaceSubCategoryLinkTarget>(target: Target): string;
export function getRoute<Target extends EntryLinkTarget>(target: Target): string;
export default function getRoute<Target extends EntryLinkTarget>(target: Target) {
  switch (target?.__typename) {
    case 'ContentfulPage':
      return target?.slug || '/';
    case 'ContentfulArticle':
      return `/articles/${target?.slug}`;
    case 'ContentfulWorkspaceCategory': {
      return `/workspaces/${target.slug}`;
    }
    case 'ContentfulWorkspaceSubCategory': {
      const [parentWorkspaceCategory] = target.workspaceCategory || [];
      if (isDevEnvironment && !parentWorkspaceCategory)
        console.warn(
          `no parent 'workspaceCategory' (query for workspaceCategory: workspace_category in the workspace sub category)`
        );
      const [firstWorkspace] = target.workspaces || [];
      const categorySlug = parentWorkspaceCategory?.slug;
      const subcategorySlug = target.slug;
      return `/workspaces/${categorySlug}/${subcategorySlug}/${firstWorkspace?.slug}`;
    }
    case 'ContentfulWorkspace': {
      const [parentWorkspaceSubCategory] = target.workspaceSubCategory || [];
      const [parentWorkspaceCategory] = parentWorkspaceSubCategory?.workspaceCategory || [];
      const categorySlug = parentWorkspaceCategory?.slug;
      const subcategorySlug = parentWorkspaceSubCategory?.slug;
      if (!categorySlug || !subcategorySlug) return `/workspaces/other/${target?.slug}`;
      return `/workspaces/${categorySlug}/${subcategorySlug}/${target?.slug}`;
    }
    case 'ProductWithSelectedVariant':
    case 'ContentfulProduct':
      return getProductPath(target);
    case 'ContentfulProductCategory':
      return getProductCategoryPath(target);
    default: {
      if (isDevEnvironment) {
        console.error(`target is missing a target or __typename in ${JSON.stringify(target, null, 2)}`);
      }
      return '';
    }
  }
}
