import styled, { css } from 'styled-components';
import { mediaQueries } from 'styles';

// wrapper primarly used in CountrySelector, but exported here to prevent circular dependencies in other component
export const CountrySelectorWrapper = styled.div`
  display: inline-block;
  ${mediaQueries.small(css`
    margin-right: 20%;
  `)}
  ${mediaQueries.extraLarge(css`
    margin-right: 40%;
  `)}
`;
