import React from 'react';
import { isOnServer } from 'utils';
import OuntboundLink from './OutboundLink';

type TrackedWebexLinkProps = {
  children?: JSX.Element | string | Array<JSX.Element | string>;
  className?: string;
};

export default function TrackedWebexLink(props: TrackedWebexLinkProps) {
  const linkText = props.children || 'webex.com';
  return (
    <OuntboundLink
      href="https://www.webex.com/"
      className={props.className}
      eventCategory="User outbound link"
      eventAction="User clicks webex.com link"
      eventLabel={!isOnServer ? window.location.pathname : 'on server'}
    >
      {linkText}
    </OuntboundLink>
  );
}
