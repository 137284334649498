import React from 'react';
import Button from 'components/generic/Button';
import { encodeUrl } from 'utils';
import { useCookie } from '../../utils/useCookie';
import styled from 'styled-components';
import { branding, grey10, grey100 } from 'styles';
import { useUser } from 'context/User/UserContext';

export default function LoginButton() {
  const [user] = useUser();
  const [, setToken] = useCookie('token', null);

  const handleLogin = (event: React.MouseEvent<HTMLElement>) => {
    const query = {
      client_id: process.env.GATSBY_WEBEX_CLIENT_ID,
      response_type: 'code',
      redirect_uri: process.env.GATSBY_WEBEX_REDIRECT,
      scope: process.env.GATSBY_WEBEX_SCOPE,
    };
    const endpoint = `${process.env.GATSBY_WEBEX_AUTH_ENDPOINT}?${encodeUrl(query)}`;
    window.location.href = endpoint;
  };

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    setToken(null);
    window.location.href = '/';
  };

  if (user) {
    return (
      <StyledLoginButton type="button" clickHandler={handleLogout} title="Log out">
        Log out
      </StyledLoginButton>
    );
  }

  return (
    <StyledLoginButton type="button" clickHandler={handleLogin} title="Log in">
      Log in
    </StyledLoginButton>
  );
}

export const StyledLoginButton = styled(Button)`
  height: 44px;
  padding: 0 16px;
  color: ${grey100};
  background-color: ${grey10};
  border-radius: 999px;
  font-weight: 100;
  font-size: 18px;
  &:hover {
    color: ${grey100};
  }
  &[disabled] {
    color: ${branding.primaryButton.colors.disabledColor};
  }
`;
