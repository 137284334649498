import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { mergeClassNames } from 'utils';
import { universalMargin, mediaQueries, grid, navType } from 'styles';
import { white, grey60, grey100, black, grey70 } from 'styles/baseColors';
import { useDebug } from 'Debug';
import { useBaseLayout } from 'context/BaseLayoutState';
import Section from 'components/generic/Section';
import LogoColumn from './LogoColumn';
import { ContentfulWorkspaceCategory } from 'types';
import { ProductCategory } from 'context/Quote/typedefs';
import { SiteMetadata } from '../types';

type FooterMenuItem = {
  label: string;
  url?: string;
};

type FooterMenuColumn = {
  heading: FooterMenuItem;
  items: Array<FooterMenuItem>;
};

export type FooterMenuData = [FooterMenuColumn?, FooterMenuColumn?, FooterMenuColumn?, FooterMenuColumn?];
interface FooterProps {
  className?: string;
  topLogo?: React.ReactNode;
  showLanguageSelector?: boolean;
  showFooterWorkspaces?: boolean;
  siteMetadata: FooterMetaData;
  menu?: FooterMenuData;
}

export type FooterMetaData = Pick<SiteMetadata, 'globalMenuBottomLinks' | 'contactPage' | 'homeLink'>;
export type FooterData = {
  productCategories: { heading: string; sections: ProductCategory[] };
  workspaceCategories: { heading: string; sections: ContentfulWorkspaceCategory[] };
  site: { siteMetadata: FooterMetaData };
};

export default function Footer({ className, ...props }: FooterProps) {
  const { appearance } = useBaseLayout();
  const debug = useDebug();

  return (
    <footer>
      <FooterWrapper
        theme={appearance}
        className={mergeClassNames(appearance, debug ? 'debug' : null, className)}
        innerClassName="inner"
        size="max"
      >
        <Nav>
          <LogoColumnWrapper>
            <LogoColumn
              siteMetadata={props.siteMetadata}
              showLanguageSelector={props.showLanguageSelector || false}
              topLogo={props.topLogo}
            />
          </LogoColumnWrapper>

          {props.menu &&
            props.menu.map((column, index) => {
              return (
                <Column key={index}>
                  {column && (
                    <>
                      {column.heading.url ? (
                        <Link className="heading" to={column.heading.url}>
                          {column.heading.label}
                        </Link>
                      ) : (
                        <NonLink>{column.heading.label}</NonLink>
                      )}
                      <List>
                        {column.items?.map((menuItem, index) => (
                          <li key={menuItem.url || '' + index}>
                            {menuItem.url ? (
                              menuItem.url.indexOf('https://') === -1 ? (
                                <Link to={menuItem.url}>{menuItem.label}</Link>
                              ) : (
                                <a href={menuItem.url}>{menuItem.label}</a>
                              )
                            ) : (
                              <NonLink>{menuItem.label}</NonLink>
                            )}
                          </li>
                        ))}
                      </List>
                    </>
                  )}
                </Column>
              );
            })}
        </Nav>
      </FooterWrapper>
    </footer>
  );
}

const NonLink = styled.span`
  color: ${props => (props.theme === 'light' ? grey100 : white)};
`;

const FooterWrapper = styled(Section)<{ theme: 'light' | 'dark' }>`
  padding-top: ${universalMargin * 8}px;
  padding-bottom: ${universalMargin * 8}px;
  background: ${props => (props.theme === 'light' ? white : black)};
  color: ${props => (props.theme === 'light' ? grey60 : white)};
  .heading {
    font-weight: 400;
    color: ${props => (props.theme === 'light' ? grey70 : 'inherit')};
  }
  span,
  p,
  a {
    ${navType}
    background-color: transparent;
    text-decoration: none;
    color: inherit;
  }
  ${mediaQueries.large(css`
    color: ${props => (props.theme === 'light' ? grey100 : white)};
  `)}
`;

const Nav = styled.nav`
  ${grid}
  @media print {
    display: none;
  }
`;

const Column = styled.div`
  display: none;
  ${mediaQueries.large(css`
    display: unset;
  `)}
  grid-column: 1 / -1;
  ${mediaQueries.large(css`
    grid-column: span 2;
  `)}
  &:last-of-type {
    display: unset;
    ${mediaQueries.large(css`
      padding-right: 0;
    `)}
    .heading {
      display: none;
      ${mediaQueries.large(css`
        display: unset;
      `)}
    }
  }
`;

const List = styled.ul`
  ${mediaQueries.large(css`
    padding-top: ${universalMargin * 2}px;
  `)}
`;

const LogoColumnWrapper = styled(Column)`
  display: none;
  ${mediaQueries.large(css`
    display: unset;
    grid-column: span 4;
    line-height: 44px;
  `)}
`;
