import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import QuoteIcon from '../../assets/cart_44.svg';
import React from 'react';
import { getOrderListLength } from '../../../../../context/Quote/utils/quoteCalculations';
import { useQuote } from '../../../../../context/Quote/QuoteContext';
import { black, branding, button, grey10, white } from 'styles';

interface GoToOrderButtonWithStatusProps {
  className?: string;
}

export default function GoToOrderButtonWithStatus({ className }: GoToOrderButtonWithStatusProps) {
  const {
    site: {
      siteMetadata: {
        orderPage: { url, title },
      },
    },
  } = useStaticQuery(query);

  return (
    <OrderButton activeClassName="active" className={className} to={url} title={title} partiallyActive>
      <StyledQuoteIcon />
      <BadgeWrapper />
    </OrderButton>
  );
}

const OrderButton = styled(Link)`
  ${button};
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-content: center;
  background-color: ${grey10};
  height: 44px;
  width: 44px;
  border-radius: 22px;
  text-decoration: none !important;

  &.active {
    color: ${white};
    background-color: ${black};
    &:hover {
      color: ${white};
    }
  }
`;

const StyledQuoteIcon = styled(QuoteIcon)`
  width: 100%;
  height: 100%;
`;

const Badge = styled.span`
  display: block;
  position: absolute;
  left: -12px;
  bottom: 0px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: ${white};
  background-color: ${branding.accentColor};
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
`;

function BadgeWrapper() {
  const { quote } = useQuote();
  return <Badge>{getOrderListLength(quote)}</Badge>;
}

const query = graphql`
  query {
    site {
      siteMetadata {
        orderPage {
          title
          url
          type
        }
      }
    }
  }
`;
