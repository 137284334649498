import { css } from 'styled-components';
import { gutter, mediaQueries, ScreenSize } from './breakpoints';
import { universalMargin } from './variables';

export const gridPadding: { [Property in ScreenSize]: number } = {
  mobile: universalMargin * 3,
  tablet: universalMargin * 4,
  desktop: universalMargin * 8,
};

const generateGridPadding = (size: ScreenSize) => css`
  padding-left: ${gridPadding[size]}px;
  padding-right: ${gridPadding[size]}px;
`;

export const gridPaddings = css`
  ${generateGridPadding('mobile')}
  ${mediaQueries.medium(css`
    ${generateGridPadding('tablet')}
  `)}
  ${mediaQueries.large(css`
    ${generateGridPadding('desktop')}
  `)}
`;

/** @deprecated - please see <Row> & <Column> components */
export const grid = css<{ disableGridPaddings?: boolean }>`
  ${props => (!props.disableGridPaddings ? gridPaddings : null)}
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: ${gutter(1, 'mobile')}%;
  ${mediaQueries.medium(css`
    grid-template-columns: repeat(8, 1fr);
    column-gap: ${gutter(1, 'tablet')}%;
  `)}
  ${mediaQueries.large(css`
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${gutter(1, 'desktop')}%;
  `)}
`;
