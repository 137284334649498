import { useState, useRef, MutableRefObject } from 'react';
import { isOnServer, useLayoutEffect } from './';

export function useResizeObserverElemRect(node: MutableRefObject<Element | undefined | null>) {
  const [resizeDimensions, setResizeDimensions] = useState<DOMRect>();

  const observerRef = useRef(
    !isOnServer
      ? new ResizeObserver(([entry]) => {
          const { contentRect } = entry;
          setResizeDimensions(contentRect);
        })
      : null
  );

  useLayoutEffect(() => {
    const { current: observer } = observerRef;
    if (!observer) return;
    observer.disconnect();
    if (!node || !node.current) return;
    observer.observe(node.current);

    return () => {
      if (observer) observer.disconnect();
    };
  }, [node]);

  return [resizeDimensions] as const;
}
