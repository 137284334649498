import { QuoteStateAction } from '../typedefs'; // eslint-disable-line no-unused-vars
import { useTrackCustomEvent } from '../../../utils/useTrackCustomEvent';

export function useTrackCartEvents() {
  let trackCustomEvent = useTrackCustomEvent();
  /** @param {QuoteStateAction} action */
  let trackEvent = action => {
    switch (action.type) {
      case 'add': {
        let label = `${action.payload?.label ? action.payload?.label + ':' : ''} ${getItemHeading(
          action.payload.item
        )} @ ${window.location.pathname}`;

        trackCustomEvent({
          category: 'Quote interactions',
          action: `add item to quote`,
          label,
          value: getItemValue(action.payload.item),
        });
        break;
      }
      case 'remove': {
        let quoteItem = action.payload.item.product;
        let label = `${action.payload.item.quantity}x ${quoteItem?.heading} @ ${window.location.pathname}`;
        let value = -quoteItem.price * action.payload.item.quantity;

        trackCustomEvent({
          category: 'Quote interactions',
          action: 'Removing product',
          label,
          value,
        });
        break;
      }
      case 'increment':
        trackCustomEvent({
          category: 'Quote interactions',
          action: `incrementing item quantity`,
          label: `${action.payload.item.product.heading} @ ${window.location.pathname}`,
          value: action.payload.item.product.price,
        });
        break;
      case 'decrement': {
        trackCustomEvent({
          category: 'Quote interactions',
          action: `decrementing item quantity`,
          label: `${action.payload.item.product.heading} @ ${window.location.pathname}`,
          value: -action.payload.item.product.price,
        });
        break;
      }
      case 'reset': {
        trackCustomEvent({
          category: 'Quote interactions',
          action: `quote content resetted`,
          label: window.location.pathname,
        });
        break;
      }
      case 'duplicate': {
        trackCustomEvent({
          category: 'Quote interactions',
          action: `quote item duplicated`,
          label: window.location.pathname,
        });
        break;
      }
      case 'setQuantity': {
        trackCustomEvent({
          category: 'Quote interactions',
          action: `setting product quantity`,
          label: `${action.payload.item.product.heading} @ ${window.location.pathname}`,
          value: action.payload.item.product.price * action.payload.quantity,
        });
        break;
      }
      case 'interrupted': {
        trackCustomEvent({
          category: 'Quote interactions',
          action: `Adding item to queue waiting for user selecting purchase type before putting it into quote`,
          label: `${getItemHeading(action.payload.item)} @ ${window.location.pathname}`,
          value: getItemValue(action.payload.item),
        });
        break;
      }
      case 'addWithPaymentType': {
        trackCustomEvent({
          category: 'Quote interactions',
          action: `Adding item to quote after user selected payment type`,
          label: `${getItemHeading(action.payload.item)} @ ${window.location.pathname}`,
          value: getItemValue(action.payload.item),
        });
        break;
      }
      default:
        console.log(`unknown cart action type in action:`, action);
    }
  };

  return trackEvent;
}

function getItemHeading(item) {
  return Array.isArray(item)
    ? `[${item.reduce((title, item, index) => {
        let ttl = title.concat(`${index < 1 ? '' : ', '}${item.title || item.heading}`);
        return ttl;
      }, '')}]`
    : item.heading || item.title;
}

function getItemValue(item) {
  return Array.isArray(item) ? item.reduce((total, item) => total + (item.price > 0 ? item.price : 0), 0) : item.price;
}
