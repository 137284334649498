import { BuildTargetType } from './shared/types';

/**
 * here we list the all the possible build targets we got in this project
 * these are used to change the build output (more specifically the branding of the project)
 */

export const buildTargets = {
  pwp: 'pwp',
  webex: 'webex',
  wwt: 'wwt',
  ford: 'ford',
  onboarding: 'onboarding',
} as const;

export function isValidBuildTarget(envVariable: unknown): envVariable is BuildTargetType {
  try {
    if (typeof JSON.stringify(envVariable) !== 'string') {
      throw new Error('GATSBY_BUILD_TARGET env var is not a string');
    }
    return (
      envVariable !== undefined && typeof envVariable === 'string' && Object.keys(buildTargets).includes(envVariable)
    );
  } catch {
    return false;
  }
}

export function getBuildTarget() {
  const buildTargetOptions = Object.keys(buildTargets);
  const target = isValidBuildTarget(process.env.GATSBY_BUILD_TARGET) && process.env.GATSBY_BUILD_TARGET;
  if (!target) {
    throw new Error(`Environment variable GATSBY_BUILD_TARGET is not set. Must be one of: ${buildTargetOptions}`);
  }
  return target;
}

export function isBuildTargetPwp() {
  return getBuildTarget() === buildTargets.pwp;
}

export function isBuildTargetWebex() {
  return getBuildTarget() === buildTargets.webex;
}

export function isBuildTargetWwt() {
  return getBuildTarget() === buildTargets.wwt;
}

export function isBuildTargetFord() {
  return getBuildTarget() === buildTargets.ford;
}

export function isBuildTargetOnboarding() {
  return getBuildTarget() === buildTargets.onboarding;
}
