import { ReactNode } from 'react';

export function filterEmptyParagraphs(richtextNodes: ReactNode) {
  let nonEmptyChildren = (Array.isArray(richtextNodes) ? richtextNodes : []).filter(richtextNodeContainsText);
  if (!nonEmptyChildren.length) return null;
  return nonEmptyChildren;
}

function richtextNodeContainsText(richtextNodes: ReactNode) {
  return !isEmptyParagraph(richtextNodes);
}

// here we see if we match the empty paragraph pattern: `[false, '']`
function isEmptyParagraph(richtextNode: ReactNode) {
  return (
    Array.isArray(richtextNode) &&
    richtextNode.length === 2 &&
    richtextNode[0] === false &&
    typeof richtextNode[1] === 'string' &&
    richtextNode[1].length === 0
  );
}
