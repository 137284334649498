import { ItemInCart, Quote, QuotedItem } from '../typedefs'; // eslint-disable-line no-unused-vars

export function getItemInQuote(quote: Quote, item: QuotedItem): ItemInCart | undefined {
  switch (item.__typename) {
    case 'ProductWithSelectedVariant': {
      return getProduct(quote, item);
    }
    default:
      throw new Error(`Cannot get item of type from quote`);
  }
}

function getProduct(quote: Quote, item: QuotedItem) {
  return quote.find(({ product }) => {
    if (product?.selectedVariant?.id !== item?.selectedVariant?.id) return false;
    return product.id === item.id;
  });
}
