import { createContext } from 'react';
import { QuoteContext as QuoteContextType } from './typedefs';
import { useContext } from 'utils';

const QuoteContext = createContext<QuoteContextType | undefined>(undefined);

function useQuote() {
  const context = useContext(QuoteContext);
  if (!context) throw new Error('QuoteContext must be used inside the <QuoteProvider> (with a value)');
  return context;
}

export { useQuote, QuoteContext };
