import React, { forwardRef, ElementType, CSSProperties, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { gridPaddings } from 'styles';
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from 'types/PolymorphicComponentPropsWithRef';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';

type SectionSize = 'tiny' | 'small' | 'medium' | 'large' | 'max' | 'full';
type SectionSpacing = 'none' | 'tight' | 'normal' | 'medium' | 'spacy';

type SectionCustomProps = {
  children: ReactNode | Array<ReactNode>;
  spacing?: SectionSpacing;
  id?: string;
  className?: string;
  innerClassName?: string;
  innerPadding?: boolean;
  style?: CSSProperties;
  size?: SectionSize;
  withPadding?: boolean;
};

type SectionProps<ComponentType extends ElementType> = PolymorphicComponentPropsWithRef<
  ComponentType,
  SectionCustomProps
>;

type SectionForwardedRef = <ComponentType extends ElementType = 'section'>(
  props: SectionProps<ComponentType>
) => ReactElement | null;

export const Section: SectionForwardedRef = forwardRef(function <ComponentType extends ElementType = 'section'>(
  props: SectionProps<ComponentType>,
  ref?: PolymorphicRef<ComponentType>
) {
  const size = props.size || 'large';
  const spacing = props.size || 'none';
  const Component = props.as || 'section';

  return (
    <Component
      id={props.id}
      className={mergeClassNames(styles.section, props.className, props.withPadding ? styles.withPadding : null)}
      style={props.style}
      ref={ref}
    >
      <Inner
        className={mergeClassNames('inner', styles[size], styles[spacing], props.innerClassName)}
        gridPadding={props.innerPadding || false}
      >
        {props.children}
      </Inner>
    </Component>
  );
});

export default Section;

const Inner = styled.div<{ gridPadding: boolean }>`
  min-height: 100%;
  width: 100%;
  background: inherit;
  ${props => (props.gridPadding ? gridPaddings : null)}
`;
