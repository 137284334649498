import React from 'react';
import { RichText as RichTextType } from 'types';
import { ContentfulRichText } from './ContentfulRichText';
import { NotRichText } from './NotRichText';

type AcceptedRichTextData = RichTextType | string | Record<string, string>;

interface RichTextProps {
  children?: AcceptedRichTextData;
  className?: string;
  imageClassName?: string;
  linkIcons?: boolean;
  options?: {
    textColor?: 'grey60';
    padding?: 'none';
  };
}

export default function RichText({ children, className, imageClassName, linkIcons = false, ...props }: RichTextProps) {
  switch (true) {
    case children &&
      typeof children === 'object' &&
      'raw' in children &&
      typeof children.raw === 'string' &&
      children.raw !== null: {
      return (
        <ContentfulRichText
          richtextData={children as React.ComponentProps<typeof ContentfulRichText>['richtextData']} // at this point we have checked we got the requirements for the CMSRichText.richtextData, except children.references that is not required in practice.
          className={className}
          imageClassName={imageClassName}
          linkIcons={linkIcons}
          textColor={props.options?.textColor}
          padding={props.options?.padding}
        />
      );
    }

    case children && typeof children === 'object': {
      const extractedString =
        children !== undefined && children !== null
          ? Object.values(children).find(child => typeof child === 'string') // for CMS Long text //todo find the first object that child is string, we should use the children type to restrict objects with more than one property...
          : '';
      return <NotRichText className={className}>{extractedString}</NotRichText>;
    }
    case !Boolean(children): {
      return null;
    }
    default: {
      return <NotRichText className={className}>{children}</NotRichText>;
    }
  }
}
