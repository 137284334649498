exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-get-in-touch-tsx": () => import("./../../../src/templates/get-in-touch.tsx" /* webpackChunkName: "component---src-templates-get-in-touch-tsx" */),
  "component---src-templates-learn-tsx": () => import("./../../../src/templates/learn.tsx" /* webpackChunkName: "component---src-templates-learn-tsx" */),
  "component---src-templates-order-order-tsx": () => import("./../../../src/templates/order/order.tsx" /* webpackChunkName: "component---src-templates-order-order-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-preview-colors-tsx": () => import("./../../../src/templates/preview/colors.tsx" /* webpackChunkName: "component---src-templates-preview-colors-tsx" */),
  "component---src-templates-preview-typography-tsx": () => import("./../../../src/templates/preview/typography.tsx" /* webpackChunkName: "component---src-templates-preview-typography-tsx" */),
  "component---src-templates-product-category-product-category-tsx": () => import("./../../../src/templates/productCategory/productCategory.tsx" /* webpackChunkName: "component---src-templates-product-category-product-category-tsx" */),
  "component---src-templates-product-product-tsx": () => import("./../../../src/templates/product/product.tsx" /* webpackChunkName: "component---src-templates-product-product-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-workspace-sub-categories-js": () => import("./../../../src/templates/workspaceSubCategories.js" /* webpackChunkName: "component---src-templates-workspace-sub-categories-js" */),
  "component---src-templates-workspace-tsx": () => import("./../../../src/templates/workspace.tsx" /* webpackChunkName: "component---src-templates-workspace-tsx" */),
  "component---src-templates-workspaces-tsx": () => import("./../../../src/templates/workspaces.tsx" /* webpackChunkName: "component---src-templates-workspaces-tsx" */)
}

