import ArrowDownIconSVG from './assets/arrow_down.svg';
import ArrowDownSmallIconSVG from './assets/arrow-down-small.svg';
import ArrowLeftIconSVG from './assets/arrow_left.svg';
import ArrowRightIconSVG from './assets/arrow_right.svg';
import ArrowRightWithLineSVG from './assets/arrow-right-with-line.svg';
import ArrowUpIconSVG from './assets/arrow_up.svg';
import CameraIconSVG from './assets/camera.svg';
import Cancel from './assets/cancel.svg';
import CartIconSVG from './assets/cart.svg';
import CheckedIconSVG from './assets/checked.svg';
import Clear from './assets/clear.svg';
import DocumentIconSVG from './assets/document.svg';
import Download from './assets/download.svg';
import Fullscreen from './assets/fullscreen.svg';
import PenIconSVG from './assets/pen.svg';
import PeopleIconSVG from './assets/people.svg';
import QuoteIconSVG from './assets/quote.svg';
import React from 'react';
import SendIconSVG from './assets/send.svg';
import SizeIcon from './assets/size.svg';
import Wallpaper from './assets/wallpaper.svg';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';
import styled from 'styled-components';

export const IconTypes = {
  people: PeopleIconSVG,
  size: SizeIcon,
  left: ArrowLeftIconSVG,
  right: ArrowRightIconSVG,
  down: ArrowDownIconSVG,
  up: ArrowUpIconSVG,
  arrowdown: ArrowDownSmallIconSVG,
  document: DocumentIconSVG,
  quote: QuoteIconSVG,
  send: SendIconSVG,
  cart: CartIconSVG,
  camera: CameraIconSVG,
  pen: PenIconSVG,
  checked: CheckedIconSVG,
  wallpaper: Wallpaper,
  fullscreen: Fullscreen,
  download: Download,
  clear: Clear,
  cancel: Cancel,
  rightWithLine: ArrowRightWithLineSVG,
};

export default function Icon({
  label = '',
  IconType = IconTypes.people,
  className = '',
  style = {},
}: {
  label?: string;
  IconType?: IconType;
  className?: string;
  style?: object;
}) {
  return label && label.length ? (
    <div className={mergeClassNames(styles.icon, className)} style={style}>
      <IconType />
      {label}
    </div>
  ) : (
    <IconWrapper className={className} style={style}>
      <IconType />
    </IconWrapper>
  );
}

export type IconType = React.FunctionComponent;

const IconWrapper = styled.div`
  display: inline;
`;
