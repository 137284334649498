import { MuxVideo, Image as ImageType } from 'types';

export type PosterFormat = 'png' | 'jpg' | 'gif';

export function generatePosterFrameImgSrcSet(
  cmsvideo: MuxVideo,
  posterFormat: PosterFormat,
  maxWidth = 1440,
  posterFrame?: number
): ImageType {
  const width = maxWidth;
  const ratioParts = cmsvideo?.ratio?.split(':') || [];
  const height = Math.floor(cmsvideo.ratio ? width * findRatio(Number(ratioParts[0]), Number(ratioParts[1])) : 0);
  const posterTime = posterFrame || 0;

  // build a mux video poster frame image (https://docs.mux.com/guides/video/get-images-from-a-video#get-an-image-from-a-video):
  const constructMuxPosterLocation = (width: number, height: number) =>
    `//image.mux.com/${cmsvideo.playbackId}/thumbnail.${posterFormat}?width=${width}&height=${height}&fit_mode=pad&time=${posterTime}`;

  const srcSet = composeSizedSrcStrings(constructMuxPosterLocation, width, height);
  const fallbackSrc = constructMuxPosterLocation(width, height);
  const contentType = `image/${posterFormat}`;
  const sizes = `(min-width: ${maxWidth}px) ${maxWidth}px, 100vw`;

  return {
    __typename: 'ContentfulAsset',
    id: '',
    title: '',
    description: '',
    file: {
      contentType,
      details: {
        image: { width, height },
      },
      url: `https:${fallbackSrc}`,
    },
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet,
            sizes,
            type: contentType,
          },
        ],
        fallback: {
          src: fallbackSrc,
          srcSet,
          sizes,
        },
      },
      layout: 'constrained',
      width,
      height,
    },
  };
}

function findRatio(width: number, height: number) {
  return height / width;
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/srcset
 * @param {(width: number, height: number) => string} constructPosterUrl a function for constructing an URL for a sized image based on width / height
 * @param {number} maxWidth
 */
function composeSizedSrcStrings(
  constructPosterUrl: (width: number, height: number) => string,
  maxWidth: number,
  maxHeight: number
) {
  const ratio = findRatio(maxWidth, maxHeight);
  const widths = [maxWidth / 4, maxWidth / 2, maxWidth];

  const getWidthString = (width: number, index: number, arr: Array<number>) => {
    const url = constructPosterUrl(width, Math.floor(width * ratio));
    const lineTerminator = index < arr.length - 1 ? ', \n' : ''; // don't terminate the last line
    return `${url} ${width}w${lineTerminator}`;
  };

  return `${widths.map(getWidthString).join('')}`;
}
