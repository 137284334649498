import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { branding } from 'styles/variables';
import styled, { css } from 'styled-components';

export type ImageShape =
  | 'circle'
  | 'roundedCorners'
  | 'cardInnerRoundedCorners' // used when the image is placed inside a card with rounded corners
  | 'rectangular'
  | 'square'
  | 'wide'
  | '16:9';

export type ImageProps = GatsbyImageProps & {
  eager?: boolean;
  shape?: ImageShape;
  image?: IGatsbyImageData;
};

export default function Image(props: ImageProps) {
  const { eager, ...rest } = props;

  /** For some shapes, it makes sense to default to 'cover' rather than 'contain', so we don't always have to pass objectFit option when specifying 'square', 'wide' or 'circle'. */
  const fallbackObjectFit = ['square', 'wide', 'circle', '16:9'].includes(props.shape || '') ? 'cover' : 'contain';
  const objectFit = props.objectFit || fallbackObjectFit;

  if (!props.image) {
    return null;
  }

  return (
    <StyledGatsbyImage
      {...rest}
      loading={eager ? 'eager' : 'lazy'}
      objectFit={objectFit}
      objectPosition={props.objectPosition || 'center'}
      shape={props.shape}
    />
  );
}

const StyledGatsbyImage = styled(GatsbyImage)<{ shape?: ImageShape }>`
  z-index: 0; // Fixes issue with rounded corners in Safari
  ${props => props.shape && getImageStyle(props.shape)}
`;

function getImageStyle(shape: ImageShape) {
  switch (shape) {
    case 'circle':
      return css`
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 9999px;
      `;

    case 'roundedCorners':
      return css`
        border-radius: ${branding.boxBorderRadius};
      `;

    case 'cardInnerRoundedCorners':
      return css`
        border-radius: ${branding.innerBorderRadius};
      `;

    case 'rectangular':
      return css``;

    case 'square':
      return css`
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: ${branding.boxBorderRadius};
      `;

    case '16:9':
      return css`
        height: 0;
        width: 100%;
        padding-bottom: 56.275%; // 16:9 Aspect Ratio
        border-radius: ${branding.boxBorderRadius};
      `;

    case 'wide':
      return css`
        width: 100%;
        max-height: 456px;
        border-radius: ${branding.boxBorderRadius};
      `;
  }
}

GatsbyImage.displayName = 'GatsbyImage';
Image.displayName = 'PwpImage';
