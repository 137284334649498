import React from 'react';
import styles from './index.module.scss';

export default function ActivityIndicator() {
  const circumference = 7 * 2 * Math.PI;
  return (
    <svg height="16" width="16">
      <defs>
        <circle id="shape" cx="8" cy="8" r="7" />
        <clipPath id="clip">
          <use xlinkHref="#shape" />
        </clipPath>
        <circle id="circle" cx="8" cy="8" r="7" />
      </defs>

      <use xlinkHref="#circle" stroke="gray" strokeWidth="2" fill="none" clipPath="url(#clip)" />
      <use
        xlinkHref="#shape"
        stroke="black"
        strokeWidth="4"
        fill="none"
        clipPath="url(#clip)"
        className={styles.spin}
        style={{
          strokeDasharray: `${circumference / 4} ${(circumference / 4) * 3}`,
          strokeDashoffset: `${(circumference / 4) * 2}`,
        }}
      />
    </svg>
  );
}
