import GoogleTracking from './src/components/GoogleTracking';
import Layout from 'pwplayout'; // see gatsby-node.js->webpack-config->resolve.alias... for resolution of this module
import React from 'react';
import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs, ShouldUpdateScrollArgs, GatsbyBrowser } from 'gatsby';
import SentryTracking from './src/components/SentryTracking';
import Store from './src/context/Store';
import { navigate } from 'gatsby';
import DetectCountry from './src/context/WebexShopIntegration/DetectCountry';

export { onClientEntry } from './config/onClientEntry';

export let wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }: WrapPageElementBrowserArgs) => {
  let { location } = props;
  // client side redirect to support redirecting legacy link paths that potentially can exists as links throuhout the webex.com/ cisco.com sites
  // example: projectworkplace.cisco.com/#/scenarios/meeting/conference needs to be translated into projectworkplace.cisco.com/workspaces/meeting/conference (scenarios -> workspaces translation is handled in the redirects in config/pages/workspaces)
  if (location.hash.startsWith('#/')) {
    let newDestination = location.hash.replace('#', '');
    navigate(newDestination, { replace: true });
  }

  // Prevent layout wrapper with createPage({context: {useLayout: false}})
  if (props.pageContext.useLayout === false) {
    return element;
  } else {
    return <Layout {...props}>{element}</Layout>;
  }
};

export let wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }: WrapRootElementBrowserArgs) => {
  return (
    <Store>
      <SentryTracking />
      <GoogleTracking />
      <DetectCountry />
      {element}
    </Store>
  );
};

export let shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }: ShouldUpdateScrollArgs) => {
  const { location } = routerProps;
  if (location?.state?.modal || location?.state?.workspaceToggle) return false; // prevent scroll when modal|workspacesToggle flag is passed in
  return true;
};
