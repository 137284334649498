import styled, { css } from 'styled-components';
import { universalMargin, contentBlock, grey60, grey20 } from 'styles';

const sharedLinkIconStyles = css`
  content: '';
  width: ${universalMargin * 2.5}px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
`;

export const RichTextContainer = styled.div<{ padding?: 'none'; textColor?: 'grey60' }>`
  ${contentBlock}
  width: 100%;

  ${props =>
    props.padding === 'none' &&
    css`
      padding: 0 !important;
    `}
  ${props =>
    props.textColor === 'grey60' &&
    css`
      color: ${grey60};
    `}

  .entry-link,
  .url-link,
  .asset-link {
    &:after {
      ${sharedLinkIconStyles};
    }
  }
  .entry-link {
    &:after {
      background-image: url(/images/link_icon_entry.svg);
      background-size: 8px;
      background-position-x: center;
    }
  }
  .url-link {
    &:before {
      background-image: url(/images/link_icon_url.svg);
      background-size: 12px;
    }
  }
  .asset-link {
    &:before {
      background-image: url(/images/link_icon_asset.svg);
      background-size: 12px;
    }
  }

  img {
    border: 1px solid ${grey20};
  }

  ul li,
  ol li {
    list-style-position: outside;
    margin-left: 18px;
  }
`;
