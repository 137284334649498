import React from 'react';
import Label from './Label';
import WarningLabel from './WarningLabel';
import styled from 'styled-components';

export interface InputLabelsProps {
  caption: string;
  id?: string;
  htmlFor?: string;
  children?: JSX.Element;
  required?: boolean;
  validated?: boolean;
  invalid?: boolean;
  setActive?: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
}

export function InputLabels({
  caption = '',
  id = caption,
  htmlFor,
  children,
  required,
  validated,
  invalid,
  setActive,
  isActive,
}: InputLabelsProps) {
  return (
    <StyledInputLabel id={id} onClick={setActive} isActive={isActive}>
      <Label htmlFor={htmlFor} required={required}>
        {caption}
      </Label>
      {children}
      {invalid ? <WarningLabel caption="Field required" /> : null}
    </StyledInputLabel>
  );
}

const StyledInputLabel = styled.div<{ isActive?: boolean }>`
  position: relative;
  z-index: auto;
  ${props => props.isActive && `z-index: 2;`}
`;
