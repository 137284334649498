import React from 'react';
import {
  OutboundLink as GAOutboundLink,
  OutboundLinkProps as GAOutboundLinkProps,
} from 'gatsby-plugin-google-analytics';

interface OutboundLinkProps extends GAOutboundLinkProps, React.HTMLProps<HTMLAnchorElement> {}

export default function OuntboundLink(props: OutboundLinkProps) {
  //@ts-expect-error GAOutboundLinkProps does not include Anchor Element props even though it passes rest props to an <a> - issue upstream
  return <GAOutboundLink {...props} />;
}
