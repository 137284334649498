import React, { useState } from 'react';
import styled from 'styled-components';
import LanguageIcon from './LanguageIcon';
import LanguageDropdown from './LanguageDropdown';
import Fixed from 'components/generic/Fixed';
import { useClickOutside } from 'utils';

export default function LanguageModalButton() {
  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  function handleLanguageButtonClick() {
    setLanguageModalOpen(currentLanguageModalOpen => !currentLanguageModalOpen);
  }

  const ref = useClickOutside<HTMLDivElement>(() => setLanguageModalOpen(false));

  return (
    <>
      {languageModalOpen ? <TranslucentBackground /> : null}
      <LanguageButtonWrapper ref={ref}>
        <LanguageButton onClick={handleLanguageButtonClick}>
          <LanguageIcon />
        </LanguageButton>
        <LanguageModalWrapper style={{ display: languageModalOpen ? 'block' : 'none' }}>
          <LanguageDropdown />
        </LanguageModalWrapper>
      </LanguageButtonWrapper>
    </>
  );
}

const TranslucentBackground = styled(Fixed)`
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(23, 27, 31, 0.6);
`;

const LanguageButtonWrapper = styled.div`
  position: relative;
`;

const LanguageButton = styled.button`
  background: transparent;
  z-index: 2;
  position: relative;
`;

const LanguageModalWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
`;
