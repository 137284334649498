import { ContentfulBlock, ContentfulSimpleArticle } from 'types';

// Keep it simple?
// const kebabCase = (str: string) => {
//   return str
//     ?.trim()
//     .toLowerCase()
//     .replace(/[^a-z\s]+/g, '')
//     .replace(/\s+/g, '-');
// };

// or stay 1337
const cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export function getEntryId(entry: ContentfulBlock | ContentfulSimpleArticle) {
  if (typeof entry.heading !== 'string' || entry.heading.length === 0) {
    return entry.id || 'nodep';
  }
  return cyrb53(entry.heading).toString();
}

export function getEntryAnchor(entry: ContentfulBlock | ContentfulSimpleArticle) {
  const id = getEntryId(entry);
  return typeof id === 'string' && id.length > 0 ? '#' + id : '';
}
