/**
 * we assserts that the switch has exhaustive case coverage for all possible cases
 * this code should be unreachable when used as the default case
 *
 * @example
 * ```typescript
  type AllThePossibleCaseDescriptor = 'somecase' | 'othercase' | 'thirdcase;
  switch (caseDesciption) {
    case 'somecase': return 'This is a case';
    case 'thirdcase': return 'This is a case';
    default: assertUnreachable(caseDescription)
    //                         ^^^^^^^^^^^^^^^ since it's possible 'othercase' will hit `default`
    //                         we will get this error:
    //                         Argument of type 'string' is not assignable to parameter of type 'never'.ts(2345)
  }
 * ```
 * https://javascript.plainenglish.io/never-miss-a-switch-case-with-typescript-684bf5d0e1d1
 */
export function assertUnreachable(value: never): never {
  throw new Error(`did not cover case: ${value}`);
}
