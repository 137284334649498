import { createGlobalStyle, css } from 'styled-components';
import { breadcrumbHeight, branding } from 'styles';
import { grey100, grey40 } from './baseColors';
import { resets } from './resets';
import * as typography from './typography';
import { fonts } from './fonts';
import { mediaQueries } from './breakpoints';

const GlobalStyle = createGlobalStyle`
  ${resets}

  ${fonts}

  html, body, button, textarea {
      font-family: "CiscoSansTT", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  :root {
    // --headerHeight is overridden with javascript when BUILD_TARGET == 'webex'
    --headerHeight: ${branding.headerHeight.mobile};
    ${mediaQueries.small(css`
      --headerHeight: ${branding.headerHeight.tablet};
    `)}
    ${mediaQueries.large(css`
      --headerHeight: ${branding.headerHeight.desktop};
    `)}
    scroll-snap-type: proximity; /* scroll-snap-type: x proximity; */
    scroll-padding-top: calc(var(--headerHeight) + ${breadcrumbHeight}px);
    --defaultTransition: 300ms ease-in; // use like transition: transform var(--defaultTransition), color var(--defaultTransition);
  }

  html {
    --scroll-behavior: smooth; // enable scroll-behavior for smoothscroll-anchor-polyfill (scroll-behaviour for Safari)
    scroll-behavior: smooth;
  }

  [id] {
    scroll-snap-align: start;
  }

  html,
  body {
    color: ${grey100};
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
  }

  * {
    box-sizing: border-box;
    /* resets */
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  body {
    background-color: #fafafa;
  }

  .hero > h1 {
    ${typography.h1Heading};
  }

  h1 {
    ${typography.h1Heading};
  }

  h2 {
    ${typography.h2Heading};
  }

  h3 {
    ${typography.h3Heading};
  }

  h4 {
    ${typography.h4Heading};
  }

  h5 {
    ${typography.h5Heading};
  }

  a {
    ${typography.anchor};
  }

  p,
  a {
    ${typography.mediumBody};
  }

  dfn {
    font-weight: bold;
    cursor: help;
  }

  .small,
  .small p,
  .small a {
    ${typography.mediumBody};
  }

  //? deprecated
  .negative,
  .negative p,
  .negative a {
    ${typography.negativeType};
  }

  //? deprecated
  .muted,
  .muted p,
  .muted a {
    ${typography.mutedType};
  }

  //? deprecated
  .label,
  .label p,
  .label a {
    ${typography.xsmallBody};
  }
  .label {
    font-weight: 300;
  }

  button {
    ${typography.button};
  }

  input[type="checkbox"] {
    ${typography.checkbox};
  }

  select,
  input[type="name"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="phonenumber"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  textarea {
    ${typography.input};
  }

  textarea {
    ${typography.textarea};
  }

  hr,
  figure,
  blockquote,
  p {
    margin: 0; //?
  }

  mark {
    background-color: unset;
  }

  ::placeholder {
    color: ${grey40};
  }

`;

export default GlobalStyle;
