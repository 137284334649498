export function toCurrencyString(number: number, asterisk = ' *'): string {
  if (typeof number !== 'number' || number < 0) return '***';
  return (
    'US $' +
    number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/(?=\.\d{3,})(\.\d{2})\d*/, '$1')
      .replace(/(\.\d)$/, '$10') +
    asterisk
  );
}

export function toMonthlyCurrencyString(number: number): string {
  if (typeof number !== 'number' || number < 0) return '***/mo';
  return toCurrencyString(number, ' /mo *');
}
