import React from 'react';
import NavigationStatusIcon from './NavigationStatusIcon';
import GoToOrderButtonWithStatus from './GoToOrderButtonWithStatus';
import { HideOnDesktop, HideOnTablet } from 'components/Hide';
import { SearchIconButton } from '../IconButtons';
import LanguageModalButton from './LanguageModalButton';
import styled, { css } from 'styled-components';
import { mediaQueries, universalMargin } from 'styles';
import LoginButton from 'components/Authentication/LoginButton';
import { isBuildTargetWebex, isBuildTargetWwt } from 'white-label/build-target';

export interface UserAreaProps {
  globalNavVisibility: Readonly<[boolean, (action?: 'open' | 'close') => void]>;
  showLanguageSelector: boolean;
  showSubscribeButton: boolean;
  showSearchButton: boolean;
  showCartButton: boolean;
  showLoginButton: boolean;
  showBurgerMenuButton: boolean;
}

export default function UserArea({
  globalNavVisibility,
  showCartButton = true,
  showBurgerMenuButton = true,
  ...props
}: UserAreaProps) {
  const [isNavVisible, toggleGlobalNavigation] = globalNavVisibility;

  return (
    <UserAreaContainer>
      {!isBuildTargetWebex() && !isBuildTargetWwt() && props.showLoginButton && <LoginButton />}

      {(props.showSubscribeButton || props.showSearchButton) && (
        <HideOnTablet alsoHideMobile>{props.showSearchButton && <SearchIconButton />}</HideOnTablet>
      )}
      {showCartButton && <GoToOrderButtonWithStatus />}
      {showBurgerMenuButton && (
        <HideOnDesktop>
          <NavigationStatusIcon toggleNav={toggleGlobalNavigation} isOpen={isNavVisible} />
        </HideOnDesktop>
      )}
      {props.showLanguageSelector && (
        <HideOnTablet alsoHideMobile>
          <LanguageModalButton />
        </HideOnTablet>
      )}
    </UserAreaContainer>
  );
}

const UserAreaContainer = styled.div`
  height: 44px;
  display: flex;
  > div a,
  > div button,
  > a:not(:first-child),
  > button:not(:first-child) {
    margin-left: ${universalMargin * 1.5}px;
    ${mediaQueries.small(css`
      margin-left: ${universalMargin * 2}px;
    `)}
  }
`;
