import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React, { useMemo } from 'react';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Options as ContentfulRichtTextRendererOptions } from '@contentful/rich-text-react-renderer';
import { RichTextContainer } from './RichTextContainer';
import {
  renderText,
  renderAssetHyperlink,
  renderEmbeddedAsset,
  renderEmbeddedEntry,
  renderEntryHyperlink,
  renderHyperlink,
  renderParagraph,
} from './elementRenderers';

type RichTextReference = { __typename: string; contentful_id: string }; //todo

interface CMSRichTextProps<T extends ContentfulRichTextGatsbyReference> {
  richtextData: RenderRichTextData<T>;
  linkIcons?: boolean;
  className?: string;
  imageClassName?: string;
  padding?: 'none';
  textColor?: 'grey60';
}

export function ContentfulRichText({
  richtextData,
  linkIcons = false,
  className,
  imageClassName,
  padding,
  textColor,
}: CMSRichTextProps<RichTextReference>) {
  const options: ContentfulRichtTextRendererOptions = useMemo(
    () => ({
      renderText,
      renderNode: {
        [BLOCKS.PARAGRAPH]: renderParagraph,
        [INLINES.ENTRY_HYPERLINK]: node => renderEntryHyperlink(node, linkIcons),
        [INLINES.HYPERLINK]: node => renderHyperlink(node, linkIcons),
        [INLINES.ASSET_HYPERLINK]: node => renderAssetHyperlink(node, linkIcons),
        [BLOCKS.EMBEDDED_ASSET]: node => renderEmbeddedAsset(node, imageClassName),
        [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
      },
    }),
    [linkIcons, imageClassName]
  );

  return (
    <RichTextContainer className={className} padding={padding} textColor={textColor}>
      {richtextData && renderRichText(richtextData, options)}
    </RichTextContainer>
  );
}
