import { useEffect } from 'react';
import { isDevEnvironment } from 'utils';
import { useDetectedCountry, useUserCountry } from './UserCountryContext';

const ENDPOINT = `/api/webexshop/country`;

export default function DetectCountry() {
  const [, setCountry] = useDetectedCountry();
  const [userCountry] = useUserCountry();

  useEffect(() => {
    async function fetchCountry() {
      const response: Response | null = await fetch(ENDPOINT).catch(() => null);
      if (!response) return null;
      const body = await handleCountryResponse(response);
      return gotCountryInResponse(body) ? body.country : null;
    }
    if (!userCountry) {
      fetchCountry()
        .then(country => setCountry(country))
        .catch(error => {
          if (isDevEnvironment) console.error(`Detecting use origin country failed`);
          console.error(error);
        });
    } else {
      setCountry(userCountry);
    }
  }, [setCountry, userCountry]);
  return null;
}

async function handleCountryResponse(response: Response) {
  if (!response.ok || response.status >= 400) return null;
  if (response.status === 204) return; // sent from the API if country detection isn't supported
  const parsedResponse = await response.json().catch(() => null);
  return parsedResponse;
}

function gotCountryInResponse(parsedResponse: unknown): parsedResponse is { country: string } {
  return (
    typeof parsedResponse === 'object' &&
    parsedResponse !== null &&
    'country' in parsedResponse &&
    typeof (parsedResponse as { country: string }).country === 'string'
  );
}
