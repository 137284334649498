import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ChatIcon from './assets/chat_24.svg';
import styled, { css } from 'styled-components';
import variables from '../../../styles/variables';
import { button, mediaQueries } from 'styles';
import { getBuildTarget } from 'white-label';

interface ChatBubbleButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
}

export default function ChatBubbleButton({ ariaLabel }: ChatBubbleButtonProps) {
  const data = useStaticQuery(query);
  const {
    site: { siteMetadata },
  } = data;
  return (
    <StyledLink to={siteMetadata.contactPage.url} title={ariaLabel}>
      <StyledChatIcon />
    </StyledLink>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        contactPage {
          url
        }
      }
    }
  }
`;

const StyledLink = styled(Link)`
  ${button}
  background-color: ${variables[getBuildTarget()].accentColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 22px;
  cursor: pointer;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  ${mediaQueries.medium(css`
    height: 64px;
    width: 64px;
    border-radius: 32px;
  `)}
`;

const StyledChatIcon = styled(ChatIcon)`
  ${StyledLink}:hover & {
    transform: rotate(7deg);
  }
  transition: transform 250ms ease-in-out;
  height: 18px;
  width: 18px;
  ${mediaQueries.medium(css`
    height: 24px;
    width: 24px;
  `)}
`;
