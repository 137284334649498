import styled, { css } from 'styled-components';
import { mediaQueries, gutter } from 'styles/breakpoints';

type Size = 'none' | 'small' | 'medium' | 'large';

type RowProps = {
  mobileRowGap?: Size;
  tabletRowGap?: Size;
  desktopRowGap?: Size;
  mobileReverse?: boolean;
  tabletReverse?: boolean;
  desktopReverse?: boolean;
  centerVertical?: boolean;
};

function mapRowGap(size: Size) {
  return { none: 0, small: 32, medium: 64, large: 96 }[size];
}

export const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${gutter(1, 'mobile')}%;
  ${mediaQueries.small(css`
    column-gap: ${gutter(1, 'tablet')}%;
  `)}
  ${mediaQueries.large(css`
    column-gap: ${gutter(1, 'desktop')}%;
  `)}
  
  ${props =>
    props.mobileRowGap &&
    css`
      row-gap: ${mapRowGap(props.mobileRowGap)}px;
    `}
    
  ${props =>
    props.tabletRowGap &&
    mediaQueries.small(css`
      row-gap: ${mapRowGap(props.tabletRowGap)}px;
    `)}
      
  ${props =>
    props.desktopRowGap &&
    mediaQueries.large(css`
      row-gap: ${mapRowGap(props.desktopRowGap)}px;
    `)}
  
  ${props =>
    props.desktopReverse &&
    mediaQueries.large(css`
      direction: rtl;
      > * {
        direction: ltr;
      }
    `)}
  
  ${props =>
    props.mobileReverse &&
    css`
      direction: rtl;
      > * {
        direction: ltr;
      }
    `}
    
  ${props =>
    props.tabletReverse &&
    mediaQueries.small(css`
      direction: rtl;
      > * {
        direction: ltr;
      }
    `)}
  
  ${props =>
    props.desktopReverse &&
    mediaQueries.large(css`
      direction: rtl;
      > * {
        direction: ltr;
      }
    `)}
    
  ${props =>
    props.centerVertical &&
    css`
      align-items: center;
    `}
`;

type ColumnRange =
  | '1/12'
  | '2/12'
  | '3/12'
  | '4/12'
  | '5/12'
  | '6/12'
  | '7/12'
  | '8/12'
  | '9/12'
  | '10/12'
  | '11/12'
  | '12/12';

const mapToNumber = (range?: ColumnRange): number =>
  ({
    '1/12': 1,
    '2/12': 2,
    '3/12': 3,
    '4/12': 4,
    '5/12': 5,
    '6/12': 6,
    '7/12': 7,
    '8/12': 8,
    '9/12': 9,
    '10/12': 10,
    '11/12': 11,
    '12/12': 12,
  }[range || '12/12']);

interface ColumnProps {
  mobile?: ColumnRange;
  mobileOffset?: ColumnRange;
  tablet?: ColumnRange;
  tabletOffset?: ColumnRange;
  desktop?: ColumnRange;
  desktopOffset?: ColumnRange;
  centerVertical?: boolean;
}

export const Column = styled.div<ColumnProps>`
  ${props =>
    props.centerVertical &&
    css`
      align-items: center;
    `}

  grid-column: ${props =>
    props.mobileOffset
      ? `${mapToNumber(props.mobileOffset)} / span ${mapToNumber(props.mobile)}`
      : `span ${mapToNumber(props.mobile)}`};

  ${props =>
    mediaQueries.small(css`
      ${props.tablet &&
      (props.tabletOffset
        ? css`
            grid-column: ${mapToNumber(props.tabletOffset)} / span ${mapToNumber(props.tablet)};
          `
        : css`
            grid-column: span ${mapToNumber(props.tablet)};
          `)};
    `)}

  ${props =>
    mediaQueries.large(css`
      ${props.desktop &&
      (props.desktopOffset
        ? css`
            grid-column: ${mapToNumber(props.desktopOffset)} / span ${mapToNumber(props.desktop)};
          `
        : css`
            grid-column: span ${mapToNumber(props.desktop)};
          `)};
    `)}
`;
