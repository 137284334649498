import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { universalMargin } from 'styles';
import CountrySelector from 'context/WebexShopIntegration/CountrySelector';
import CiscoLogo from 'assets/cisco_logo.svg';
import { FooterMetaData } from '.';
import { isDirectLink } from '../types';
import TrackedWebexLink from 'components/TrackedWebexLink';
import { useBaseLayout } from 'context/BaseLayoutState';

interface LogoColumnArgs {
  siteMetadata: FooterMetaData;
  topLogo: React.ReactNode;
  showLanguageSelector?: boolean;
}

export default function LogoColumn({ siteMetadata, ...props }: LogoColumnArgs) {
  const { appearance } = useBaseLayout();
  const homeLinkUrl = isDirectLink(siteMetadata.homeLink) ? siteMetadata.homeLink.url : '/';
  return props.topLogo ? (
    <ColumnWrapper>
      <div>
        <TopLogo to={homeLinkUrl}>{props.topLogo}</TopLogo>
        <a href="https://www.cisco.com">
          <StyledCiscoLogo />
        </a>
      </div>
      {props.showLanguageSelector && <CountrySelector />}
    </ColumnWrapper>
  ) : (
    <ColumnWrapper>
      <div>
        <a href="https://www.cisco.com">
          <StyledCiscoLogo />
        </a>
        <TrackedWebexLink>
          <StyledWebexLogo
            src={appearance === 'light' ? '/images/webex_logo.png' : '/images/webex_logo-dark.png'}
            alt="Webex Logo"
          />
        </TrackedWebexLink>
      </div>
      {props.showLanguageSelector && <CountrySelector />}
    </ColumnWrapper>
  );
}

const TopLogo = styled(Link)`
  padding-bottom: ${universalMargin * 5}px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
`;

const StyledWebexLogo = styled.img`
  width: 147px;
  height: 40px;
  margin-right: 45px;
  margin-bottom: 14px;
`;

const StyledCiscoLogo = styled(CiscoLogo)`
  margin-right: 45px;
`;
