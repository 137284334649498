import styled from 'styled-components';

/**
 * helps fixing elements at the top, below the site header menu
 */
const Fixed = styled.div`
  position: fixed;
  top: var(--headerHeight);
`;
export default Fixed;
