import React, { createContext, useContext } from 'react';

type CountryContext = [string | null, React.Dispatch<React.SetStateAction<string | null>>];
export const DetectedCountryContext = createContext<CountryContext>([null, () => null]);
export const UserCountryContext = createContext<CountryContext>([null, () => null]);

export function useUserCountry() {
  return useContext(UserCountryContext);
}

export function useDetectedCountry() {
  return useContext(DetectedCountryContext);
}
