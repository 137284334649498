import { Reducer, ReducerState, useEffect, useReducer, useState } from 'react';
import { useDebug } from '../Debug';

/**
 * useLocalStorageState
 * a useState with persistent storage accross sessions
 *
 * Use like React's useState, except the added `key` argument for a unique key
 * for the in-browser local storage
 */
export function useLocalStorageState<StateType>(key: string, initialValue: StateType) {
  let debug = useDebug();
  let [localStorageRead, setLocalStorageRead] = useState(false);
  let [storedValue, setStoredValue] = useState(() => valueOrDeferedValue(initialValue));

  function handleChange(json: string | null) {
    try {
      const changedLocal = json && json !== 'undefined' ? JSON.parse(json) : null;
      if (changedLocal) setStoredValue(changedLocal);
    } catch (error) {
      console.error(`Something went wrong while parsing persistent state`);
    }
  }

  useEffect(() => {
    if (localStorageRead) window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, localStorageRead, storedValue]);

  /** links multiple storage changes in multiple browser tabs together */
  useEffect(() => {
    function storageEventHandler(event: StorageEvent) {
      if (debug) debugLogger(key, event);
      if (key !== event.key) return;
      const json = event.newValue;
      handleChange(json);
    }
    window.addEventListener('storage', storageEventHandler, false);
    return () => window.removeEventListener('storage', storageEventHandler);
  }, [debug, key]);

  useEffect(() => {
    const json = window.localStorage.getItem(key);
    handleChange(json);
    setLocalStorageRead(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [storedValue, setStoredValue] as const;
}

function valueOrDeferedValue<ValueType>(valueOrFunction: (() => ValueType) | ValueType) {
  return valueOrFunction instanceof Function ? valueOrFunction() : valueOrFunction;
}

/**
 * useLocalStorageReducer
 * a useReducer with persistent storage accross sessions
 *
 * Use like React's useReducer, except the added `key` argument for a unique key
 * for the in-browser local storage
 */
export function useLocalStorageReducer<StateType, ActionType>(
  key: string,
  reducer: Reducer<StateType, ActionType>,
  initialValue: StateType,
  initializer?: (
    arg: StateType & ReducerState<Reducer<StateType, ActionType>>
  ) => ReducerState<Reducer<StateType, ActionType>>
) {
  let debug = useDebug();
  let [localStorageRead, setLocalStorageRead] = useState(false);

  // let restoreLocalStorage = initialValue => {
  //   if (isOnServer && initializer instanceof Function) return initializer(initialValue);
  //   if (isOnServer) return initialValue;
  //   const json = window.localStorage.getItem(key);
  //
  //   const storedData = json && json !== 'undefined' ? JSON.parse(json) : initialValue;
  //
  //   if (initializer instanceof Function) {
  //     return initializer(storedData);
  //   }
  //
  //   return storedData;
  // };

  let [storedValue, storedValueDispatcher] = useReducer(reducer, initialValue, initializer ? initializer : arg => arg);

  function handleChange(json: string | null) {
    try {
      const changedLocal = json && json !== 'undefined' ? JSON.parse(json) : null;
      if (changedLocal) storedValueDispatcher(changedLocal);
    } catch (error) {
      console.error(`Something went wrong while parsing persistent state`);
    }
  }

  useEffect(() => {
    const json = window.localStorage.getItem(key);
    handleChange(json);
    setLocalStorageRead(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** links multiple storage changes in multiple browser tabs together */
  useEffect(() => {
    function storageEventHandler(event: StorageEvent) {
      if (debug) debugLogger(key, event);
      if (key !== event.key) return;
      const json = event.newValue;
      handleChange(json);
    }
    window.addEventListener('storage', storageEventHandler, false);
    return () => window.removeEventListener('storage', storageEventHandler);
  }, [debug, key]);

  useEffect(() => {
    if (localStorageRead) window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [storedValue, key, localStorageRead]);

  return [storedValue, storedValueDispatcher] as const;
}

function debugLogger(key: string, event: StorageEvent) {
  console.log(
    `The local storage changed in another tab...  ((event type: {${event.type}}))
    event key: {${event.key}} this key: (${key}) ${
      event.key !== key ? ' - event key and local key not equal, will bail' : ''
    }
     - with newValue? (${Boolean(event.newValue)}) which is:`,
    event.newValue
  );
}
