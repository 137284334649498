import React, { lazy, memo, useState } from 'react';
import { Suspense } from 'utils/IsomorphicSuspense';
import { mergeClassNames } from 'utils';
import { ContentfulVideoMux } from 'types';
import Image from '../Image';
import { branding } from 'styles';
import { generatePosterFrameImgSrcSet } from './generatePosterFrameImgSrcSet';
import styled from 'styled-components';
import { useCookieAcceptance } from 'context/CookiesAcceptance';
import { useDebug } from 'Debug';
import { getBuildTarget } from 'white-label';

const MuxPlayer = memo(MuxPlayerImpl);
export default MuxPlayer;

const HlsVideoPlayer = lazy(() => import('../HlsVideoPlayer'));

export type BaseVideoPlayerProps = {
  onPlayStatusChange?: (playing: boolean) => void;
  video: ContentfulVideoMux;
  onClick?: React.MouseEventHandler<HTMLVideoElement>;
  className?: string;
};

export type MuxPlayerProps = BaseVideoPlayerProps & {
  roundedCorners?: boolean;
  posterImageMaxWidth?: number;
};

/*
 * component handling loading of video player internals and displaying a poster frame as a fallback image
 */
function MuxPlayerImpl(props: MuxPlayerProps) {
  const [{ necessary: necessaryCookieAccepted }] = useCookieAcceptance();
  const debug = useDebug();

  const [isPlaying, setIsPlaying] = useState(props.video.autoplay);

  const videoMuxSrc = `https://stream.mux.com/${props.video.video?.playbackId}.m3u8`;

  // defer loading video before cookies is accepted to get a clue if the client is human controlled or not
  // prevents unneccessary loading of video player & data if client is crawler / bot
  const videoInitAllowed = !['pwp', 'wwt'].includes(getBuildTarget()) || necessaryCookieAccepted;

  const posterImage = generatePosterFrameImgSrcSet(
    props.video.video,
    'jpg',
    props.posterImageMaxWidth,
    props.video.posterFrame
  );
  const videoFallback = (
    <VideoWrapper>
      <Image
        image={posterImage.gatsbyImageData}
        alt="Video poster placeholder"
        shape={props.roundedCorners && !isPlaying ? 'roundedCorners' : 'rectangular'}
      />
    </VideoWrapper>
  );

  return (
    <Suspense fallback={videoFallback}>
      {videoInitAllowed && (
        <VideoWrapper roundedCorners={props.roundedCorners && !isPlaying}>
          <Image
            image={posterImage.gatsbyImageData}
            alt="Video poster placeholder"
            shape={props.roundedCorners && !isPlaying ? 'roundedCorners' : 'rectangular'}
          />
          <StyledHlsVideoPlayer
            {...props}
            videoSrc={videoMuxSrc}
            posterUrl="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" // transparent gif, use posterImage instead of video's poster image to get size and use benefits of the image's srcSet
            loop={props.video.mode === 'Loop'}
            controls={props.video.controls === 'Controls' && isPlaying}
            muted={props.video.audio === 'Muted'}
            pause={!isPlaying}
            autoPlay={props.video.autoplay}
            onPlayStatusChange={playing => {
              if (isPlaying !== playing) setIsPlaying(playing);
            }}
            className={mergeClassNames(debug ? 'debug' : null, props.className)}
          />
          {!isPlaying && (
            <PlayContainer>
              <PlayButton onClick={() => setIsPlaying(true)}>
                <Triangle />
              </PlayButton>
            </PlayContainer>
          )}
        </VideoWrapper>
      )}
    </Suspense>
  );
}

const Relative = styled.div`
  position: relative;
`;

const VideoWrapper = styled(Relative)<{ roundedCorners?: boolean }>`
  transition: border-radius 200ms ease-in-out;
  will-change: border-radius;
  border-radius: ${props => (props.roundedCorners ? branding.boxBorderRadius : '0')};
  overflow: hidden;
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: top;
  &.debug {
    box-shadow: 0 0 0 2px deepskyblue;
  }
`;

const StyledHlsVideoPlayer = styled(HlsVideoPlayer)`
  // use posterImage to size position:
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PlayContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayButton = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${branding.accentColor};
  border-radius: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: all 200ms ease-in-out;
  will-change: transform, box-shadow;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.085);
  }
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const Triangle = styled.div`
  margin-left: 6px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 18px solid white;
`;

export { generatePosterFrameImgSrcSet };
