import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { Block, Inline, Text } from '@contentful/rich-text-types';
import React, { ReactNode } from 'react';
import { isDevEnvironment } from 'utils';
import { universalMargin } from 'styles';
import getRoute from 'routes/getRoute';
import Image from 'components/generic/Image';
import LinkButton from 'components/generic/LinkButton';
import { SelectVideoPlayer } from 'components/blocks/Video';
import { filterEmptyParagraphs } from './utils/filterEmptyParagraphs';
import LinkArrow from '../Icon/assets/cardArrowRight.svg';

export function renderText(text: string) {
  return text
    .replace(/\r\n|\r/, '\n') // translate all `\r\n` (windows newline) and `\r` into consistent `\n`
    .split('\n')
    .flatMap((text, i) => [i > 0 && <br key={'br_' + i} />, text]);
}

export function renderParagraph(node: Block | Inline, children: ReactNode) {
  return filterEmptyParagraphs(children) ? <p>{filterEmptyParagraphs(children)}</p> : <></>;
}

export function renderHyperlink(node: Block | Inline, linkIcons: boolean) {
  let [content] = node.content;
  let { uri } = node.data;

  // TODO: Should we test the external links here?
  // if (isOnServer) {
  //   console.warn('External link:', uri);
  // }

  if (!checkValidContentfulLink(content)) return <></>;
  return (
    <StyledLink prependUrlLinkIcon={Boolean(linkIcons)} href={uri} target="_blank" rel="noopener noreferrer">
      {content.value}
    </StyledLink>
  );
}

export function renderAssetHyperlink(node: Block | Inline, linkIcons: boolean) {
  let [content] = node.content;
  let { target } = node.data;
  let url = target.file?.url;

  if (!checkValidContentfulLink(content)) return <></>;
  return (
    <StyledLink
      prependAssetLinkIcon={Boolean(linkIcons)}
      href={`https://${url}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {content.value}
      {!linkIcons && (
        <IconWrapper>
          <LinkArrow />
        </IconWrapper>
      )}
    </StyledLink>
  );
}

export function renderEntryHyperlink(node: Block | Inline, linkIcons: ReactNode) {
  let [content] = node.content;
  let { target } = node.data;

  if (!target?.__typename) {
    if (isDevEnvironment) {
      console.error(
        `this node (entry hyperlink) is missing a graph query for it's type inside the rich text field it is being referenced.`,
        node
      );
    }
    return <></>;
  }

  if (!checkValidContentfulLink(content)) return <></>;
  return (
    <StyledLink prependEntryLinkIcon={Boolean(linkIcons)} as={Link} to={getRoute(target)}>
      {content.value}
      {!linkIcons && (
        <IconWrapper>
          <LinkArrow />
        </IconWrapper>
      )}
    </StyledLink>
  );
}

export function renderEmbeddedEntry(node: Block | Inline) {
  let { __typename, ...target } = node.data.target || {};

  switch (__typename) {
    case 'ContentfulButton':
      return (
        <p>
          <LinkButton {...target} />
        </p>
      );
    case 'ContentfulMuxVideo': {
      return (
        <VideoWrapper>
          <SelectVideoPlayer video={target} posterImageMaxWidth={2880} />
        </VideoWrapper>
      );
    }
    default:
      const warning = `Unsupported embed type ${target.__typename} : ${JSON.stringify(target)}`;
      console.warn(warning);
      return isDevEnvironment ? <p>{warning}</p> : <></>;
  }
}

export function renderEmbeddedAsset(node: Block | Inline, imageClassName?: string) {
  let { file, gatsbyImageData, description = {} } = node.data.target;
  let { contentType } = file || {};

  switch (contentType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/webp': {
      return <Image className={imageClassName} image={gatsbyImageData} alt={description} />;
    }
    case 'image/svg+xml':
      return <InlineSvg className={imageClassName} src={file.url} alt={description} />;
    default:
      return isDevEnvironment ? <p>Unsupported asset type '{contentType}'</p> : <></>;
  }
}

// don't render links if the value is empty (Contentful issue)
function checkValidContentfulLink(content: Block['content'][0]): content is Text {
  return 'value' in content && content.value !== undefined && content.value.length !== 0;
}

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const InlineSvg = styled.img`
  width: 100%;
`;

const StyledLink = styled.a<{
  prependUrlLinkIcon?: boolean;
  prependAssetLinkIcon?: boolean;
  prependEntryLinkIcon?: boolean;
}>`
  ${props =>
    props.prependUrlLinkIcon || props.prependAssetLinkIcon || props.prependEntryLinkIcon
      ? css`
          &:before {
            content: '';
            width: ${universalMargin * 2.5}px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-size: 12px;
          }
        `
      : ''}
  ${props =>
    props.prependUrlLinkIcon
      ? css`
          &:before {
            background-image: url(/images/link_icon_url.svg);
          }
        `
      : ''}
  ${props =>
    props.prependAssetLinkIcon
      ? css`
          &:before {
            background-image: url(/images/link_icon_asset.svg);
          }
        `
      : ''}
  ${props =>
    props.prependEntryLinkIcon
      ? css`
          &:before {
            background-image: url(/images/link_icon_entry.svg);
          }
        `
      : ''}
`;

const IconWrapper = styled.div`
  display: inline;
  vertical-align: middle;
  margin-left: 4px;

  svg {
    height: 14px;
    width: 14px;
  }
`;
