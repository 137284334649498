import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { ContentfulNavigation } from 'types';
import { DropdownType } from 'components/Dropdown';
import { isDevEnvironment } from '../utils';
import { Quote } from './Quote/typedefs';

interface OpenDropdownActionBase {
  type: 'open' | 'toggle';
}

interface OpenNavigationAction extends OpenDropdownActionBase {
  menuType: Extract<DropdownType, 'menu'>;
  subNavigation?: Array<ContentfulNavigation>;
}

interface OpenDropdownGenericAction extends OpenDropdownActionBase {
  menuType: Exclude<DropdownType, 'menu' | 'confirmaddedtocart'>;
}

interface OpenConfirmAddedToCartDropdownAction extends OpenDropdownActionBase {
  menuType: Extract<DropdownType, 'confirmaddedtocart'>;
  quote: Quote;
}

interface CloseDropdownAction {
  type: 'close';
}

type OpenDropdownAction = OpenConfirmAddedToCartDropdownAction | OpenDropdownGenericAction | OpenNavigationAction;
type DropdownStateAction = CloseDropdownAction | OpenDropdownAction;

const initialState: DropdownState = { dropdown: 'close' };

const DropdownStateContext = createContext<{
  dropdownState: DropdownState;
  dispatchDropdownState: Dispatch<DropdownStateAction>;
}>({ dropdownState: initialState, dispatchDropdownState: (() => null) as Dispatch<DropdownStateAction> });

type DropdownCloseState = {
  dropdown: 'close';
};

interface DropdownOpenStateBase {
  dropdown: 'open' | 'toggle';
}

interface DropdownOpenGenericState {
  dropdown: 'open' | 'toggle';
  menuType: Exclude<DropdownType, 'menu' | 'confirmaddedtocart'>;
}

interface DropdownOpenNavigationState extends DropdownOpenStateBase {
  menuType: Extract<DropdownType, 'menu'>;
  subNavigation?: Array<ContentfulNavigation>;
}

interface DropdownOpenAddedToCartOpenState extends DropdownOpenStateBase {
  menuType: Extract<DropdownType, 'confirmaddedtocart'>;
  quote: Quote;
}

type DropdownOpenState = DropdownOpenGenericState | DropdownOpenNavigationState | DropdownOpenAddedToCartOpenState;

export type DropdownState = DropdownOpenState | DropdownCloseState;

function reducer(state: DropdownState, action: DropdownStateAction): DropdownState {
  switch (action.type) {
    case 'open': {
      if (action.menuType === 'menu') {
        return {
          dropdown: 'open',
          menuType: action.menuType,
          subNavigation: action.subNavigation,
        };
      }
      if (action.menuType === 'confirmaddedtocart') {
        return {
          dropdown: 'open',
          menuType: action.menuType,
          quote: action.quote,
        };
      }
      return {
        dropdown: 'open',
        menuType: action.menuType,
      };
    }
    case 'close':
      return {
        dropdown: 'close',
      };
    case 'toggle':
      if (state.dropdown === 'open') {
        return {
          dropdown: 'close',
          menuType: action.menuType,
        };
      } else {
        if (action.menuType === 'menu') {
          return {
            dropdown: 'open',
            menuType: action.menuType,
            subNavigation: action.subNavigation,
          };
        }
        if (action.menuType === 'confirmaddedtocart') {
          return {
            dropdown: 'open',
            menuType: action.menuType,
            quote: action.quote,
          };
        }
        return {
          dropdown: 'open',
          menuType: action.menuType,
        };
      }

    default:
      if (isDevEnvironment) {
        console.warn('Unknown dropdown action type provided, DropdownStateContext:', state, action);
        throw new Error('Unknown dropdown action type provided');
      }
      return state; // do not change anything
  }
}

interface NavigationDropdownStateProviderProps {
  children: ReactNode;
}

export function NavigationDropdownStateProvider({ children }: NavigationDropdownStateProviderProps) {
  const [dropdownState, dispatchDropdownState] = useReducer(reducer, initialState);

  const contextValue = { dropdownState, dispatchDropdownState };

  return <DropdownStateContext.Provider value={contextValue}>{children}</DropdownStateContext.Provider>;
}

export function useDropdownState() {
  return useContext(DropdownStateContext);
}
